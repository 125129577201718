import { Input } from 'antd'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SearchIcon from '@mui/icons-material/Search'

export const SearchBar = ({ search }) => {
  return (
    <div className="flex justify-end ml-20 mb-10 mt-10">
      <div className="flex items-center space-x-2">
        <Input
          className="w-80  rounded-md border border-gray-200 focus:outline-none"
          placeholder="Recherche"
          style={{ outline: 'none' }}
          onChange={(e) => search(e.target.value)}
        />
        <SearchIcon className="text-gray-500 text-3xl" />
        <DoneAllIcon className="text-gray-500 text-3xl" />
        <FilterAltIcon className="text-gray-500 text-3xl" />
      </div>
    </div>
  )
}
