import { Input, Modal, Select, message } from 'antd'
import { useEffect, useRef, useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import AxiosInstance from '../../axios/axiosInstance'

const ControleReference = () => {
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [controleReferences, setControleReferences] = useState([])

  useEffect(() => {
    loadControleReferences()
  }, [])

  const loadControleReferences = () => {
    AxiosInstance.get('controle-reference')
      .then((res) => {
        setControleReferences(res.data)
        console.log(res.data)
      })
      .catch((e) => message.error("Une erreur s'est produite ! "))
  }

  const updateStatus = (controle, e) => {
    AxiosInstance.patch(`controle-reference/${controle._id}`, { action: e })
      .then((res) => {
        const controleIdx = controleReferences.findIndex(
          (searchedControle) => controle._id === searchedControle._id,
        )
        const newControles = [...controleReferences]
        newControles[controleIdx] = res.data
        setControleReferences(newControles)
        message.success('Modifications enregistrées !')
      })
      .catch((e) => console.log(e))
  }

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <div>
      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2 h-10 mr-20">
          {showSearch && (
            <Input
              ref={searchInputRef}
              className={`w-80 transition-all ${
                showSearch ? 'opacity-100' : 'opacity-0'
              } duration-500 ease-in-out   rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
            />
          )}
          <SearchIcon
            onClick={() => showSearchInput(showSearch)}
            className="hover:cursor-pointer text-gray-500 text-3xl"
          />
          <FilterListOutlinedIcon className="text-gray-500 text-3xl" />
        </div>
      </div>

      <div className="flex items-center justify-between text-xs mx-10 text-center">
        <p className="flex-1">DATE</p>
        <p className="flex-1">CANDIDAT</p>
        <p className="flex-1">QUALIFICATION</p>
        <p className="flex-1">ENTREPRISE</p>
        <p className="flex-1">TELEPHONE CONTACT</p>
        <p className="flex-1">ACTION MENEE</p>
        {/*   <p className="grow">NOM DU RESPONSABLE</p>
            <p className="grow">DATE DE L'ACTION</p>
            <p className="grow">RESULTAT</p>
            <div className="grow">
            <p >PROPOSITION </p>
            <p > PARTENARIAT</p>

            </div>
            <p className="grow">COMMENTAIRES</p> */}
      </div>

      <div className="mt-10">
        {controleReferences.map((controle) => {
          return (
            <div className="flex font-semibold uppercase bg-white border px-2 py-4 rounded-md shadow-lg border-gray-300 items-center justify-between  text-xs mx-10 text-center mt-10">
              <p className="flex-1">{formatJsDate(controle.createdAt)}</p>
              <p className="flex-1">
                {controle.tempworkerCandidate?.firstname}{' '}
                {controle.tempworkerCandidate?.lastname}
              </p>
              <p className="flex-1">{controle.qualification}</p>
              <p className="flex-1">{controle.companyName}</p>
              <p className="flex-1">{controle.responsablePhone}</p>
              <p className="flex-1">
                <Select
                  className="  max-h-12   py-2   rounded-md  placeholder-[#00000040]"
                  onChange={(e) => updateStatus(controle, e)}
                  value={controle.action}
                  options={[
                    {
                      label: 'A faire',
                      value: 'A faire',
                      key: 'A faire',
                    },
                    {
                      label: 'Faite',
                      value: 'Faite',
                      key: 'Faite',
                    },
                    {
                      label: 'En attente',
                      value: 'En attente',
                      key: 'En attente',
                    },
                    { label: 'Annulée', value: 'Annulée', key: 'Annulée' },
                  ]}
                  placeholder="Statut"
                />
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ControleReference
