import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import RHItem from './RHItem'

const RHList = ({ currentWeekNumber, RHs, setShowRH }) => {
  return (
    <div>
      <div className="flex items-center  mx-32 ">
        <div className="w-4/12 flex items-center space-x-2">
          <ArrowBackIosIcon onClick={() => setShowRH(false)} />
          <p className="text-xl font-semibold">SEMAINE {currentWeekNumber}</p>
        </div>
        <div className="w-8/12 flex items-center justify-between text-xs uppercase mr-20">
          <p className="px-2">Lundi</p>
          <p className="px-2">Mardi</p>
          <p className="px-2">Mercredi</p>
          <p className="px-2">jeudi</p>
          <p className="px-2">vendredi</p>
          <p className="px-2">samedi</p>
          <p className="px-2">dimanche</p>
          <p className="px-2"></p>
        </div>
      </div>

      <div className="mx-32 mt-10">
        {RHs.map((RH) => {
          return <RHItem item={RH} />
        })}
      </div>
    </div>
  )
}

export default RHList
