import { useContext, useEffect, useState, useRef } from 'react'

import { AuthContext } from '../context/authContext'
import { useNavigate } from 'react-router-dom'
import Header from '../components/Header/Header'
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import axios from 'axios'
import Agency from '../components/Agency/Agency'

const HomePage = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext)
  const navigate = useNavigate()
  const [userEmail, setUserEmail] = useState('')
  const [userRole, setUserRole] = useState('')
  const [username, setUserName] = useState('')

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userRole')
    navigate('/login')
  }

  useEffect(() => {
    if (!isLoggedIn) navigate('/login')
    else {
      const userEmail = localStorage.getItem('userEmail')
      const userRole = localStorage.getItem('userRole')
      const username = localStorage.getItem('name')
      setUserEmail(userEmail)
      setUserRole(userRole)
      setUserName(username)
    }
  }, [isLoggedIn])

  return (
    <div>
      <Agency />
    </div>
  )
}

export default HomePage
