import { message } from 'antd'
import { useEffect, useState, useRef } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import PersonIcon from '@mui/icons-material/Person'
import WeekCard from './WeekCard'
import RHList from './RHList'
const RH = () => {
  const [RH, setRH] = useState([])
  const [weeks, setWeeks] = useState([])
  const [showRH, setSHowRH] = useState(false)
  const currentWeekRef = useRef(null)
  const [currentWeekNumber, setCurrentWeekNumber] = useState(0)

  useEffect(() => {
    const currentWeekNumber = getISOWeekNumber()
    console.log(currentWeekRef)
    setCurrentWeekNumber(currentWeekNumber)
    const currentYear = new Date().getFullYear()
    const allWeeksOfYear = getWeeksOfYear(currentYear)
    setWeeks(allWeeksOfYear)
    if (currentWeekRef.current) {
      currentWeekRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [])

  function getISOWeekNumber(date) {
    const currentDate = date || new Date()
    const daysUntilMonday = (currentDate.getDay() + 6) % 7 // Calculate days until Monday
    const startOfWeek = new Date(currentDate.getFullYear(), 0, 1)

    // Adjust the start of the week to the preceding Monday
    startOfWeek.setDate(
      startOfWeek.getDate() + (daysUntilMonday > 0 ? 8 - daysUntilMonday : 1),
    )

    const diff = currentDate - startOfWeek
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000)) + 1 // Adding 1 to start from week 1

    return weekNumber
  }

  function getWeeksOfYear() {
    const currentYear = new Date().getFullYear()
    const weeks = []

    const firstDayOfYear = new Date(currentYear, 0, 1)
    const lastDayOfYear = new Date(currentYear, 11, 31)

    // Find the first Monday of the year
    let currentWeekDate = new Date(firstDayOfYear)
    while (currentWeekDate.getDay() !== 1 /* Monday */) {
      currentWeekDate.setDate(currentWeekDate.getDate() + 1)
    }

    while (currentWeekDate <= lastDayOfYear) {
      const weekNumber = getISOWeekNumber(currentWeekDate)

      // Calculate the last day of the week (Sunday)
      const lastDayOfWeek = new Date(currentWeekDate)
      lastDayOfWeek.setDate(currentWeekDate.getDate() + 6)

      weeks.push({
        year: currentYear,
        weekNumber,
        firstDayOfWeek: currentWeekDate,
        lastDayOfWeek,
      })

      currentWeekDate = new Date(
        currentWeekDate.getTime() + 7 * 24 * 60 * 60 * 1000,
      )
    }

    return weeks
  }

  function formatDateToCustomString(date) {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}${month}${day}`
  }

  const loadRH = (week) => {
    setCurrentWeekNumber(week.weekNumber)

    const DateDebut = formatDateToCustomString(week.firstDayOfWeek)
    const DateFin = formatDateToCustomString(week.lastDayOfWeek)
    const params = {
      DateDebut: DateDebut,
      DateFin: DateFin,
    }
    axiosInstance
      .get('releve-heures', { params })
      .then((res) => {
        setRH(res.data)
        console.log(res.data)
        setSHowRH(true)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
      })
  }

  return (
    <>
      {/*   <div className="flex items-center justify-between  mb-6 mx-20 text-md">
            <p className="w-1/5 text-center  animate-fadeIn">N° DE CONTRAT</p>
            <p className="w-1/5 text-center">ENTREPRISE</p>
            <p className="w-1/5 text-center">INTERIMAIRE</p>
            <p className="w-1/5 text-right">DATE DE CONTRAT</p>
            <p className="w-1/5"></p>
          </div> */}
      <h2 className="mx-10 text-2xl mb-10">Relevés d'heures</h2>
      <div className="flex flex-col space-y-4">
        {!showRH && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mx-10">
            {weeks.map((week) => {
              return <WeekCard loadRH={loadRH} week={week} />
            })}
          </div>
        )}

        {showRH && (
          <RHList
            currentWeekNumber={currentWeekNumber}
            RHs={RH}
            setShowRH={setSHowRH}
          />
        )}
      </div>
    </>
  )
}

export default RH
