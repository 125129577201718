import { useEffect, useRef } from 'react'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'

const WeekCard = ({ loadRH, week }) => {
  const cardRef = useRef(null)

  function getWeekNumber() {
    const currentDate = new Date()
    const startOfWeek = new Date(currentDate.getFullYear(), 0, 1)
    const daysOffset = startOfWeek.getDay() === 0 ? 1 : 8 - startOfWeek.getDay()

    const diff = currentDate - startOfWeek + daysOffset * 24 * 60 * 60 * 1000
    const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000))

    return weekNumber
  }

  function formatDate(date) {
    const day = date.getDate().toString().padStart(2, '0')
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
  }

  useEffect(() => {
    const currentWeekNumber = getWeekNumber()
    if (week.weekNumber === currentWeekNumber) {
      cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [week])

  return (
    <div
      key={week.weekNumber}
      onClick={() => loadRH(week)}
      ref={cardRef}
      className="flex items-center flex-col space-y-1 bg-gray-100 h-96 rounded-lg hover:scale-101"
    >
      <div className="flex items-center justify-center space-x-2 py-4 border-b border-gray-400 mb-10 w-full">
        <div className="bg-white p-4 rounded-full">
          <CalendarMonthIcon />
        </div>
        <div className="text-sm">
          <p>Semaine {week.weekNumber}</p>
          <p> {formatDate(week.firstDayOfWeek)}</p>
          <p> {formatDate(week.lastDayOfWeek)}</p>
        </div>
      </div>

      <div className="flex flex-col items-start space-y-6">
        <p className="flex items-center space-x-2">
          <span>0</span>
          <span>à saisir par l'interimaire</span>
        </p>
        <p className="flex items-center space-x-2">
          <span>0</span>
          <span>à saisir par le client</span>
        </p>

        <p className="flex items-center space-x-2">
          <span>0</span>
          <span>validés</span>
        </p>

        <p className="flex items-center space-x-2">
          <span>0</span>
          <span>integrés</span>
        </p>
      </div>
    </div>
  )
}

export default WeekCard
