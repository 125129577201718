import { Button, Input, Modal, Popover, Select, message } from 'antd'
import { useRef, useState, useEffect } from 'react'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import poubelle from '../../assets/img/poubelle.gif'
import download from '../../assets/img/download.gif'
import visualize from '../../assets/img/visualize.gif'
import traiter from '../../assets/img/traiter.gif'
import {
  AddCircleOutlineOutlined,
  CheckCircleOutline,
  TroubleshootSharp,
} from '@mui/icons-material'
import { Document, Page } from 'react-pdf'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios/axiosInstance'
import { CircularProgress } from '@mui/material'
import { FilePdfOutlined } from '@ant-design/icons'

const ArretMaladie = () => {
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [showDocument, setShowDocument] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [documentData, setDocumentData] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [numPages, setNumPages] = useState([])
  const [timer, setTimer] = useState(null)
  const [arrets, setArrets] = useState([])
  const [originalArrets, setOriginalArret] = useState([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [searchWord, setSearchWord] = useState('')

  useEffect(() => {
    loadArrets()
  }, [])

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }

  const updateStatus = (arret, idx) => {
    axios
      .post(`arret/${arret._id}/status`, { status: 'Traitée' })
      .then((res) => {
        const newArrets = [...arrets]
        newArrets[idx] = res.data
        setArrets(newArrets)
        console.log(res.data)
        message.success(' statut mis a jour !')
      })
      .catch((e) => message.error(e))
  }

  const updateAction = (arret, e, idx) => {
    const newArrets = [...arrets]
    newArrets[idx].actionMenee = e
    setArrets(newArrets)

    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      axios
        .post(`arret/${arret._id}/action`, { actionMenee: e })
        .then((res) => {
          const newArrets = [...arrets]
          newArrets[idx] = res.data
          setArrets(newArrets)
          console.log(res.data)
          message.success(' statut mis a jour !')
        })
        .catch((e) => message.error(e))
    }, 1000)

    setTimer(newTimer)
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const base64ToBlob = (base64, type = 'application/pdf') => {
    const binStr = window.atob(base64)
    const len = binStr.length
    const arr = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    console.log(type)
    const blob = new Blob([arr], { type: type })
    return URL.createObjectURL(blob)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const search = () => {
    if (searchWord.length === 0) {
      setArrets(originalArrets)
    }
    if (searchWord.length < 3) {
      return
    }
    if (searchWord.length >= 3) {
      axios
        .post('arret/find-by-text', { query: searchWord })
        .then((res) => {
          setArrets(res.data)
        })
        .catch((e) => {})
    }
  }

  const deleteArret = (arretId) => {
    console.log(arretId)
    axios
      .delete(`arret/${arretId}`)
      .then((res) => {
        console.log(res)
        const arretIdx = arrets.findIndex((arret) => res.data._id === arret._id)
        const newArrets = [...arrets]
        newArrets.splice(arretIdx, 1)
        console.log(newArrets)
        setArrets(newArrets)
        message.success('Arret supprimé !')
      })
      .catch((e) => {
        message.error("Vous n'etes pas autorisé a supprimer !")
      })
  }

  const loadArrets = () => {
    axios
      .get('arret')
      .then((res) => {
        setArrets(res.data)
        setOriginalArret(res.data)
      })
      .catch((e) => console.log(e))
  }

  const getDocument = (documentId) => {
    setIsLoading(true)
    axios
      .get(`/arret/${documentId}`)
      .then((res) => {
        //setDocumentType(res.data.type)
        //setDocumentData(res.data.data)
        console.log(res.data.type)
        let pdfUrl
        if (res.data.type === 'pdf') pdfUrl = base64ToBlob(res.data.data)
        else pdfUrl = base64ToBlob(res.data.data, 'image/png')

        window.open(pdfUrl, '_blank')
        //setShowDocument(true)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      {isLoading && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <Modal
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        footer={[
          <Button key="back" onClick={() => setShowDocument(false)}>
            fermer
          </Button>,
        ]}
        width={1200}
        title="Document"
        open={showDocument}
        onCancel={() => setShowDocument(false)}
      >
        <div>
          {documentType === 'pdf' && (
            <Document
              file={`data:application/pdf;base64,${documentData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={2 / 5} key={idx} pageNumber={idx + 1} />
              })}
            </Document>
          )}

          {documentType !== 'pdf' && (
            <img
              className="w-[600px]"
              src={`data:image/png;base64,${documentData}`}
            />
          )}
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setIsOpenModal(false)}
        open={isOpenModal}
      >
        <Select
          className=" mt-10 h-8 px-2 py-1  text-gray-600 font-light rounded-md placeholder-gray-400 w-96 "
          showSearch
          placeholder=""
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: 'Arret maladie',
              label: 'Arret maladie',
              key: 'Arret maladie',
            },
            {
              value: 'Accident de travail',
              label: 'Accident de travail',
              key: 'Accident de travail',
            },
            {
              value: 'Maladie professionnelle',
              label: 'Maladie professionnelle',
              key: 'Maladie professionnelle',
            },
            {
              value: 'Congé maternité',
              label: 'Congé maternité',
              key: 'Congé maternité',
            },
            {
              value: 'Congé paternité',
              label: 'Congé paternité',
              key: 'Congé paternité',
            },
            {
              value: 'Autres absences',
              label: 'Autres absences (à preciser)',
              key: 'Autres absences',
            },
          ]}
        />
      </Modal>
      <div className="flex justify-start flex-col ml-10 mb-10 mt-10">
        <div className="flex items-center space-x-2 h-10">
          <p className="text-xl text-gray-400">Accueil / Gestion / Absences</p>
        </div>
        <div className="flex items-center space-x-2">
          <Input
            ref={searchInputRef}
            onChange={(e) => setSearchWord(e.target.value)}
            className={`w-80 rounded-md duration-500 border border-gray-200 focus:outline-none`}
            placeholder="Recherche"
            style={{ outline: 'none' }}
          />
          <button
            onClick={() => search()}
            className="bg-green-500 text-white p-2 rounded-md"
          >
            Rechercher
          </button>
        </div>
      </div>
      <div className="border border-gray-300 rounded-md">
        <div className="flex items-center justify-between  text-md py-3">
          <p className="w-1/6 text-center">INTERIMAIRE</p>
          <p className="w-1/6 text-center">TYPE ABSENCE</p>
          <p className="w-1/6 text-center">DATE</p>
          <p className="w-1/6 text-center">STATUT</p>
          <p className="w-1/6 text-center">ACTION MENEE</p>
          <p className="w-1/6 text-center">ACTION</p>
        </div>
        <div className="flex flex-col">
          {arrets.map((arret, idx) => {
            return (
              <div
                className="flex  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
                key={idx}
              >
                <p className="w-1/6 text-center text-lg capitalize">
                  {arret.candidateName.toLowerCase()}
                </p>
                <p className="w-1/6 text-center text-lg capitalize">
                  {arret.typeAbsence}
                </p>
                <p className="w-1/6  flex flex-col items-center justify-center text-lg capitalize">
                  <span>Du {formatJsDate(arret.startDate)}</span>
                  <span>Au {formatJsDate(arret.endDate)}</span>
                </p>
                <div className="w-1/6 text-center flex justify-center">
                  {/*  <Select
                  className=" text-green-600 font-light bg-green-600 rounded-md placeholder-gray-400 w-40 "
                  optionFilterProp="children"
                  onChange={(e) => updateStatus(arret, e, idx)}
                  value={arret.status}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: 'A traiter',
                      label: 'A traiter',
                      key: 'A traiter',
                    },
                    {
                      value: 'Traitée',
                      label: 'Traitée',
                      key: 'Traitée',
                    },
                  ]}
                /> */}

                  {arret.status === 'Traitée' && (
                    <p className="bg-green-200 text-green-700 rounded-md text-lg w-fit p-2 ">
                      {arret.status}
                    </p>
                  )}
                  {arret.status === 'A traiter' && (
                    <p className="bg-yellow-200 text-yellow-700 rounded-md text-lg w-fit p-2 ">
                      {arret.status}
                    </p>
                  )}
                </div>

                <div className="w-1/6 flex justify-center">
                  <Input
                    className="rounded-md border border-gray-200 focus:outline-none"
                    placeholder="Action menée"
                    value={arret.actionMenee}
                    style={{ outline: 'none' }}
                    onChange={(e) => updateAction(arret, e.target.value, idx)}
                  />
                </div>

                <div className="w-1/6 flex items-center justify-center space-x-2">
                  <Popover
                    placement="bottom"
                    content={
                      <div className="flex flex-col capitalize text-base space-y-3">
                        <p
                          onClick={() => getDocument(arret._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img src={visualize} className="w-8" />
                          <span>Visualiser</span>
                        </p>

                        <p
                          onClick={() => updateStatus(arret, idx)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img src={traiter} className="w-8" />
                          <span>Traiter</span>
                        </p>
                        {/*  <p onClick={() => getDocument(arret._id)} className='hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer'>
                  <img src={download} className='w-8'/>
                  <span>Télécharger</span>
                  </p> */}
                        <p
                          onClick={() => deleteArret(arret._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img src={poubelle} className="w-8" />
                          <span>Supprimer</span>
                        </p>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button>...</Button>
                  </Popover>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default ArretMaladie
