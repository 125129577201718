export const documentsList = [
  'Accident de trajet',
  'Accident de travail',
  'Arret maladie',
  'Atradius',
  'Attestation Pôle Emploi',
  'Avis à tiers détenteur',
  'Autorisation provisoire de séjour',
  'CACES',
  'Carte BTP',
  'Carte conducteur',
  "Carte d'identité",
  'Carte de résident',
  'Carte de séjour',
  'Carte européenne',
  'Carte vitale',
  'Certificat de travail',
  'Congé',
  'Congé paternité',
  'Congé maternité',
  'Contrat',
  'Contrôle préfecture',
  'Devis non signé',
  'Devis signé',
  'Diplôme',
  'Document client',
  'Fiche de paie',
  'Fiche de paie (controle taux et niveaux)',
  'Fiche inscription',
  'FCO',
  'FIMO',
  'Habilitation',
  'Justificatif de domicile',
  'KBIS',
  'Permis de conduire',
  'Rib',
  'Travailleur handicapé ( RQTH )',
  'Vaccination',
  'Visite médicale',
  'Autre',
]
