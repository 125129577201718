import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Checkbox, DatePicker, Input, Modal, Select, message } from 'antd'
import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { TimePicker } from '@mui/x-date-pickers'
import qualifications from '../../qualifications/qualifications'
import {
  DeleteForeverOutlined,
  RemoveCircleOutlineRounded,
} from '@mui/icons-material'
import epinone from '../../assets/img/epinone.png'
import epicasque from '../../assets/img/epicasque.png'
import epichaussure from '../../assets/img/epichaussure.png'
import epigilet from '../../assets/img/epigilet.png'
import epimasque from '../../assets/img/epimasque.png'
import epiaudio from '../../assets/img/epiaudio.png'
import epiaudioselected from '../../assets/img/epiaudioselected.png'
import epicasqueselected from '../../assets/img/epicasqueselected.png'
import epichaussureselected from '../../assets/img/epichaussureselected.png'
import epigiletselected from '../../assets/img/epigiletselected.png'
import epimasqueselected from '../../assets/img/epimasqueselected.png'
import epibleuselected from '../../assets/img/epibleuselected.png'
import epigantselected from '../../assets/img/epigantselected.png'
import epiharnaisselected from '../../assets/img/epiharnaisselected.png'
import epilunetteselected from '../../assets/img/epilunetteselected.png'
import epinoneselected from '../../assets/img/epinoneselected.png'
import epiautre from '../../assets/img/epiautre.png'
import epibleu from '../../assets/img/epibleu.png'
import epigant from '../../assets/img/epigant.png'
import epiharnais from '../../assets/img/epiharnais.png'
import epilunette from '../../assets/img/epilunette.png'
import { MailOutlined } from '@ant-design/icons'
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'

const CreateContract = ({
  candidateId,
  isCreateContract,
  setIsCreateContract,
}) => {
  const navigate = useNavigate()

  const [step, setStep] = useState('1')
  const [clientAddressesArray, setClientAdressesArray] = useState([])
  const [clientContactsArray, setClientContactsArray] = useState([])
  const [prime, setPrime] = useState('')
  const [primeType, setPrimeType] = useState('')
  const [taux, setTaux] = useState('')
  const [recruiters, setRecruiters] = useState([])
  const [clients, setClients] = useState([])

  const images = [
    { selected: epiaudioselected, name: epiaudio, description: 'audio' },
    { selected: epicasqueselected, name: epicasque, description: 'casque' },
    {
      selected: epichaussureselected,
      name: epichaussure,
      description: 'chaussure',
    },
    { selected: epigiletselected, name: epigilet, description: 'gilet' },
    { selected: epimasqueselected, name: epimasque, description: 'masque' },
    { selected: epibleuselected, name: epibleu, description: 'bleu' },
    { selected: epigantselected, name: epigant, description: 'gant' },
    { selected: epiharnaisselected, name: epiharnais, description: 'harnais' },
    { selected: epilunetteselected, name: epilunette, description: 'lunette' },
  ]

  const [contract, setContract] = useState({
    PenibiliteNC: 'O',
    candidate: candidateId,
    primes: [],
  })

  const deleteEpi = () => {
    const newContract = { ...contract }
    newContract.epis = []
    console.log(newContract)
    setContract(newContract)
  }

  const addPrime = () => {
    const newPrime = {
      prime,
      primeType,
      taux,
    }
    const primes = contract.primes ? [...contract.primes, newPrime] : [newPrime]
    setContract({
      ...contract,
      primes,
    })

    setPrime('')
    setPrimeType('')
    setTaux('')
  }

  const addEpi = (image) => {
    const epis = contract.epis ? contract.epis : []
    if (!epis.includes(image.description)) epis.push(image.description)
    else {
      const index = epis.findIndex((item) => _.isEqual(item, image.description))

      if (index !== -1) {
        epis.splice(index, 1)
      }
    }
    const newContract = { ...contract, epis }
    setContract(newContract)
    console.log(newContract)
  }

  const clientArray = clients.map((client) => {
    return {
      value: client._id,
      label: `${client.companyName} - ${client.address} ${client.postCode} ${client.town}`,
    }
  })

  const selectStep = (step, direction) => {
    if (
      step === '4' &&
      (contract.typeContrat === 'CDI' || contract.typeContrat === 'CDD') &&
      direction === 'f'
    )
      step = '6'
    if (
      step === '5' &&
      (contract.typeContrat === 'CDI' || contract.typeContrat === 'CDD') &&
      direction === 'b'
    )
      step = '3'
    if (step === '17' && !contract.epis.length) {
      validateContract()
      setIsCreateContract(false)
      setContract({
        PenibiliteNC: 'O',
        candidate: candidateId,
        primes: [],
      })
    }
    setStep(step)
  }

  const loadRecruiters = () => {
    axiosInstance
      .get('user')
      .then((res) => {
        const recruitersArray = res.data.map((recruiter, idx) => {
          return {
            value: recruiter._id,
            label:
              recruiter.firstname
                .toLowerCase()
                .split('')
                .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                .join('') +
              ' ' +
              recruiter.lastname
                .toLowerCase()
                .split('')
                .map((char, index) => (index === 0 ? char.toUpperCase() : char))
                .join(''),
            key: idx,
          }
        })
        setRecruiters(recruitersArray)
      })
      .catch((e) => {
        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const deletePrime = (idx) => {
    const newPrimes = contract.primes.filter((prime, index) => idx !== index)
    setContract({
      ...contract,
      primes: newPrimes,
    })
  }

  const selectRecruiter = (e) => {
    const newContract = { ...contract }
    newContract.recruiter = e
    setContract(newContract)
  }

  const setAdressFromSiege = (client) => {
    const newContract = { ...contract }
    newContract.clientName = client.companyName
    newContract.address = client.address
    newContract.postCode = client.postCode
    newContract.town = client.town
    setContract(newContract)
  }

  const setAdressFromLivraison = (client) => {
    const newContract = { ...contract }
    newContract.clientName = client.companyName
    newContract.address = client.addressLivraison
    newContract.postCode = client.postCodeLivraison
    newContract.town = client.townLivraison
    setContract(newContract)
  }

  const setAdressFromFacturation = (client) => {
    const newContract = { ...contract }
    newContract.clientName = client.companyName
    newContract.address = client.addressFacturation
    newContract.postCode = client.postCodeFacturation
    newContract.town = client.townFacturation
    setContract(newContract)
  }

  const selectContact = (e) => {
    const newContract = { ...contract }
    newContract.contact = e
    setContract(newContract)
  }

  const selectQualification = (e) => {
    const newContract = { ...contract }
    newContract.qualification = e
    setContract(newContract)
  }

  const selectPenibilite = (e) => {
    const newContract = { ...contract }
    newContract.PenibiliteNC = e
    setContract(newContract)
  }

  const selectMotif = (e) => {
    const newContract = { ...contract }
    newContract.otherMotif = null
    newContract.motif = e
    setContract(newContract)
  }

  const selectOtherMotif = (e) => {
    const newContract = { ...contract }
    newContract.motif = null
    newContract.otherMotif = e
    setContract(newContract)
  }

  const selectJustificatif = (e) => {
    const newContract = { ...contract }
    newContract.justificatif = e
    setContract(newContract)
  }

  const selectNombreHeureHebdo = (e) => {
    const newContract = { ...contract }
    newContract.nbHeureHebdo = e
    setContract(newContract)
    console.log(newContract)
  }

  const selectDateDebut = (e) => {
    console.log(e.getDate())
    const newContract = { ...contract }
    newContract.startDate = e
    setContract(newContract)
  }

  const selectDateFin = (e) => {
    const newContract = { ...contract }
    newContract.endDate = e
    setContract(newContract)
  }

  const selectHeureDebut = (e) => {
    const newContract = { ...contract }
    newContract.startHour = e
    setContract(newContract)
  }

  const selectHeureFin = (e) => {
    const newContract = { ...contract }
    newContract.endHour = e
    setContract(newContract)
  }

  const selectClientName = (e) => {
    const newContract = { ...contract }
    newContract.clientName = e
    setContract(newContract)
  }

  const selectAddress = (e) => {
    const newContract = { ...contract }
    newContract.address = e
    setContract(newContract)
  }

  const selectIsVehicule = (bool) => {
    const newContract = { ...contract }
    newContract.isVehicule = bool
    setContract(newContract)
  }

  const selectConsigne = (e) => {
    const newContract = { ...contract }
    newContract.consigne = e
    setContract(newContract)
  }

  const selectPostCode = (e) => {
    const newContract = { ...contract }
    newContract.postCode = e
    setContract(newContract)
  }

  const selectTown = (e) => {
    const newContract = { ...contract }
    newContract.town = e
    setContract(newContract)
  }

  const setTask = (e) => {
    const newContract = { ...contract }
    newContract.task = e
    setContract(newContract)
  }

  const selectHourlyRate = (e) => {
    const newContract = { ...contract }
    newContract.hourlyRate = e
    setContract(newContract)
  }

  const selectEpiProvider = (e) => {
    const newContract = { ...contract }
    newContract.epiProvider = e
    setContract(newContract)
  }

  const addWorkedDay = (day) => {
    let days = contract.workedDays
    if (days.includes(day)) {
      console.log(days, '1')
      days = days.filter((d) => d !== day)
      console.log(days, '2')
    } else {
      days.push(day)
    }
    setContract({ ...contract, workedDays: days })
  }

  const usedMotif = [
    "42 : Accroissement temporaire d'activité lié à une variation cyclique d'activité",
    "52 : Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable",
    "11 : Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
    '83 : Emploi temporaire par nature défini comme emploi à caractère saisonnier',
    "93 : Emploi temporaire par nature défini comme emploi d'usage constant",
  ]

  const otherMotif = [
    '10 : Formation',
    "72 : Accroissement temporaire d'activité lié à des travaux dont  l'exécution immédiate est nécessitée par des mesures de sécurité",
    "12 : Remplacement d'un salarié en cas de passage provisoire à temps partiel, conclu par avenant à son contrat de travail ou par échange écrit entre le salarié et l'employeur",
    "62 : Accroissement temporaire d'activité lié à une commande exceptionnelle à l'exportation",
    "21 : Conformément aux dispositions légales faciliter l'embauche des personnes sans emploi ayant des difficultés sociales professionnelles particulières",
    "14 : Remplacement d'un salarié en cas de l'entrée effective du salarié recruté par un contrat à durée indéterminée appelé à le remplacer",
    "23 : Remplacement d'un associé non salarié d'une société civile professionnelle d'une société civile de moyens ou d'une société d'exercice libéral",
    "25 : Remplacement d'un chef d'exploitation agricole ou d'une entreprise mentionnée aux 1° à 4° de l'art. L.722-1 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
    "24 : Remplacement d'un chef d'entreprise artisanale industriel, commerciale, d'une profession libérale, de son conjoint participant réellement à l'activité à titre professionnel et habituel",
    "31 : Remplacement d'un salarié en cas de départ définitif  précédant la suppression de son poste de travail",
    "22 : L'ETT et l'E.U. s'engagent, pour une durée, dans les  conditions légales à assurer un complément de formation professionnelle au salarié",
    "HANDICP : Bénéficiaire de l'obligation d'emploi de l'article L. 5212-13 CT",
    "26 : Remplacement d'un aide familial, associé d'exploit. ou leur conjoint mentionné  à l'art. L.722-10 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
  ]

  const usedMotifArray = usedMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })
  const otherMotifArray = otherMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })
  const qualificationsArray = qualifications.map((qualification, idx) => {
    return { value: qualification, label: qualification, key: idx }
  })

  const validateContract = () => {
    axiosInstance
      .post('pld-contract', contract)
      .then((res) => {
        console.log(res.data)
        message.success('Contrat créé ! ')
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite ! ")
      })
  }

  useEffect(() => {
    loadRecruiters()

    axiosInstance
      .get('client')
      .then((res) => setClients(res.data))
      .catch((e) => {
        if (e.response.data && e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }, [])

  const selectClient = (e) => {
    const client = clients.find((client) => client._id === e)
    console.log(client)
    const newContract = contract
    newContract.client = client
    newContract.clientSiret = client.siret
    setContract(newContract)
    const clientAdresses = [
      ` ${client.address} ${client.postCode} ${client.town} `.toUpperCase(),
      `Adresse de livraison : ${client.addressLivraison} ${client.postCodeLivraison} ${client.townLivraison} `.toUpperCase(),
      `Adresse de facturation : ${client.addressFacturation} ${client.postCodeFacturation} ${client.townFacturation} `.toUpperCase(),
    ]

    const clientContactsArray = client.contacts.map((contact, idx) => {
      return {
        value: `${contact.contactFirstname} ${contact.contactLastname} ${contact.contactEMail} ${contact.contactPhone}`,
        label: `${contact.contactFirstname} ${contact.contactLastname} ${contact.contactEMail} ${contact.contactPhone}`,
        key: idx,
      }
    })

    const clientAddressesArray = clientAdresses.map((address, idx) => {
      return {
        value: address,
        label: address,
        key: idx,
      }
    })
    setClientAdressesArray(clientAddressesArray)
    setClientContactsArray(clientContactsArray)
  }

  return (
    <Modal
      width={1000}
      open={isCreateContract}
      footer={null}
      onCancel={() => setIsCreateContract(false)}
    >
      <div className="h-[600px]">
        {step === '1' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold  text-center text-3xl">Client</p>

              <Select
                className="w-full py-6 my-2 ring-0"
                showSearch="true"
                onChange={(e) => selectClient(e)}
                value={
                  contract.client
                    ? `${contract.client?.companyName} - ${contract.client?.address} ${contract.client?.postCode} ${contract.client?.town}`
                    : null
                }
                optionFilterProp="children"
                placeholder="Client"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clientArray}
              />
            </div>

            <div className="flex items-center justify-center ">
              <button
                disabled={!contract.client}
                onClick={() => selectStep('2', 'f')}
                className={`${
                  !contract.client
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '2' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">Contact</p>

              <Select
                className="w-full py-6 my-2 ring-0"
                onChange={(e) => selectContact(e)}
                value={contract.contact ? `${contract.contact}` : null}
                optionFilterProp="children"
                placeholder="Contact"
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={clientContactsArray}
              />
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('1', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('3', 'f')}
                className={`${
                  !contract.contact
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.contact}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '3' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Motif de recours
              </p>

              <div>
                <p>Les plus utilisés</p>
                <Select
                  className="w-full py-3 my-2"
                  showSearch
                  onChange={(e) => selectMotif(e)}
                  value={contract.motif ? `${contract.motif}` : null}
                  optionFilterProp="children"
                  placeholder="Motif de recours"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={usedMotifArray}
                />
              </div>

              <div>
                <p>Les autres motifs</p>
                <Select
                  className="w-full py-3 my-2"
                  showSearch
                  onChange={(e) => selectOtherMotif(e)}
                  value={contract.otherMotif ? `${contract.otherMotif}` : null}
                  optionFilterProp="children"
                  placeholder="Motif de recours"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={otherMotifArray}
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('2', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('4', 'f')}
                className={`${
                  !contract.motif && !contract.otherMotif
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.motif && !contract.otherMotif}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '4' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Développer le motif
              </p>

              <div>
                <p className="mt-6 text-lg"> {contract.motif} </p>
                <Input
                  onChange={(e) => selectJustificatif(e.target.value)}
                  value={
                    contract.justificatif ? `${contract.justificatif}` : null
                  }
                  className="rounded-md bcontract bcontract-gray-300 mb-2 mt-6"
                  placeholder="Justificatif"
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('3', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('5', 'f')}
                className={`${
                  !contract.justificatif
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.justificatif}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '5' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Date de la mission
              </p>

              <div className="flex items-center my-6 space-x-2 justify-center">
                <DatePicker
                  onChange={(e) => selectDateDebut(e.$d)}
                  value={contract.startDate ? dayjs(contract?.startDate) : null}
                  className="w-1/2 h-10"
                  format="DD/MM/YYYY"
                  placeholder="Date de début de la mission"
                />
                <DatePicker
                  onChange={(e) => selectDateFin(e.$d)}
                  value={contract.endDate ? dayjs(contract?.endDate) : null}
                  className="w-1/2 h-10"
                  format="DD/MM/YYYY"
                  placeholder="Date de fin de la mission"
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('4', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              {contract.typeContrat !== 'CDI' && (
                <button
                  onClick={() => selectStep('6', 'f')}
                  className={`${
                    !contract.startDate || !contract.endDate
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!contract.startDate || !contract.endDate}
                >
                  Suivant
                </button>
              )}

              {contract.typeContrat === 'CDI' && (
                <button
                  onClick={() => selectStep('7', 'f')}
                  className={`${
                    !contract.startDate
                      ? 'bg-gray-200 text-gray-600'
                      : 'bg-mountain-500 text-white'
                  } rounded-md p-4 `}
                  disabled={!contract.startDate}
                >
                  Suivant
                </button>
              )}
            </div>
          </div>
        )}

        {step === '6' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Horaire de la mission
              </p>

              <div className="flex items-center my-6 space-x-2">
                <TimePicker
                  value={contract.startHour ? `${contract.startHour}` : null}
                  onChange={(newValue) =>
                    selectHeureDebut(
                      newValue.$d.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      }),
                    )
                  }
                  format="HH:mm"
                  className="w-1/2 outline-none"
                  placeholder="Heure de début"
                />
                <TimePicker
                  format="HH:mm"
                  value={contract.endHour ? `${contract.endHour}` : null}
                  className="w-1/2 outline-none focus:outline-none hover:outline-none"
                  onChange={(newValue) =>
                    selectHeureFin(
                      newValue.$d.toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      }),
                    )
                  }
                  placeholder="Heure de fin"
                />
              </div>
              <div>
                {/* <p className="font-semibold text-center text-3xl">
                  Jours travaillés
                </p> */}
                {/* <div className="flex items-center justify-around space-x-1 mt-6">
                  <p
                    onClick={() => addWorkedDay('Lundi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Lundi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Lundi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Mardi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Mardi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Mardi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Mercredi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Mercredi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Mercredi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Jeudi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Jeudi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Jeudi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Vendredi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Vendredi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Vendredi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Samedi')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Samedi')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Samedi
                  </p>
                  <p
                    onClick={() => addWorkedDay('Dimanche')}
                    className={`p-2 cursor-pointer rounded-md ${
                      contract.workedDays.includes('Dimanche')
                        ? 'bg-green-400 text-white'
                        : 'bcontract bcontract-gray-500'
                    }`}
                  >
                    Dimanche
                  </p>
                </div> */}

                <div>
                  <Input
                    onChange={(e) => selectNombreHeureHebdo(e.target.value)}
                    value={
                      contract.nbHeureHebdo ? `${contract.nbHeureHebdo}` : null
                    }
                    className="rounded-md bcontract bcontract-gray-300 mb-2 mt-6"
                    placeholder="Nombre heures hebdomadaire"
                  />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('5', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('7', 'f')}
                disabled={!contract.startHour || !contract.endHour}
                className={`${
                  !contract.startHour || !contract.endHour
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '7' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Lieu de la mission
              </p>
              <div className="flex space-x-2 items-center justify-around mt-6">
                <p
                  onClick={() => setAdressFromSiege(contract.client)}
                  className="text-xs text-blue-500
            "
                >
                  Recopier l'adresse du siège
                </p>
                <p
                  onClick={() => setAdressFromLivraison(contract.client)}
                  className="text-xs text-blue-500
            "
                >
                  Recopier l'adresse de livraison
                </p>
                <p
                  onClick={() => setAdressFromFacturation(contract.client)}
                  className="text-xs text-blue-500"
                >
                  Recopier l'adresse de facturation
                </p>
              </div>
              <div className="flex flex-col items-center my-6 space-y-3">
                <Input
                  onChange={(e) => selectClientName(e.target.value)}
                  value={contract.clientName ? `${contract.clientName}` : null}
                  className="rounded-md bcontract bcontract-gray-300 mb-2"
                  placeholder="Nom du client final"
                />
                <Input
                  onChange={(e) => selectAddress(e.target.value)}
                  value={contract.address ? `${contract.address}` : null}
                  className="rounded-md bcontract bcontract-gray-300 mb-2"
                  placeholder="Adresse"
                />
                <Input
                  onChange={(e) => selectPostCode(e.target.value)}
                  value={contract.postCode ? `${contract.postCode}` : null}
                  className="rounded-md bcontract bcontract-gray-300 mb-2"
                  placeholder="Code postal"
                />
                <Input
                  onChange={(e) => selectTown(e.target.value)}
                  value={contract.town ? `${contract.town}` : null}
                  className="rounded-md bcontract bcontract-gray-300 mb-2"
                  placeholder="Ville"
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('6', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('8', 'f')}
                className={`${
                  !contract.clientName ||
                  !contract.address ||
                  !contract.postCode ||
                  !contract.town
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={
                  !contract.clientName ||
                  !contract.address ||
                  !contract.postCode ||
                  !contract.town
                }
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '8' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">Poste</p>

              <div className="flex flex-col items-center my-6 space-y-3">
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  onChange={(e) => selectQualification(e)}
                  value={
                    contract.qualification ? `${contract.qualification}` : null
                  }
                  optionFilterProp="children"
                  placeholder="Poste"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={qualificationsArray}
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('7', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('9', 'f')}
                className={`${
                  !contract.qualification
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.qualification}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {/*  {step === '10' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Renseigner le taux horaire
              </p>

              <div className="flex flex-col items-center my-6 space-y-3">
                <Input
                  onChange={(e) => selectHourlyRate(e.target.value)}
                  value={contract.hourlyRate ? `${contract.hourlyRate}` : null}
                  className="rounded-md bcontract bcontract-gray-300 mb-2"
                  placeholder="Taux horaire"
                />
              </div>
            </div>
            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('9', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('11', 'f')}
                className={`${
                  !contract.hourlyRate
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.hourlyRate}
              >
                Suivant
              </button>
            </div>
          </div>
        )} */}

        {/* {step === '11' && (
          <div className="flex flex-col h-full justify-between ">
            <div>
              <p className="font-semibold mb-6 text-center text-3xl">
                Renseigner primes/indemnités
              </p>

              <div className="flex items-center space-x-4 mb-4">
                <div className="w-1/2 flex flex-col space-y-1">
                  <Select
                    className="w-full"
                    onChange={(e) => setPrime(e)}
                    value={prime}
                    optionFilterProp="children"
                    placeholder="Primes"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={[
                      { value: 'Primes', label: 'Primes', key: 'Primes' },
                      {
                        value: 'Indemnités',
                        label: 'Indemnités',
                        key: 'Indemnités',
                      },
                    ]}
                  />

                  <Input
                    onChange={(e) => setPrimeType(e.target.value)}
                    value={primeType}
                    className="rounded-md bcontract bcontract-gray-300 mb-2"
                    placeholder="Type de prime"
                  />

                  <Input
                    onChange={(e) => setTaux(e.target.value)}
                    value={taux}
                    className="rounded-md bcontract bcontract-gray-300 mb-2"
                    placeholder="Taux ou montant"
                  />
                </div>

                <div className=" w-1/2 flex items-center justify-start">
                  <button
                    onClick={addPrime}
                    className="my-2 rounded-md  bg-mountain-500 p-2 text-white"
                  >
                    <ShoppingCartCheckoutIcon />
                  </button>
                </div>
              </div>

              <div>
                <div className="flex items-center space-x-2 uppercase my-4">
                  <p className="w-1/4 text-center">Prime</p>
                  <p className="w-1/4 text-center">Type de prime</p>
                  <p className="w-1/4 text-center">Taux ou montant </p>
                  <p className="w-1/4 text-center"></p>
                </div>

                {contract.primes.map((prime, idx) => {
                  return (
                    <div key={idx} className="flex items-center space-x-2">
                      <p className="w-1/4 text-center">{prime.prime}</p>
                      <p className="w-1/4 text-center">{prime.primeType}</p>
                      <p className="w-1/4 text-center">{prime.taux}</p>
                      <p
                        onClick={() => deletePrime(idx)}
                        className="w-1/4 text-center"
                      >
                        <DeleteForeverOutlined />
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('10', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('12', 'f')}
                className="bg-mountain-500 rounded-md p-4 text-white"
              >
                Suivant
              </button>
            </div>
          </div>
        )} */}

        {step === '9' && (
          <div className="flex flex-col h-full justify-between">
            <div>
              <p className="font-semibold text-center text-3xl">
                Consignes particulières
              </p>

              <div className="flex items-center my-6">
                <Select
                  className="w-full py-6 my-2"
                  showSearch
                  optionFilterProp="children"
                  onChange={(e) => selectConsigne(e)}
                  value={contract.consigne ? contract.consigne : null}
                  placeholder="Consignes"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    {
                      value: '0 : Aucune',
                      label: '0 : Aucune',
                      key: '0',
                    },
                    {
                      value:
                        "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                      label:
                        "1 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire.",
                      key: '1',
                    },
                    {
                      value:
                        "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                      label:
                        "2 : La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                      key: '2',
                    },
                    {
                      value:
                        "3 : Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                      label:
                        "3 :  Port des EPI obligatoire. Le respect des règles de sécurité et d'hygiène est obligatoire. La formation sécurité est dispensée par le responsable en poste le jour de l'intégration.",
                      key: '3',
                    },
                  ]}
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('8', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('10', 'f')}
                className={`${
                  !contract.consigne
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.consigne}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '10' && (
          <div className="flex flex-col justify-between h-full">
            <div>
              <p className="font-semibold text-center text-3xl">
                Quelles sont les tâches/missions ?
              </p>

              <div className="flex items-center my-6 space-x-10 justify-center">
                <Input
                  onChange={(e) => setTask(e.target.value)}
                  value={contract.task ? contract.task : null}
                  placeholder="Preciser..."
                />
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('9', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={() => selectStep('11', 'f')}
                className={`${
                  !contract.task
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.task}
              >
                Suivant
              </button>
            </div>
          </div>
        )}

        {step === '11' && (
          <div className="flex flex-col justify-between h-full">
            <div>
              <p className="font-semibold text-center text-3xl">
                Penibilités non communiquées ?
              </p>

              <div className="flex items-center my-6 space-x-10 justify-center">
                <p className="flex items-center space-x-1">
                  <Checkbox
                    checked={contract.PenibiliteNC === 'O'}
                    onChange={() => selectPenibilite('O')}
                  />
                  <span>OUI</span>
                </p>
                <p className="flex items-center space-x-1">
                  <Checkbox
                    checked={contract.PenibiliteNC === 'N'}
                    onChange={() => selectPenibilite('N')}
                  />
                  <span>NON</span>
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-2">
              <button
                onClick={() => selectStep('10', 'b')}
                className="bg-black rounded-md p-4 text-white"
              >
                Retour
              </button>
              <button
                onClick={validateContract}
                className={`${
                  !contract.task
                    ? 'bg-gray-200 text-gray-600'
                    : 'bg-mountain-500 text-white'
                } rounded-md p-4 `}
                disabled={!contract.task}
              >
                Creer le contrat
              </button>
            </div>
          </div>
        )}

        {/* {step === '16' && (
        <div className="flex flex-col h-full justify-between mt-6">
          <div>
            <p className="font-semibold text-center text-3xl mb-6">
              EPI nécessaires
            </p>

            <div className="gap-2 grid grid-cols-5">
              <p
                onClick={deleteEpi}
                className={`relative bcontract flex items-center justify-center bcontract-gray-500 ${
                  contract.epis?.length === 0 ? 'bg-ultramarine-200' : ''
                }`}
              >
                {contract.epis?.length ? (
                  <img className="w-36 h-36" src={epinone} />
                ) : (
                  <img className="w-36 h-36" src={epinoneselected} />
                )}
              </p>
              {images.map((image) => {
                return (
                  <p
                    onClick={() => addEpi(image)}
                    className={`relative bcontract flex items-center justify-center bcontract-gray-500 ${
                      contract.epis?.includes(image.description)
                        ? 'bg-ultramarine-200'
                        : ''
                    } `}
                  >
                    {contract.epis?.includes(image.description) ? (
                      <img className="w-36 h-36" src={image.selected} />
                    ) : (
                      <img className="w-36 h-36" src={image.name} />
                    )}
                  </p>
                )
              })}
            </div>
          </div>

          <div className="flex items-center justify-center space-x-2">
            <button
              onClick={() => selectStep('15', 'b')}
              className="bg-black rounded-md p-4 text-white"
            >
              Retour
            </button>
            {!contract.epis.length && (
              <button
                onClick={validateContract}
                className={`bg-mountain-500 text-white rounded-md p-4 `}
              >
                Valider la commande
              </button>
            )}
            {contract.epis.length && (
              <button
                onClick={() => selectStep('17', 'f')}
                className="bg-mountain-500 rounded-md p-4 text-white"
              >
                Suivant
              </button>
            )}
          </div>
        </div>
      )}

      {step === '17' && (
        <div className="flex flex-col h-full justify-between">
          <div>
            <p className="font-semibold text-center text-3xl">
              Qui fournit les EPI ?
            </p>
          </div>

          <div className="flex text-2xl font-bold items-center my-6 space-x-10 justify-center">
            <p
              onClick={() => selectEpiProvider('EU')}
              className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                contract.epiProvider === 'EU'
                  ? 'text-white bg-black'
                  : 'text-black bcontract bcontract-black'
              }`}
            >
              {' '}
              EU
            </p>
            <p
              onClick={() => selectEpiProvider('AGENCE')}
              className={`px-2 py-2  rounded-md hover:cursor-pointer ${
                contract.epiProvider === 'AGENCE'
                  ? 'text-white bg-black'
                  : 'text-black bcontract bcontract-black'
              } `}
            >
              {' '}
              AGENCE
            </p>
          </div>

          <div className="flex items-center justify-center space-x-2">
            <button
              onClick={() => selectStep('16', 'b')}
              className="bg-black rounded-md p-4 text-white"
            >
              Retour
            </button>
            <button
              onClick={validateContract}
              className={`${
                !contract.epiProvider
                  ? 'bg-gray-200 text-gray-600'
                  : 'bg-mountain-500 text-white'
              } rounded-md p-4 `}
            >
              Valider la commande
            </button>
          </div>
        </div>
      )}

      {step === '18' && (
        <p>ok</p>
      )} */}
      </div>
    </Modal>
  )
}

export default CreateContract
