import { DatePicker, Input, Modal, message } from 'antd'
import { useState } from 'react'
import { dayjs } from 'dayjs'
import axios from '../../axios/axiosInstance'

const ValidateAcompte = ({
  acompteId,
  validerAcompte,
  onClose,
  setValiderAcompte,
}) => {
  const [amount, setAmount] = useState('')
  const [date, setDate] = useState(null)

  const validate = () => {
    axios
      .put(`acompte/${acompteId}`, {
        status: 'Validée',
        validatedAmount: amount,
        paymentDate: date,
      })
      .then((res) => {
        message.success('Modification enregistrées')
        onClose(res.data)
        setValiderAcompte(false)
      })
      .catch((e) => {
        message.error('Vous ne pouvez pas modifier !')
      })
  }

  return (
    <div>
      <Modal
        open={validerAcompte}
        footer={null}
        onCancel={() => setValiderAcompte(false)}
      >
        <div className="flex flex-col space-y-6 mt-10">
          <Input
            className="rounded-md border border-gray-300"
            placeholder="Montant de l'acompte"
            onChange={(e) => setAmount(e.target.value)}
            value={amount}
          />
          <DatePicker
            onChange={(date, dateString) => setDate(date.$d)}
            className="rounded-md border p-2 border-gray-300"
            format={'DD/MM/YYYY'}
            placeholder="Date de versement de l'acompte"
          />

          <button
            className="p-2 text-white bg-green-600 rounded-md"
            onClick={validate}
          >
            Valider l'acompte
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default ValidateAcompte
