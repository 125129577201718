import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const Planning = () => {
  const [rhs, setRhs] = useState([])
  const [days, setDays] = useState([])
  const [weekNumber, setWeekNumber] = useState('')
  const [sDate, setSDate] = useState(null)
  const [eDate, setEDate] = useState(null)

  useEffect(() => {
    const s = new Date()
    const { startDate, endDate } = getStartAndEndDate(s)
    console.log(startDate)
    console.log(endDate)
    getWeekNumber()
    getWeekDays(startDate, endDate)
    setSDate(startDate)
    setEDate(endDate)
    loadRhs(startDate, endDate)
  }, [])

  const switchToNextWeek = () => {
    setWeekNumber(weekNumber + 1)
    const startDate = new Date(sDate)
    startDate.setDate(sDate.getDate() + 7)
    const endDate = new Date(eDate)
    endDate.setDate(eDate.getDate() + 7)
    console.log(startDate)
    console.log(endDate)
    const newstartDateCalculation = new Date(startDate)
    getStartAndEndDate(newstartDateCalculation)
    setSDate(startDate)
    setEDate(endDate)
    getWeekDays(startDate, endDate)
    loadRhs(startDate, endDate)
  }

  const switchToPreviousWeek = () => {
    if (!weekNumber) return
    setWeekNumber(weekNumber - 1)
    const startDate = new Date(sDate)
    startDate.setDate(sDate.getDate() - 7)
    const endDate = new Date(eDate)
    endDate.setDate(eDate.getDate() - 7)
    console.log(startDate)
    console.log(endDate)
    const newstartDateCalculation = new Date(startDate)
    getStartAndEndDate(newstartDateCalculation)
    setSDate(startDate)
    setEDate(endDate)
    getWeekDays(startDate, endDate)
    loadRhs(startDate, endDate)
  }

  const getWeekNumber = () => {
    const date = new Date()
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1)
    const days = Math.round((date - firstDayOfYear) / (24 * 60 * 60 * 1000))
    const weekNumber = Math.ceil((days + firstDayOfYear.getDay() + 1) / 7)
    setWeekNumber(weekNumber)
  }

  const getStartAndEndDate = (sDate) => {
    const currentDay = sDate.getDay()
    const diff = sDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1) // Adjust when currentDay is Sunday

    const firstDay = new Date(sDate.setDate(diff))
    const lastDay = new Date(sDate.setDate(diff + 6))

    return {
      startDate: new Date(firstDay), // Return as JavaScript Date object
      endDate: new Date(lastDay), // Return as JavaScript Date object
    }
  }

  const getWeekDays = (firstDay, lastDay) => {
    console.log(firstDay)
    console.log(lastDay)
    const days = []
    const currentDate = new Date(firstDay)

    while (currentDate <= lastDay) {
      const dayName = getDayName(currentDate.getDay())
      const dayDate = formatDate(currentDate)

      days.push({ name: dayName, date: dayDate })

      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1)
    }

    setDays(days)
  }

  const getDayName = (dayIndex) => {
    const daysOfWeek = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ]
    return daysOfWeek[dayIndex]
  }

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    return `${day}/${month}`
  }

  const loadRhs = (startDate, endDate) => {
    axiosInstance
      .post('pld-contract/between', { startDate, endDate })
      .then((res) => {
        setRhs(res.data)
        console.log(res.data)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className="mt-10">
      <div className="justify-end my-6  items-center mx-20 grow flex space-x-2">
        <LeftOutlined onClick={() => switchToPreviousWeek()} />
        <p className="text-xl font-semibold">SEMAINE {weekNumber}</p>
        <RightOutlined onClick={() => switchToNextWeek()} />
      </div>

      <div className="flex items-center mb-6 text-center">
        <p className="w-1/12 uppercase text-xs">Nom</p>
        <p className="w-1/12 uppercase text-xs">Code postal</p>
        <p className="w-1/12 uppercase text-xs">Métier</p>

        {days.map((day, idx) => {
          return (
            <div key={idx} className="w-1/12 flex flex-col space-y-1">
              <p className=" uppercase text-xs">{day.name}</p>
              <p className=" uppercase text-xs">{day.date}</p>
            </div>
          )
        })}
      </div>

      {rhs.map((rh, idx) => {
        return (
          <div key={idx} className="flex items-center  text-center">
            <p className="w-1/12 font-semibold uppercase text-xs border-y border-r h-28 border-gray-200 pt-2 ">
              {rh.NomPrenom}
            </p>
            <p className="w-1/12 uppercase text-xs border-y border-r h-28 border-gray-200 pt-2 ">
              {75015}
            </p>
            <p className="w-1/12 uppercase text-xs border-y  border-r h-28 border-gray-200 pt-2 ">
              {rh.Profession}
            </p>
            <div className="flex w-1/12 flex-col items-center space-y-1 h-28 border-y border-r border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>
              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center h-28 space-y-1 border-r border-y border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center space-y-1 border-r border-y border-gray-200 h-28 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center space-y-1 h-28 border-r border-y border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center space-y-1 border-r h-28 border-y border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center space-y-1 border-r h-28 border-y border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>

            <div className="flex w-1/12  flex-col items-center space-y-1 border-r h-28 border-y border-gray-200 pt-2">
              <p className="text-pink-500 uppercase text-xs">
                {rh.RaisonSociale}
              </p>

              <p className=" uppercase text-mountain-600 text-[10px]">
                08H00-16H00
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default Planning
