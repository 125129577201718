import PersonIcon from '@mui/icons-material/Person'
import { Input } from 'antd'
import { useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'

const RHItem = ({ item }) => {
  const [tabJours, setTabJours] = useState(item.TabJours)

  const createRh = () => {
    axiosInstance
      .post('releve-heures', {
        ...item,
        TabJours: tabJours,
      })
      .then((res) => console.log(res.data))
      .catch((e) => console.log(e))
  }

  const updateHours = (idx, value) => {
    const newTabJours = tabJours.map((jour) => {
      if (jour.NumeroJour === idx) {
        return { ...jour, NbHeureJour: value }
      }
      return jour
    })

    setTabJours(newTabJours)
  }

  function formatDate(date) {
    const year = date.substring(0, 4)
    const month = date.substring(4, 6)
    const day = date.substring(6, 8)
    return `${day}/${month}/${year}`
  }

  return (
    <div className="flex items-center text-[10px] my-4 border border-gray-300 rounded-lg hover:scale-101 shadow-xl p-4 transition-all duration-300">
      <div className="w-4/12 flex space-x-4">
        <div className="h-16 w-16 rounded-full border border-yellow-400 flex items-center justify-center">
          <PersonIcon />
        </div>

        <div className="flex flex-col justify-between">
          <p className="font-bold">{item.NomPrenom}</p>
          <div>
            <p>{item.RaisonSociale.substring(0, 20)}...</p>
            {item.NumeroAvenant !== 0 && (
              <p>
                {item.NumeroContrat}_{item.NumeroAvenant} -{' '}
                {formatDate(item?.DateDebut)} - {formatDate(item?.DateFin)}
              </p>
            )}
            {item.NumeroAvenant === 0 && (
              <p>
                {item.NumeroContrat} - {item.DateDebut} - {item.DateFin}
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="flex items-center w-8/12 justify-between text-xs">
        <Input
          onChange={(e) => updateHours(1, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 1)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(2, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 2)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(3, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 3)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(4, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 4)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(5, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 5)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(6, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 6)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <Input
          onChange={(e) => updateHours(7, e.target.value)}
          disabled={!tabJours.some((jour) => jour.NumeroJour === 7)}
          className="w-14 px-2 rounded-lg border border-gray-300 placeholder:text-gray-300 placeholder:text-xs"
          placeholder="00:00"
        />
        <button
          onClick={createRh}
          className="bg-mountain-500 text-white p-2 rounded-md"
        >
          Envoyer vers tempo
        </button>
        <p></p>
      </div>
    </div>
  )
}

export default RHItem
