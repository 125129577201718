import { useEffect, useState } from "react";
import axios from '../../axios/axiosInstance';

const DemandeContrat = () => {

    const [demandes,setDemandes] = useState([]);

    const loadDemande = () => {
        axios.get("demande-contrat")
        .then((res)=> setDemandes(res.data))
        .catch(e=> console.log(e));
    }

    useEffect(()=> {
        loadDemande();
    },[])

    const formatJsDate = (date) => {
        const newDate = new Date(date)
        const day = String(newDate.getDate()).padStart(2, '0')
        const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
        const year = newDate.getFullYear()
    
        return `${day}/${month}/${year}`
      }
    


    return (
        <div>
            <div className="mt-10">
                <p className="text-2xl font-semibold mx-6">Demande de contrat freelance</p>
            </div>



            <div className="flex items-center justify-between mt-6 mx-6 bg-gray-200 rounded-t-md border border-gray-300 py-2">
                <p className="w-1/5 text-center">FREELANCE</p>
                <p className="w-1/5 text-center">CLIENT</p>
                <p className="w-1/5 text-center">CANDIDAT</p>
                <p className="w-1/5 text-center">DATE</p>
                <p className="w-1/5 text-center">POSTE</p>
            </div>

            <div className="mt-2">
                {demandes.map((demande,idx)=> {
                return <div key={idx} className="flex border hover:cursor-pointer border-gray-200 rounded-md shadow-md items-center justify-between mx-6 uppercase">
                    <p className="w-1/5 text-center">{demande.freelance.firstname} {demande.freelance.lastname}</p>
                    <p className="w-1/5 text-center">{demande.client.companyName}</p>
                    <p className="w-1/5 text-center">{demande.candidate.firstname} {demande.candidate.lastname}</p>
                    <div className="flex w-1/5 flex-col items-center justify-center">
                        <p>Du {formatJsDate(demande.startDate)}</p>
                        <p>Au {formatJsDate(demande.endDate)}</p>
                    </div>
                    <p className="w-1/5 text-center">{demande.clientQualification.TxtQualification}</p>
                </div>
                })}
            </div>
        </div>
    )
}

export default DemandeContrat;