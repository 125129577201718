import { useEffect, useRef, useState } from 'react'
import axios from '../../axios/axiosInstance'
import { Select, message, Modal, Input, Pagination } from 'antd'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { FilePdfOutlined, UploadOutlined } from '@ant-design/icons'
import FilterFacture from './FilterFacture'
import xlsIcon from '../../assets/img/xls.png'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const Facture = () => {
  const [bills, setBills] = useState([])
  const [originalBills, setOriginalBills] = useState([])
  const [isAddFile, setIsAddFile] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedBill, setSelectedBill] = useState(null)
  const [isFilterFacture, setIsFilterFacture] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [documentCount, setDocumentCount] = useState(0)
  const [searchEntry, setSearchEntry] = useState('')
  const [isReset, setIsReset] = useState(true)
  const [filter, setFilter] = useState('Toutes')

  const inputFileRef = useRef(null)

  useEffect(() => {
    loadBills()
  }, [])

  const search = () => {
    axios.post('bill/find-by-text', { query: searchEntry }).then((res) => {
      const loadedBills = res.data
      loadedBills.sort((a, b) => {
        // Compare client names
        const clientComparison = a.client.companyName.localeCompare(
          b.client.companyName,
        )

        // If client names are equal, compare candidate names
        if (clientComparison === 0) {
          return a.candidate.lastname.localeCompare(b.candidate.lastname)
        }

        return clientComparison
      })
      setBills(loadedBills)
    })
  }

  const onChangePage = (page, pageSize) => {
    axios
      .get(`bill/paginate/${page - 1}`)
      .then((res) => {
        const loadedBills = res.data
        loadedBills.sort((a, b) => {
          // Compare client names
          const clientComparison = a.client.companyName.localeCompare(
            b.client.companyName,
          )

          // If client names are equal, compare candidate names
          if (clientComparison === 0) {
            return a.candidate.lastname.localeCompare(b.candidate.lastname)
          }

          return clientComparison
        })
        setBills(loadedBills)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const updateStatus = (bill, e) => {
    axios
      .patch(`bill/${bill._id}`, { status: e })
      .then((res) => {
        console.log(res)
        const billIdx = bills.findIndex(
          (searchedBill) => bill._id === searchedBill._id,
        )
        const newBills = [...bills]
        newBills[billIdx] = res.data
        setBills(newBills)
        message.success('Modifications enregistrées !')
      })
      .catch((e) => console.log(e))
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const filterFactures = (status) => {
    if (status === 'Toutes') {
      setBills(originalBills)
      setFilter('Toutes')
      setIsReset(true)
      return
    }
    setIsReset(false)

    axios
      .get(`bill/filter/${status}`)
      .then((res) => {
        setBills(res.data)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite !")
        console.log(e)
      })
  }

  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    setSelectedFile(event.target.files[0])
  }

  const selectFileToUpload = (bill) => {
    setSelectedBill(bill)
    console.log(bill)
    setIsAddFile(true)
  }

  const exportToExcel = () => {
    const newBills = bills.map((facture) => {
      const {
        user,
        client,
        candidate,
        qualification,
        facturation,
        dates,
        comment,
      } = facture
      return {
        Recruteur: `${user.firstname} ${user.lastname}`,
        Client: client.companyName,
        Vacataire: `${candidate.firstname} ${candidate.lastname}`,
        Poste: qualification,
        Date: dates.map((d) => formatJsDate(d.dateDebut)).join('  '),
        Facturation: facturation
          .map((fact) => `${fact.quantity} ${fact.description} ${fact.rate}€`)
          .join('  '),
        Commentaire: comment,
      }
    })

    const workbook = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(newBills)

    XLSX.utils.book_append_sheet(workbook, sheet, 'Sheet 1')

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    })

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    saveAs(blob, 'data.xlsx')
  }

  const uploadFile = () => {
    const formData = new FormData()
    formData.append('file', selectedFile)
    axios
      .post(`bill/${selectedBill._id}/upload-file`, formData)
      .then((res) => {
        console.log(res)
        const billIdx = bills.findIndex(
          (searchedBill) => selectedBill._id === searchedBill._id,
        )
        const newBills = [...bills]
        newBills[billIdx] = res.data
        setBills(newBills)
        setSelectedBill(null)
        setSelectedFile(null)
        setIsAddFile(false)
        message.success('Modifications enregistrées !')
      })
      .catch((e) => console.log(e))
  }

  const handleDownloadPdf = (bill) => {
    axios
      .get(`bill/${bill._id}`)
      .then((res) => {
        const binaryData = Uint8Array.from(window.atob(res.data.data), (char) =>
          char.charCodeAt(0),
        )
        const pdfBlob = new Blob([binaryData], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      })
      .catch((e) => console.log(e))
    // Generate a temporary anchor element and set the PDF file as its href
  }

  const loadBills = () => {
    axios.get('bill/count').then((res) => {
      setDocumentCount(res.data)
      console.log(res.data)
    })

    axios
      .get('bill')
      .then((res) => {
        const loadedBills = res.data
        loadedBills.sort((a, b) => {
          // Compare client names
          const clientComparison = a.client.companyName.localeCompare(
            b.client.companyName,
          )

          // If client names are equal, compare candidate names
          if (clientComparison === 0) {
            return a.candidate.lastname.localeCompare(b.candidate.lastname)
          }

          return clientComparison
        })
        setBills(loadedBills)
        setOriginalBills(loadedBills)
      })
      .catch((e) => console.log(e))
  }

  return (
    <div className="mt-10">
      {isFilterFacture && (
        <FilterFacture
          originalBills={originalBills}
          setBills={setBills}
          setIsReset={setIsReset}
          open={isFilterFacture}
          onClose={() => setIsFilterFacture(false)}
        ></FilterFacture>
      )}
      <div className="flex items-center justify-between">
        <div className="flex hover:cursor-pointer my-6 items-center w-fit border rounded-lg font-semibold  border-gray-300 bg-[#F7F9FC] ">
          <p
            onClick={() => {
              setFilter('Toutes')
              filterFactures('Toutes')
            }}
            className={`${
              filter === 'Toutes' && 'bg-purple-400'
            } text-black p-2  text-center      w-fit  border-r  border-r-gray-300`}
          >
            Toutes
          </p>
          <p
            onClick={() => {
              setFilter('Vacation')
              filterFactures('Vacation')
            }}
            className={`${
              filter === 'Vacation' && 'bg-purple-400'
            } p-2  border-r    w-fit  text-center text-black border-r-gray-300`}
          >
            Vacation
          </p>
          <p
            onClick={() => {
              setFilter('Placement CDI')
              filterFactures('Placement CDI')
            }}
            className={`${
              filter === 'Placement CDI' && 'bg-purple-400'
            } p-2  border-r text-center w-fit     text-black border-r-gray-300`}
          >
            Placement CDI
          </p>
          <p
            onClick={() => {
              setFilter('Placement CDD')
              filterFactures('Placement CDD')
            }}
            className={`${
              filter === 'Placement CDD' && 'bg-purple-400'
            } p-2  border-r text-center   w-fit     text-black border-r-gray-300`}
          >
            Placement CDD
          </p>
        </div>
        <div className="text-left flex space-x-1 mb-6 h-10 items-center   pr-10">
          <Input
            onChange={(e) => setSearchEntry(e.target.value)}
            className={`w-80 transition-all duration-500 ease-in-out   rounded-md border border-gray-200 focus:outline-none`}
            placeholder="Recherche"
            style={{ outline: 'none' }}
          />

          <button
            onClick={search}
            className="bg-mountain-500 text-white p-2 rounded-md"
          >
            rechercher
          </button>
          <img
            src={xlsIcon}
            onClick={exportToExcel}
            className="w-8 h-8 mx-2 hover:cursor-pointer"
          />

          <FilterListOutlinedIcon
            onClick={() => setIsFilterFacture(true)}
            className="w-8 h-8 text-gray-500 hover:cursor-pointer"
          />
        </div>
      </div>

      {isAddFile && (
        <Modal
          width={1000}
          footer={null}
          open={isAddFile}
          onCancel={() => {
            setIsAddFile(false)
            setSelectedBill(null)
            setSelectedFile(null)
          }}
        >
          <div
            onClick={handleClick}
            className="border border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
          >
            <CloudUploadIcon />
            <p className="mt-2">Ajouter document</p>
            <p className="text-black">{selectedFile?.name}</p>
            <input
              type="file"
              onChange={(e) => handleFileChange(e)}
              className="hidden"
              ref={inputFileRef}
            />
          </div>

          <button
            onClick={uploadFile}
            className="p-2 mx-auto mt-4 rounded-md bg-mountain-500 text-white w-60"
          >
            Ajouter facture{' '}
          </button>
        </Modal>
      )}
      <div className="flex text-gray-500 uppercase mx-6 items-center justify-between">
        <p className="grow w-[11%] text-center">Recruteur</p>
        <p className="grow w-[11%] text-center">Client</p>
        <p className="grow w-[11%] text-center">Vacataire</p>
        <p className="grow w-[11%] text-center">Poste</p>
        <p className="grow w-[11%] text-center">Date</p>
        <p className="grow w-[11%] text-center">Facturation</p>
        <p className="grow w-[11%] text-center">Commentaire</p>
        <p className="grow w-[11%] text-center">Facture PLD</p>
        <p className="grow w-[11%] text-center">Statut</p>
      </div>

      <div className="mt-6 mx-6 flex flex-col space-y-2">
        {bills.map((bill) => {
          return (
            <div
              className={`flex rounded-md bg-white border p-2 text-sm border-gray-300 items-start justify-between`}
            >
              <p className="grow w-[11%] flex flex-col items-center justify-center space-y-1">
                <span className="capitalize">{bill.user.firstname}</span>{' '}
                <span className="capitalize">{bill.user.lastname}</span>
                <span
                  className={`text-sm font-semibold italic ${
                    bill.type === 'Vacation' ? 'text-green-300' : ''
                  } ${bill.type === 'Placement CDI' ? 'text-red-300' : ''}  ${
                    bill.type === 'Placement CDD' ? 'text-orange-300' : ''
                  } `}
                >
                  {bill.type}
                </span>
              </p>
              <p className="grow w-[11%] text-center">
                {bill.client.companyName}
              </p>
              <p className="grow w-[11%] text-center">
                {bill.candidate.firstname} {bill.candidate.lastname}
              </p>
              <p className="grow w-[11%] text-center">{bill.qualification}</p>
              <div className="grow w-[11%] flex flex-col text-xs items-centerZ">
                {bill.dates?.map((date) => {
                  return <p>{formatJsDate(date.dateDebut)}</p>
                })}
              </div>
              <div className="grow w-[11%] flex flex-col items-start justify-center">
                {bill.facturation.map((fact) => {
                  return (
                    <div className="flex text-sm  items-center space-x-1">
                      <p className="">{fact.quantity} *</p>
                      <p className="">{fact.description}</p>
                      <p className="">{fact.rate}&euro;</p>
                    </div>
                  )
                })}
              </div>
              <p className="grow w-[11%] text-center">{bill.comment}</p>

              <p className="grow w-[11%] flex items-center justify-center space-x-4 text-center">
                {bill.data && (
                  <span
                    onClick={() => handleDownloadPdf(bill)}
                    className="hover:cursor-pointer"
                  >
                    <FilePdfOutlined />{' '}
                  </span>
                )}
                <span
                  className="hover:cursor-pointer"
                  onClick={() => selectFileToUpload(bill)}
                >
                  <UploadOutlined />{' '}
                </span>
              </p>

              <p className="grow w-[11%] text-center">
                <Select
                  selectorBg={`${
                    bill.type === 'Vacation' ? 'bg-green-300' : ''
                  }`}
                  className={`max-h-12   py-2   rounded-md  placeholder-[#00000040]`}
                  onChange={(e) => updateStatus(bill, e)}
                  value={bill.status}
                  options={[
                    {
                      label: 'A facturer',
                      value: 'A facturer',
                      key: 'A facturer',
                    },
                    {
                      label: 'En attente',
                      value: 'En attente',
                      key: 'En attente',
                    },
                    { label: 'Facturée', value: 'Facturée', key: 'Facturée' },
                    { label: 'Annulée', value: 'Annulée', key: 'Annulée' },
                  ]}
                  placeholder="Statut"
                />
              </p>
            </div>
          )
        })}
      </div>

      {isReset && (
        <div className="flex grow justify-end mx-10">
          <Pagination
            className="mt-6"
            total={documentCount}
            current={currentPage}
            onChange={onChangePage}
            pageSize={50}
          />
        </div>
      )}
    </div>
  )
}

export default Facture
