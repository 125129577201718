export const cacesList = [
  'CACES 1 - R.489 Catégorie A (ancien R.389)',
  'CACES 2 - R.489 Catégorie B1 (ancien R.389)',
  'CACES 2 - R.489 Catégorie B2 (ancien R.389)',
  'CACES 2 - R.489 Catégorie B3 (ancien R.389)',
  'CACES 3 - R.489 Catégorie C2 (ancien R.389)',
  'CACES 4 - R.372 Catégorie C1',
  'CACES 5 - R.489 Catégorie 5 (ancien R.389)',
  'CACES 6 - R.489 Catégorie C3',
  'CACES 7 - R.489 Catégorie D',
  'CACES 8 - R.372 Catégorie E',
  'CACES 9 - R.372 Catégorie F',
  'CACES 10 - R.372 Catégorie G',
  'CACES R.482 Catégorie A',
  'CACES R.482 Catégorie B1',
  'CACES R.482 Catégorie B2',
  'CACES R.482 Catégorie C1',
  'CACES R.482 Catégorie C2',
  'CACES R.482 Catégorie C3',
  'CACES R.482 Catégorie D',
  'CACES R.482 Catégorie E',
  'CACES R.482 Catégorie F',
  'CACES R.482 Catégorie G',
  'CACES R.486 Catégorie A (ancien R.386)',
  'CACES R.486 Catégorie B (ancien R.386)',
  'CACES R.486 Catégorie C (ancien R.386)',
  'CACES R.487 Catégorie 1 (ancien R.377m)',
  'CACES R.487 Catégorie 3 (ancien R.377m)',
  'CACES R.490 Catégorie PF (poste fixe)',
  'CACES R.490 Catégorie TC (télécommande)',
  'CACES R.484 ponts roulants et portiques',
  'CACES R.485 Chariots à conducteur accompagnant',
]
