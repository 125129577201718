import { Modal, message } from 'antd'
import axios from '../../../axios/axiosInstance'
import { useEffect, useState } from 'react'
import passJeux from './passjeux.pdf'
import { PDFDocument, rgb } from 'pdf-lib'
import { saveAs } from 'file-saver'

const PassJeux = ({ candidate, isPassJeux, setIsPassJeux }) => {
  const [contracts, setContracts] = useState([])

  useEffect(() => {
    loadContract()
  }, [])

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const generatePassJeux = async (contract) => {
    // Fetch an existing PDF document
    const existingPdfBytes = await fetch(passJeux).then((res) =>
      res.arrayBuffer(),
    )

    // Load the PDF document
    const pdfDoc = await PDFDocument.load(existingPdfBytes)

    // Get the first page of the document
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]

    // Define the text to add
    const text = 'Hello, this is a sample text added to the PDF!'

    // Define the font size and color
    const fontSize = 11
    const color = rgb(0, 0, 0)

    // Add the text to the first page
    firstPage.drawText('JIHADE EL MALLOULI', {
      x: 220,
      y: 647,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('JM INTERIM', {
      x: 160,
      y: 627,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('4 AV LAURENT CELY', {
      x: 105,
      y: 607,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('92600', {
      x: 120,
      y: 587,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('ASNIERES SUR SEINE', {
      x: 85,
      y: 567,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('JM INTERIM', {
      x: 200,
      y: 525,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(contract.NomPrenom, {
      x: 285,
      y: 510,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(
      `${candidate.address} ${candidate.postCode} ${candidate.town}`,
      {
        x: 220,
        y: 495,
        size: fontSize,
        color: color,
      },
    )

    firstPage.drawText(contract.TxtQualification.toUpperCase(), {
      x: 230,
      y: 478,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(contract.TxtQualification.toUpperCase(), {
      x: 120,
      y: 464,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(contract.TxtJustificatif.toUpperCase(), {
      x: 202,
      y: 432,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(formatDate(contract.DateDebut), {
      x: 215,
      y: 417,
      size: fontSize,
      color: color,
    })

    firstPage.drawText(formatDate(contract.DateFin), {
      x: 130,
      y: 402,
      size: fontSize,
      color: color,
    })

    firstPage.drawText("INTERVENTION DANS TOUTE L'ÎLE DE FRANCE", {
      x: 280,
      y: 372,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('VEHICULE DE LA SOCIETE UTILISATRICE', {
      x: 45,
      y: 340,
      size: fontSize,
      color: color,
    })

    firstPage.drawText('ASNIERES SUR SEINE', {
      x: 110,
      y: 315,
      size: fontSize,
      color: color,
    })

    let today = formatJsDate(new Date())

    firstPage.drawText(today, {
      x: 100,
      y: 293,
      size: fontSize,
      color: color,
    })

    // Serialize the PDF document to bytes
    const pdfBytes = await pdfDoc.save()

    // Save the PDF to a file
    const blob = new Blob([pdfBytes], { type: 'application/pdf' })
    saveAs(
      blob,
      `PASS-JEUX-${contract.NomPrenom.toUpperCase()}-${
        contract.NumeroContrat
      }_${contract.NumeroAvenant}`,
    )
  }

  const loadContract = () => {
    if (candidate.pld) {
      axios
        .get(`pld-contract/candidate/${candidate.pld}`)
        .then((res) => {
          console.log(res.data)
          setContracts(res.data)
        })
        .catch((e) => console.log(e))
    } else {
      message.error("Le candidat n'a pas de pld")
    }
  }

  const formatDate = (date) => {
    const year = date.slice(0, 4);
    const month = date.slice(4, 6);
    const day = date.slice(6, 8);

    // Format the date as DD/MM/YYYY
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  return (
    <Modal
      open={isPassJeux}
      footer={null}
      onCancel={() => setIsPassJeux(false)}
    >
      <div className="mt-10 p-4 flex flex-col space-y-2">
        {contracts.map((contract) => {
          return (
            <div className="flex flex-col p-4 font-semibold space-y-1 border border-gray-300">
              <p>Numero Contrat : {contract.NumeroContrat}</p>
              <p>Numero Avenant : {contract.NumeroAvenant}</p>
              <p>Date debut :{formatDate(contract.DateDebut)}</p>
              <p>Date de fin :{formatDate(contract.DateFin)}</p>
              <p
                onClick={() => generatePassJeux(contract)}
                className="bg-purple-400 hover:cursor-pointer rounded-md p-2 text-white"
              >
                Generer le pass Jeux
              </p>
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default PassJeux
