import {
  Button,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Select,
  TreeSelect,
  message,
} from 'antd'
import { useRef, useState, useEffect } from 'react'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import { AddCircleOutlineOutlined } from '@mui/icons-material'
import qualificationsData from '../../qualifications/qualifications'
import { useNavigate } from 'react-router-dom'
import AxiosInstance from '../../axios/axiosInstance'

const AvailableCandidat = () => {
  const navigate = useNavigate()

  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [postCode, setPostCode] = useState('')
  const [town, setTown] = useState('')
  const [qualifications, setQualifications] = useState([])
  const [hourlyRate, setHourlyRate] = useState('')
  const [comment, setComment] = useState('')
  const [isVehicule, setIsVehicule] = useState('NON')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [availableCandidats, setAvailableCandidats] = useState([])
  const [originalCandidats, setOriginalCandidats] = useState([])
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [documentCount, setDocumentCount] = useState(0)
  const [selectedAvailableCandidat, setSelectedAvailableCandidat] =
    useState(null)

  useEffect(() => {
    loadCandidates()
  }, [])

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }

  const qualificationData = qualificationsData.map((qualification) => {
    return {
      title: qualification,
      value: qualification,
      key: qualification,
    }
  })

  const search = (word) => {
    if (word.length === 0) {
      setAvailableCandidats(originalCandidats)
      setCurrentPage(1)
    }
    if (word.length >= 3) {
      AxiosInstance.post('available-candidat/find-by-text', {
        query: word,
      }).then((res) => setAvailableCandidats(res.data))
    }
  }

  const onChangePage = (page, pageSize) => {
    console.log(page)

    AxiosInstance.get(`available-candidat/paginate/${page - 1}`)
      .then((res) => {
        setAvailableCandidats(res.data)
        setCurrentPage(page)
        console.log(res)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadCandidates = () => {
    AxiosInstance.get('available-candidat/count').then((res) => {
      setDocumentCount(res.data)
    })

    AxiosInstance.get('available-candidat')
      .then((res) => {
        setAvailableCandidats(res.data)
        setOriginalCandidats(res.data)
      })
      .catch((e) => message.error("Une erreur s'est produite !"))
  }

  const onChangeQualification = (newValue) => {
    setQualifications(newValue)
  }

  const qualifProps = {
    treeData: qualificationData,
    onChange: onChangeQualification,
    value: qualifications,
    treeCheckable: true,
    placeholder: 'Qualification',
  }

  const resetFields = () => {
    setComment('')
    setFirstname('')
    setLastname('')
    setPhone('')
    setEmail('')
    setHourlyRate('')
    setIsVehicule('NON')
    setQualifications([])
    setPostCode('')
    setTown('')
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const selectAvailableCandidat = (candidate) => {
    setComment(candidate.comment)
    setEmail(candidate.email)
    setFirstname(candidate.firstname)
    setLastname(candidate.lastname)
    setHourlyRate(candidate.hourlyRate)
    setQualifications(candidate.qualifications)
    setPhone(candidate.phone)
    setPostCode(candidate.postCode)
    setTown(candidate.town)
    setIsVehicule(candidate.isVehicule)

    setIsOpenModal(true)
    setSelectedAvailableCandidat(candidate)
  }

  const createCandidate = () => {
    setSaveDisabled(true)

    const candidateData = {
      firstname,
      lastname,
      phone,
      email,
      postCode,
      town,
      qualifications,
      hourlyRate,
      comment,
      isVehicule,
    }

    if (!selectedAvailableCandidat) {
      AxiosInstance.post(`available-candidat`, candidateData)
        .then((res) => {
          const candidats = [res.data, ...availableCandidats]
          setAvailableCandidats(candidats)
          message.success('Candidats crée')
          setIsOpenModal(false)
          resetFields()
          setSaveDisabled(false)
        })
        .catch((e) => {
          console.log(e)
          setSaveDisabled(false)
          if (e.response.data && e.response.data.statusCode === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    } else {
      AxiosInstance.put(
        `available-candidat/${selectedAvailableCandidat._id}`,
        candidateData,
      )
        .then((res) => {
          const candidates = availableCandidats.filter(
            (candidat) => candidat._id !== selectedAvailableCandidat._id,
          )
          candidates.push(res.data)
          candidates.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
          )
          setAvailableCandidats(candidates)
          message.success('Candidat modifié ! ')
          setIsOpenModal(false)
          resetFields()
          setSaveDisabled(false)
          setSelectedAvailableCandidat(null)
        })
        .catch((e) => {
          console.log(e)
          message.error("Une erreur s'est produite ! ")
          setSaveDisabled(false)
          if (e.response.data && e.response.data.statusCode === 403) {
            localStorage.clear()
            navigate('/login')
          }
        })
    }
  }

  return (
    <div>
      {isOpenModal && (
        <Modal
          footer={null}
          onCancel={() => {
            setIsOpenModal(false)
            setSelectedAvailableCandidat(null)
            resetFields()
          }}
          open={isOpenModal}
        >
          <div className="flex flex-col  mt-10 space-y-1">
            <Input
              onChange={(e) => setLastname(e.target.value)}
              value={lastname}
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              placeholder="Nom"
            />
            <Input
              className=" max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setFirstname(e.target.value)}
              value={firstname}
              placeholder="Prenom"
            />
            <Input
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              placeholder="Email"
            />
            <Input
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setPhone(e.target.value)}
              value={phone}
              placeholder="Téléphone"
            />

            <TreeSelect
              className="py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              value={qualifications}
              size="large"
              {...qualifProps}
            />

            <Input
              className=" max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setHourlyRate(e.target.value)}
              value={hourlyRate}
              placeholder="Taux horaire"
            />
            <Input
              className=" max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setPostCode(e.target.value)}
              value={postCode}
              placeholder="Code postal"
            />

            <Input
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setTown(e.target.value)}
              value={town}
              placeholder="Ville"
            />

            <Select
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => {
                setIsVehicule(e)
              }}
              value={isVehicule}
              options={[
                { label: 'OUI', value: 'OUI', key: 'OUI' },
                { label: 'NON', value: 'NON', key: 'NON' },
              ]}
              placeholder="Vehiculé(e)"
            />
            {/* <select onChange={(e)=>console.log(e)} className=" border rounded-md text-[#00000040] border-[#00000040]">
             <option value="" disabled selected>Vehiculé</option>
             <option value="OUI">Oui</option>
             <option value="NON">Non</option>
     </select>
 */}
            <Input
              className="  max-h-12   py-2  focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              placeholder="Commentaires"
            />
            <button
              disabled={saveDisabled}
              className="rounded-md bg-mountain-500 hover:bg-mountain-600 p-2 text-base uppercase font-semibold  text-white   "
              onClick={createCandidate}
            >
              Enregistrer
            </button>
          </div>
        </Modal>
      )}

      <div className="flex justify-end ml-20 mb-10 mt-10">
        <div className="flex items-center space-x-2 h-10">
          {showSearch && (
            <Input
              ref={searchInputRef}
              className={`w-80 transition-all ${
                showSearch ? 'opacity-100' : 'opacity-0'
              } duration-500 ease-in-out   rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
              onChange={(e) => search(e.target.value)}
            />
          )}
          <SearchIcon
            onClick={() => showSearchInput(showSearch)}
            className="text-gray-500 text-3xl"
          />
          <DoneAllIcon className="text-gray-500 text-3xl" />
          <AddCircleOutlineOutlined
            onClick={() => setIsOpenModal(true)}
            className="text-gray-500 text-3xl"
          />
        </div>
      </div>

      <div className="flex items-center justify-between text-xs mx-10 text-center">
        <p className="flex-1">DATE</p>
        <p className="flex-1">NOM</p>
        <p className="flex-1">TELEPHONE</p>
        <p className="flex-1">QUALIFICATION</p>
        <p className="flex-1">TAUX HORAIRE</p>
        <p className="flex-1">CODE POSTAL</p>
        <p className="flex-1">VILLE</p>
        <p className="flex-1">VEHICULE</p>
        <p className="flex-1">COMMENTAIRE</p>
      </div>
      {availableCandidats.map((candidate, idx) => {
        return (
          <div
            onClick={() => {
              selectAvailableCandidat(candidate)
            }}
            key={idx}
            className="flex bg-white border px-2 py-4 rounded-md shadow-lg border-gray-300 items-center justify-between  text-xs mx-10 text-center mt-10"
          >
            <p className="flex-1">{formatJsDate(candidate.createdAt)}</p>
            <p className="flex-1">
              {candidate.firstname} {candidate.lastname}
            </p>
            <p className="flex-1">{candidate.phone}</p>
            <p className="flex-1">{candidate.qualifications[0]}</p>
            <p className="flex-1">{candidate.hourlyRate}</p>
            <p className="flex-1">{candidate.postCode}</p>
            <p className="flex-1">{candidate.town}</p>
            <p className="flex-1">{candidate.isVehicule}</p>
            <p className="flex-1">{candidate.comment}</p>
          </div>
        )
      })}

      <div className="text-right">
        <Pagination
          className="mt-6 w-full"
          total={documentCount}
          current={currentPage}
          onChange={onChangePage}
          pageSize={20}
        />
      </div>
    </div>
  )
}

export default AvailableCandidat
