import {
  Document,
  Page,
  View,
  Text,
  PDFViewer,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'
import { useState } from 'react'
import logo from '../../assets/img/logo.png'

const ContractClientPdf = () => {
  const [show, setShow] = useState(true)
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  })

  return (
    <div>
      {show && (
        <div className="w-full flex flex-col h-screen absolute top-0 right-0 z-30">
          <p
            onClick={() => setShow(false)}
            className="text-center py-6 bg-gray-900 text-white"
          >
            X
          </p>
          <PDFViewer className="w-full grow">
            <Document>
              <Page size="A4">
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingTop: 10,
                  }}
                >
                  <Image style={{ width: 80, height: 80 }} src={logo} />
                  <View
                    style={{
                      display: 'flex',
                      width: '50%',
                      fontSize: 8,
                      marginLeft: 6,
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: '14',
                        fontWeight: 'bold',
                        paddingVertical: 12,
                      }}
                    >
                      J'm intérim SAS
                    </Text>
                    <Text>Capital de 80 000€</Text>
                    <Text>
                      4 avenue Laurent Cély - 92600 Asnières sur Seine
                    </Text>
                    <Text>
                      T : 01.80.88.21.35 - @ : recrutement@jm-interim.com
                    </Text>
                    <Text>SIRET : 919 125 518 00010 APE : 7820z</Text>
                    <Text>URSSAF : 117000001571042223 Montreuil (93)</Text>
                    <Text>Convention collective (IDCC 2378 )</Text>
                    <Text>
                      Personnel intérimaire des entreprises de travail
                      temporaire
                    </Text>
                    <Text>
                      Garant financier (art. L.1251-49 du CT) : Atradius
                    </Text>
                    <Text>159 Rue Anatole France, 92300 Levallois-Perret</Text>
                    <Text>
                      Intérimaires Prévoyance, TSA 60008, 92 599
                      Levallois-Perret Cedex
                    </Text>
                  </View>
                  <View>
                    <Text style={{ fontStyle: 'bold', fontSize: 12 }}>
                      Contrat
                    </Text>
                    <Text style={{ fontSize: 12 }}>Initial</Text>
                    <Text
                      style={{
                        color: 'pink',
                        fontSize: 12,
                        textDecoration: 'underline',
                        marginTop: 30,
                      }}
                    >
                      Contrat de mission temporaire
                    </Text>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    paddingHorizontal: 10,
                    alignItems: 'stretch',
                    marginTop: 40,
                    justifyContent: 'space-between',
                  }}
                >
                  <View
                    style={{
                      width: '8%',
                      borderRadius: 6,
                      border: '1px black solid',
                      fontSize: 12,
                      height: 100,
                      display: 'flew',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        textDecoration: 'underline',
                        fontSize: 9,
                        transform: 'rotate(-90deg)',
                        textAlign: 'center',
                      }}
                    >
                      Client
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 6,
                      border: '1px black solid',
                      height: 100,
                      fontSize: 12,
                      borderRadius: 6,
                      width: '36%',
                    }}
                  >
                    <Text style={{ fontWeight: 'heavy' }}>LES GATINES</Text>
                    <Text>167 ROUTES DES GATINES</Text>
                    <Text>91370 VERRIÈRES-LE-BUISSON</Text>
                  </View>
                  <View
                    style={{
                      width: '8%',
                      borderRadius: 6,
                      border: '1px black solid',
                      height: 100,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 9,
                        textDecoration: 'underline',
                        transform: 'rotate(-90deg)',
                        textAlign: 'center',
                      }}
                    >
                      Interimaire
                    </Text>
                  </View>
                  <View
                    style={{
                      padding: 6,
                      border: '1px black solid',
                      height: 100,
                      fontSize: 12,
                      borderRadius: 6,
                      width: '40%',
                    }}
                  >
                    <Text style={{ fontStyle: 'bold' }}>LES GATINES</Text>
                    <Text>167 ROUTES DES GATINES</Text>
                    <Text>91370 VERRIÈRES-LE-BUISSON</Text>
                  </View>
                </View>

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingHorizontal: '2%',
                    marginTop: 10,
                  }}
                >
                  <View
                    style={{
                      border: '1px black solid',
                      width: '48%',
                      height: 100,
                    }}
                  ></View>
                  <View
                    style={{
                      border: '1px black solid',
                      width: '48%',
                      height: 100,
                    }}
                  ></View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </div>
      )}
    </div>
  )
}

export default ContractClientPdf
