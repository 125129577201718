import { Modal, message, Input, Button, Popover } from 'antd'
import { useEffect, useState } from 'react'
import axios from '../../../axios/axiosInstance'

const Entretien = ({ candidate }) => {
  const [entretiens, setEntretiens] = useState([])
  const [candidatures, setCandidatures] = useState([])
  const [selectedEntretien, setSelectedEntretien] = useState(null)
  const [reason, setReason] = useState('')
  const [refusedEntretien, setRefusedEntretien] = useState(false)
  const [isRdv, setIsRdv] = useState(true)
  const [isCandidature, setIsCandidature] = useState(true)

  useEffect(() => {
    getEntretien()
  }, [])

  const getEntretien = () => {
    axios
      .get(`entretien/${candidate._id}`)
      .then((res) => setEntretiens(res.data))
      .catch((e) => {
        console.log(e)
      })
  }

  const getCandidature = () => {
    axios
      .get(`candidature/${candidate._id}`)
      .then((res) => setCandidatures(res.data))
      .catch((e) => {
        console.log(e)
      })
  }

  const refuseEntretien = () => {
    if (!reason.length) {
      message.error('Veuillez indiquer la raison du refus ! ')
      return
    }

    axios
      .put(`/entretien/${selectedEntretien._id}/update`, {
        status: 'Refusée',
        cancelReason: reason,
      })
      .then((res) => {
        message.success('Modifications enregistrées ! ')
        console.log(res.data)
        const newEntretiens = [...entretiens]
        const entretienIdx = entretiens.findIndex((e) => e._id === res.data._id)
        newEntretiens[entretienIdx] = res.data
        setEntretiens(newEntretiens)
        setRefusedEntretien(false)
        setReason('')
        setSelectedEntretien(null)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite ! ")
      })
  }

  const acceptEntretien = () => {
    axios
      .put(`/entretien/${selectedEntretien._id}/update`, { status: 'Acceptée' })
      .then((res) => {
        message.success('Modifications enregistrées ! ')
        console.log(res.data)
        const newEntretiens = [...entretiens]
        const entretienIdx = entretiens.findIndex((e) => e._id === res.data._id)
        newEntretiens[entretienIdx] = res.data
        setEntretiens(newEntretiens)
        setSelectedEntretien(null)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite ! ")
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  return (
    <div className="mt-6 mx-10">
      <p className="my-2 font-semibold text-2xl">Entretiens</p>
      <div className="my-6 flex items-center justify-center">
        <p className="w-1/2 text-center font-semibold">RDV</p>
        <p className="w-1/2 text-center font-semibold">CANDIDATURE</p>
      </div>
      {isRdv && (
        <div className="bg-gray-50 h-96">
          {selectedEntretien && (
            <Modal
              open={selectedEntretien}
              footer={null}
              onCancel={() => setSelectedEntretien(null)}
            >
              <div className="h-1/2 flex space-y-4 flex-col items-center justify-center">
                <p>{formatJsDate(selectedEntretien.date)}</p>
                <p>{selectedEntretien.hour}</p>
                <p>{selectedEntretien.client?.companyName}</p>
                <p>
                  {selectedEntretien.contact.contactFirstname}{' '}
                  {selectedEntretien.contact.contactLastname}
                </p>
                <p>{selectedEntretien.poste}</p>
                <p>{selectedEntretien.lieu}</p>

                <div className="flex items-center space-x-2">
                  <button
                    onClick={acceptEntretien}
                    className="w-48 rounded-md p-2 font-semibold bg-mountain-500 text-white"
                  >
                    Accepté
                  </button>
                  <button
                    onClick={() => setRefusedEntretien(true)}
                    className="w-48 rounded-md p-2 bg-red-500 text-white"
                  >
                    Refusé
                  </button>
                </div>

                {refusedEntretien && (
                  <div className="flex items-center justify-center flex-col space-y-2">
                    <Input
                      value={reason}
                      onChange={(e) => setReason(e.target.value)}
                      className="rounded-md border border-gray-300 w-96"
                      placeholder="Raison du refus"
                    />
                    <button
                      onClick={refuseEntretien}
                      className="rounded-md p-2 w-96 bg-red-500 text-white"
                    >
                      Enregistrer
                    </button>
                  </div>
                )}
              </div>
            </Modal>
          )}
          <div className=" flex flex-col h-96 w-full space-y-1 items-center justify-center">
            {!entretiens.length && <p>Aucun entretien</p>}
            {entretiens.length > 0 && (
              <div className="mx-6 w-full">
                <div className="flex items-center space-x-1 font-semibold uppercase text-sm mb-6">
                  <p className="w-1/6 text-center">date</p>
                  <p className="w-1/6 text-center">heure</p>
                  <p className="w-1/6 text-center">client</p>
                  <p className="w-1/6 text-center">contact</p>
                  <p className="w-1/6 text-center">poste</p>
                  <p className="w-1/6 text-center">lieu</p>
                  <p className="w-1/6 text-center">statut</p>
                </div>
                {entretiens.map((entretien) => {
                  return (
                    <div
                      onClick={() => setSelectedEntretien(entretien)}
                      className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
                    >
                      <p className="w-1/6 text-center">
                        {formatJsDate(entretien.date)}
                      </p>
                      <p className="w-1/6 text-center">{entretien.hour}</p>
                      <p className="w-1/6 text-center">
                        {entretien.client.companyName}
                      </p>
                      <p className="w-1/6 text-center">
                        {entretien.contact.firstname}{' '}
                        {entretien.contact.lastname}
                      </p>
                      <p className="w-1/6 text-center">{entretien.poste}</p>
                      <p className="w-1/6 text-center">{entretien.place}</p>
                      <p className="w-1/6 text-center">{entretien.status}</p>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}

      {isRdv && (
        <div className="bg-gray-50 h-96">
          <div className=" flex flex-col h-96 w-full space-y-1 items-center justify-center">
            {!candidatures.length && <p>Aucune candidature</p>}
            {candidatures.length > 0 && (
              <div className="mx-6 w-full">
                <div className="flex items-center space-x-1 font-semibold uppercase text-sm mb-6">
                  <p className="w-1/6 text-center">Date</p>
                  <p className="w-1/6 text-center">Client</p>
                  <p className="w-1/6 text-center">Poste</p>
                  <p className="w-1/6 text-center">Salaire</p>
                  <p className="w-1/6 text-center">Commentaire</p>
                  <p className="w-1/6 text-center">Action</p>
                </div>
                {candidatures.map((candidature) => {
                  return (
                    <div className=" group  flex  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300">
                      <p className="w-1/6 text-center">
                        {formatJsDate(candidature.date)}
                      </p>
                      <p className="w-1/6 text-center">
                        {candidature.client.companyName}
                      </p>
                      <p className="w-1/6 text-center">{candidature.poste}</p>
                      <p className="w-1/6 text-center">{candidature.salaire}</p>
                      <p className="w-1/6 text-center">
                        {candidature.commentaire}
                      </p>
                      <div className="w-1/6 text-center">
                        <Popover
                          placement="bottom"
                          content={
                            <div className="flex flex-col capitalize text-base space-y-3">
                              <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                                <span>Valider</span>
                              </p>

                              <p className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer">
                                <span>Refuser</span>
                              </p>
                            </div>
                          }
                          trigger="click"
                        >
                          <Button>...</Button>
                        </Popover>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Entretien
