import { Input, Select } from 'antd'
import { useEffect, useState } from 'react'
import axios from '../../../axios/axiosInstance';
import { Puff } from 'react-loader-spinner';

const CreateContractMotif = ({ contract, setContract }) => {


  const [motif,setMotif] = useState([]);
  const [loading,setLoading] = useState(true);


  useEffect(()=> {
    loadRecours();
  },[]);

  const loadRecours = () => {
    axios.get("pld-contract/recours")
    .then((res)=> {
      console.log(res.data)
      setMotif(res.data.map((moti)=> moti.IdReferentiel+" : "+moti.Designation))
    })
    .catch(e=> console.log(e))
    .finally(()=> setLoading(false));
  }

  const selectMotif = (e) => {
    setContract({ ...contract, motif: e, otherMotif: null })
  }
/* 
  const selectOtherMotif = (e) => {
    setContract({ ...contract, motif: null, otherMotif: e })
  } */

/*   const usedMotif = [
    "42 : Accroissement temporaire d'activité lié à une variation cyclique d'activité",
    "52 : Accroissement temporaire d'activité lié à une tâche occasionnelle et non durable",
    "11 : Remplacement d'un salarié en cas d'absence ou de suspension de son contrat de travail",
    '83 : Emploi temporaire par nature défini comme emploi à caractère saisonnier',
    "93 : Emploi temporaire par nature défini comme emploi d'usage constant",
  ] */

 /*  const otherMotif = [
    '10 : Formation',
    "72 : Accroissement temporaire d'activité lié à des travaux dont  l'exécution immédiate est nécessitée par des mesures de sécurité",
    "12 : Remplacement d'un salarié en cas de passage provisoire à temps partiel, conclu par avenant à son contrat de travail ou par échange écrit entre le salarié et l'employeur",
    "62 : Accroissement temporaire d'activité lié à une commande exceptionnelle à l'exportation",
    "21 : Conformément aux dispositions légales faciliter l'embauche des personnes sans emploi ayant des difficultés sociales professionnelles particulières",
    "14 : Remplacement d'un salarié en cas de l'entrée effective du salarié recruté par un contrat à durée indéterminée appelé à le remplacer",
    "23 : Remplacement d'un associé non salarié d'une société civile professionnelle d'une société civile de moyens ou d'une société d'exercice libéral",
    "25 : Remplacement d'un chef d'exploitation agricole ou d'une entreprise mentionnée aux 1° à 4° de l'art. L.722-1 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
    "24 : Remplacement d'un chef d'entreprise artisanale industriel, commerciale, d'une profession libérale, de son conjoint participant réellement à l'activité à titre professionnel et habituel",
    "31 : Remplacement d'un salarié en cas de départ définitif  précédant la suppression de son poste de travail",
    "22 : L'ETT et l'E.U. s'engagent, pour une durée, dans les  conditions légales à assurer un complément de formation professionnelle au salarié",
    "HANDICP : Bénéficiaire de l'obligation d'emploi de l'article L. 5212-13 CT",
    "26 : Remplacement d'un aide familial, associé d'exploit. ou leur conjoint mentionné  à l'art. L.722-10 du code rural, participant réellement à l'activité de l'entreprise, de l'exploitation agricole",
  ] */

  const usedMotifArray = motif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  })
 /*  const otherMotifArray = otherMotif.map((motif, idx) => {
    return { value: motif, label: motif, key: idx }
  }) */

  return (
    <div className="flex flex-col h-full justify-between">
     {loading &&<div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>}
      <div>
        <p className="font-semibold text-center text-3xl">Motif de recours</p>

        <div>
          <p>Selectionnez le motif</p>
          <Select
            className="w-full py-3 my-2"
            showSearch
            onChange={(e) => selectMotif(e)}
            value={contract.motif ? `${contract.motif}` : null}
            optionFilterProp="children"
            placeholder="Motif de recours"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={usedMotifArray}
          />
        </div>

        {/* <div>
          <p>Les autres motifs</p>
          <Select
            className="w-full py-3 my-2"
            showSearch
            onChange={(e) => selectOtherMotif(e)}
            value={contract.otherMotif ? `${contract.otherMotif}` : null}
            optionFilterProp="children"
            placeholder="Motif de recours"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={otherMotifArray}
          />
        </div> */}
        <div>
          <p>Developper le motif</p>
          <Input
            onChange={(e) => {
              setContract({ ...contract, justificatif: e.target.value })
            }}
            value={contract.justificatif ? `${contract.justificatif}` : null}
            className="rounded-md bcontract bcontract-gray-300 mb-2 mt-6"
            placeholder="Justificatif"
          />
        </div>
      </div>
    </div>
  )
}

export default CreateContractMotif
