import { useEffect, useState } from 'react'
import AxiosInstance from '../../axios/axiosInstance'
import GoogleCalendar from './GoogleCalendar'
import { CalendarOutlined, DollarOutlined } from '@ant-design/icons'
import bonjour from '../../assets/img/bonjour.png'
import order from '../../assets/img/order.gif'
import task from '../../assets/img/task.png'
import { DatePicker, Modal, Select, Spin, message } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import { values } from 'lodash'

const Dashboard = ({ username, toggle }) => {
  const [billCount, setBillCount] = useState(0)
  const [acompteCount, setAcompteCount] = useState(0)
  const [orders, setOrders] = useState([])
  const [tasks, setTasks] = useState([])
  const [tempworkerCount, setTempworkerCount] = useState(0)
  const [users, setUsers] = useState([])
  const [loadingTempworkerCount, setLoadingTempworkerCount] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [selectedUserId, setSelectedUserId] = useState('')
  const [isTempworkerCount, setisTempworkerCount] = useState(false)

  useEffect(() => {
    loadBills()
    loadOrders()
    loadTasks()
    getUsers()
    loadAcompte()
  }, [])

  const getUsers = () => {
    AxiosInstance.get('user')
      .then((res) => {
        setUsers(res.data)
      })
      .catch((e) => console.log(e))
  }

  const formateDate = (date) => {
    const year = date.substring(0, 4)
    const month = date.substring(4, 6)
    const day = date.substring(6, 8)
    return `${day}/${month}/${year}`
  }

  const loadTasks = () => {
    const date = new Date()
    AxiosInstance.get(`/task/find-by-date?date=${date}`)
      .then((res) => {
        const tasks = res.data.filter(
          (task) => task.status != 'annulée' && task.status != 'Effectuée',
        )
        setTasks(tasks)
      })
      .catch((e) => console.log(e))
  }

  const loadOrders = () => {
    AxiosInstance.get('order')
      .then((res) => {
        setOrders(
          res.data.filter(
            (c) => c.status !== 'annulée' && c.status !== 'Annulée',
          ),
        )
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  const getTempworkerNumber = () => {
    if (!startDate || !endDate || !selectedUserId.length) {
      message.error('Selectionnez un user et les dates')
      return
    }
    setLoadingTempworkerCount(true)
    AxiosInstance.post('tempworker-candidate/findbycreator', {
      creator: selectedUserId,
      startDate,
      endDate,
    })
      .then((res) => {
        setTempworkerCount(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
      .finally(() => setLoadingTempworkerCount(false))
  }

  const loadBills = () => {
    AxiosInstance.get('facture/unpaid-count')
      .then((res) => {
        setBillCount(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  const loadAcompte = () => {
    AxiosInstance.get('acompte/tovalidate-count')
      .then((res) => {
        setAcompteCount(res.data)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
        }
      })
  }

  return (
    <div className="px-6">
      <p className="text-2xl mb-6 flex items-center space-x-2">
        <span>Bonjour {username}, </span> <img src={bonjour} className="w-16" />
      </p>

      <div  className="grid grid-cols-3 gap-2    items-center justify-center ">
          
          <div onClick={() => toggle('order')} className="w-full flex flex-col   items-center h-60 rounded-md border border-gray-300 shadow-xl bg-gray-50 ">
          <p className="py-4 text-2xl flex space-x-1 items-center justify-center font-semibold rounded-t-md bg-orange-500 w-full  text-white">
            {/* <img className='w-10' src={order} /> */}
            <span>Commande à traiter</span>
          </p>
          <p>
            Contrats à faire :{' '}
            {orders.filter((o) => o.status === 'Contrat à faire').length}
          </p>
          <p>
            Aucun candidat:{' '}
            {orders.filter((o) => o.status === 'Aucun candidat').length}
          </p>
          <p>
            Candidat à contacter:{' '}
            {orders.filter((o) => o.status === 'Candidat à contacter').length}
          </p>
          <p>
            Candidat(s) contacté(s):{' '}
            {
              orders.filter((o) => o.status === 'Candidat(s) contacté(s)')
                .length
            }
          </p>
          <p>
            Candidat accepté(s):{' '}
            {orders.filter((o) => o.status === 'Candidat(s) accepté(s)').length}
          </p>
        </div>

        <div className=" grow flex flex-col h-60 ">
          {/*  <div className="rounded-2xl relative pl-2 w-full justify-center flex grow bg-gray-50 border shadow-xl border-gray-300">
            <p className="my-4 text-2xl  font-semibold text-black">
              Messagerie
            </p>
          </div> */}

          
          <div onClick={() => toggle('taches')}  className="rounded-md  relative   grow  bg-gray-50 border shadow-xl border-gray-300">
            <p className="py-4 text-2xl flex items-center justify-center space-x-2 font-semibold rounded-t-md bg-green-500 w-full  text-white">
              <img className="w-8" src={task} />
              <span>Tâches</span>
            </p>
            <div className="flex flex-col items-center">
              <p>Appel : {tasks.filter((o) => o.type === 'Appel').length}</p>
              <p>RDV: {tasks.filter((o) => o.type === 'RDV').length}</p>
              <p>
                Deplacement:{' '}
                {tasks.filter((o) => o.type === 'Deplacement').length}
              </p>
              <p>Autre: {tasks.filter((o) => o.type === 'Autre').length}</p>
            </div>
          </div>
        </div>

        <div  onClick={() => toggle('facture')} className="grow my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-red-500 w-full  text-white">
            Facture impayée
          </p>
          <div className="h-40 flex items-center justify-center">
            <p className="text-3xl font-semibold ">{billCount}</p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2">
        <div
          onClick={() => toggle('acompte')}
          className="my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl"
        >
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-purple-500 w-full  text-white">
            Acompte de la semaine
          </p>
          <div className="h-40 flex items-center justify-center">
            <p className="text-3xl font-semibold ">{acompteCount}</p>
          </div>
        </div>

        <div className="my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-blue-500 w-full  text-white">
            Prolongation à traiter
          </p>
        </div>

        <div className="my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-gray-500 w-full  text-white">
            Suivi client
          </p>
        </div>
      </div>

      <div className="flex items-start space-x-2">
        <div className="w-1/3 my-2 bg-white h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-pink-500 w-full  text-white">
            Relevé d'heure en attente
          </p>
        </div>

        <div className="w-1/3 my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-black w-full  text-white">
            Note interne
          </p>
        </div>

        <div className="w-1/3 my-2 bg-white  h-60 rounded-md border border-gray-300 shadow-xl">
          <p className="py-4 text-2xl text-center font-semibold rounded-t-md bg-black w-full  text-white">
            Nombre d'interimaires
          </p>

          {/*   */}

          {isTempworkerCount && (
            <Modal
              open={isTempworkerCount}
              onCancel={() => setisTempworkerCount(false)}
              footer={null}
            >
              <div className="flex my-20 flex-col items-center justify-center space-y-4">
                <Select
                  className="w-96  outline-none"
                  showSearch
                  onChange={(e) => setSelectedUserId(e)}
                  optionFilterProp="children"
                  placeholder="Selectionner l'utilisateur "
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={users.map((user) => {
                    return {
                      value: `${user._id}`,
                      label: `${user.firstname} ${user.lastname}`,
                      key: `${user._id}`,
                    }
                  })}
                />

                <DatePicker
                  locale={locale}
                  format={'DD/MM/YYYY'}
                  onChange={(date, dateString) => setStartDate(date.$d)}
                  className="w-96 mt-6  outline-none"
                  placeholder="date de debut"
                />
                <DatePicker
                  locale={locale}
                  format={'DD/MM/YYYY'}
                  onChange={(date, dateString) => setEndDate(date.$d)}
                  className="w-96 mt-6  outline-none"
                  placeholder="date de fin"
                />

                <button
                  onClick={getTempworkerNumber}
                  className="bg-mountain-500 text-white rounded-md p-2 w-96 mx-auto mt-2"
                >
                  Valider
                </button>
              </div>

              {!loadingTempworkerCount && (
                <p className="flex items-center justify-center text-4xl mt-2">
                  {tempworkerCount}
                </p>
              )}
              {loadingTempworkerCount && (
                <p className="flex items-center justify-center my-6">
                  <Spin />
                </p>
              )}
            </Modal>
          )}
          <div className="flex h-40 items-center justify-center">
            <button
              onClick={() => setisTempworkerCount(true)}
              className="bg-mountain-500 text-white rounded-md p-2 w-40 mx-auto mt-2"
            >
              Recherche
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
