import React, { useState } from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AuthContext } from './context/authContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ConfigProvider } from 'antd'
function Index() {
  const loginStatus = localStorage.getItem('isLogged')

  const [isLoggedIn, setIsLoggedIn] = useState(loginStatus)

  return (
    <React.StrictMode>
      <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ConfigProvider
            theme={{
              components: {
                Menu: {
                  itemActiveBg: '#EBE9FE',
                  itemBg: '#EBE9FE',
                  horizontalItemHoverColor: '#372893',
                },
              },
            }}
          >
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ConfigProvider>
        </LocalizationProvider>
      </AuthContext.Provider>
    </React.StrictMode>
  )
}

ReactDOM.createRoot(document.getElementById('root')).render(<Index />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
