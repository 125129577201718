import cgvClient from '../../../assets/img/cgv-client.jpg'
import myImage from '../../../assets/img/logopdf.png'
import logotoulouse from '../../../assets/img/logotoulouse.png'
import logobrive from '../../../assets/img/logobrive.png'
import contrat1 from '../../../assets/img/contrat1.png'
import contrat2 from '../../../assets/img/contrat2.png'
import gmail from '../../../assets/img/gmail.png'
import financialimage from '../../../assets/img/financial.png'
import eventimage from '../../../assets/img/event.png'
import contactimage from '../../../assets/img/contact.png'
import infoimage from '../../../assets/img/info.png'
import fileimage from '../../../assets/img/file.png'
import commentimage from '../../../assets/img/comment.png'
import information from '../../../assets/img/information.png'
import money from '../../../assets/img/money.png'
import devis from '../../../assets/img/devis.png'
import ClientComment from '../ClientComment'
import ClientDocuments from '../ClientDocument'
import jsPDF from 'jspdf'
import DownloadingIcon from '@mui/icons-material/Downloading'
import SearchIcon from '@mui/icons-material/Search'
import CreateQuotationModal from '../CreateQuotationModal'
import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import PersonIcon from '@mui/icons-material/Person'
import MultipleStopIcon from '@mui/icons-material/MultipleStop'
import atradius from '../../../assets/img/atradius.png'
import phone from '../../../assets/img/phone.png'
import axios from '../../../axios/axiosInstance'
import locale from 'antd/es/date-picker/locale/fr_FR'
import CreateFacture from '../../CreateFacture/CreateFacture'
import { useEffect, useState } from 'react'
import { DatePicker, Input, Modal, Select, TreeSelect, message } from 'antd'
import CreateContract from '../CreateContract/CreateContract'
import CreateContract2 from '../CreateContract/CreateContract2'
import ClientModalContract from '../ClientModalContract/ClientModalContract'
import DuplicateContract from '../DuplicateContract/DuplicateContract'
const ClientDetailModal = ({
  client,
  setClient,
  clients,
  setClients,
  originalClients,
}) => {
  const [section, setSection] = useState('info')
  const [acceptedAtradius, setAcceptedAtradius] = useState(false)
  const [updateContactObject, setUpdateContactObject] = useState({})
  const [pld, setPld] = useState('')
  const [name, setName] = useState(client.companyName)

  const [contacts, setContacts] = useState([])
  const [isEditPld, setIsEditPld] = useState(false)
  const [isEditName, setIsEditName] = useState(false)
  const [quotations, setQuotations] = useState([])
  const [originalQuotations, setOriginalQuotations] = useState([])
  const [isOpenCreateContact, setIsOpenCreateContact] = useState(false)
  const [isOpenCreateTask, setIsOpenCreateTask] = useState(false)
  const [canCreateContact, setCanCreateContrat] = useState(true)
  const [tasks, setTasks] = useState([])
  const [epis, setEpis] = useState([])
  const [isCreateFacture, setIsCreateFacture] = useState(false)
  const [isCreatePldContract, setIsCreatePldContract] = useState(false)
  const [clientQualifications, setClientQualifications] = useState([])
  const [clientChantiers, setClientChantiers] = useState([])
  const [isAskAtradius, setIsAskAtradius] = useState(false)
  const [isLoadingAtradius, setIsLoadingAtradius] = useState(false)
  const [isDuplicateContract, setIsDuplicateContract] = useState(false)
  const [selectedContract, setSelectedContract] = useState(null)
  const [cover, setCover] = useState(null)
  const [loadedCover, setLoadedCover] = useState(false)
  const [amount, setAmount] = useState('')

  useEffect(() => {
    loadQuotations(client._id)
    loadQualifications()
    getCover()
  }, [])

  const loadQualifications = () => {
    if (!client.pld) {
      setCanCreateContrat(false)
      return
    }
    axios
      .get(`client/qualifications/${client.pld}`)
      .then((res) => {
        console.log(res.data)
        if (res.data.length === 0) {
          setCanCreateContrat(false)
        }
        setClientQualifications(res.data.TabQualifications)
        setClientChantiers(res.data.TabChantiers)
      })
      .catch((e) => console.log(e))
  }

  const getCover = () => {
    axios
      .get(`cover/client/${client._id}`)
      .then((res) => {
        console.log(res.data)
        if (res.data.length) {
          setCover(res.data[0])
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setLoadedCover(true))
  }

  const loadQuotations = (clientId) => {
    axios
      .get(`quotation/client/${clientId}`)
      .then((res) => {
        const quotations = res.data.sort(
          (a, b) => b.quotationNumber - a.quotationNumber,
        )
        setQuotations(quotations)
        setOriginalQuotations(quotations)
        console.log(res.data)
      })
      .catch((e) => {
        message.error('erreur')
      })
  }

  const episList = [
    'Gants',
    'Lunette',
    'Protection',
    'Blouse',
    'Bleu',
    'Chaussures',
    'Auditive',
    'Casque',
    'Harnais',
    'Gilet',
    'Autre..',
  ]

  const epiData = episList.map((epi) => {
    return {
      title: epi,
      value: epi,
      key: epi,
    }
  })

  const onChangeEpi = (newValue) => {
    setEpis(newValue)
  }

  const generatePDF = (quotation) => {
    console.log(quotation)

    const pdf = new jsPDF({
      unit: 'mm',
      format: [210, 297],
    })

    const img = new Image()
    if (quotation.agency.name === 'CAPENEO') {
      img.src = logotoulouse
      pdf.addImage(img, 'PNG', 14, 10, 40, 24)
    } else if (quotation.agency.name === "J'M CAPENEO") {
      img.src = logobrive
      pdf.addImage(img, 'PNG', 24, 14, 32, 24)
    } else {
      img.src = myImage
      pdf.addImage(img, 'PNG', 14, 10, 40, 24)
    }

    const gmailImg = new Image()
    gmailImg.src = gmail

    const phoneImg = new Image()
    phoneImg.src = phone

    pdf.setFontSize(14)
    pdf.setFont('Helvetica', 'Bold')
    pdf.text(quotation.agency.name, 24, 45)
    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    pdf.text(quotation.agency.address, 15, 50)
    pdf.text(quotation.agency.postCode + ' ' + quotation.agency.town, 15, 55)
    pdf.text('SIRET  : ' + quotation.agency.siret, 15, 60)
    pdf.text('TVA n° : ' + quotation.agency.tva, 15, 65)
    pdf.addImage(gmailImg, 'PNG', 15, 67, 3, 3)

    pdf.text(' : ' + quotation.agency.email, 18, 69.5)
    pdf.addImage(phoneImg, 'PNG', 15, 71.5, 3, 3)
    pdf.text(' : ' + quotation.agency.phone, 18, 74)
    pdf.text(
      'Contact : ' +
        quotation.creator.firstname +
        ' ' +
        quotation.creator.lastname,
      15,
      79.5,
    )

    pdf.setFontSize(14)
    pdf.setFont('Helvetica', 'Bold')
    if (quotation.quotationNumber) {
      pdf.text(
        'Devis n° ' +
          formatJsDate2(quotation.createdAt) +
          '-' +
          quotation.quotationNumber.toString().padStart(5, 0),
        110,
        45,
      )
    } else {
      pdf.text('Devis provisoirdddde', 110, 45)
    }

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')

    pdf.text('Date : ', 110, 50)
    pdf.text(formatJsDate(quotation.createdAt), 120, 50)
    pdf.text("Date d'expiration :", 110, 55)
    pdf.text(formatJsDate(quotation.expiryDate), 137, 55)

    pdf.setFontSize(10)
    pdf.setFont('Helvetica', 'Bold')
    pdf.text('Adresse de facturation :', 15, 95)

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    var [partieAvant, codePostal, partieApres] = quotation.addressFacturation
      .trim()
      .match(/^(.*?)\b(\d{5})\b(.*)$/)
      .slice(1)
      .map((str) => str.trim())
    try {
      pdf.text(partieAvant, 15, 100)
      pdf.text(codePostal + ' ' + partieApres, 15, 105)
    } catch (e) {
      console.log(e)
    }

    pdf.setFontSize(12)
    pdf.setFont('Helvetica', 'Bold')
    pdf.text(quotation.client.companyName.toUpperCase(), 110, 80)

    pdf.setFontSize(9)
    pdf.setFont('Helvetica', 'normal')
    pdf.text(
      `${quotation.contact.split(' ')[1].toUpperCase()} ${quotation.contact
        .split(' ')[2]
        .toUpperCase()}`,

      110.5,
      85,
    )
    pdf.text(partieAvant, 110.5, 90)
    pdf.text(codePostal + ' ' + partieApres, 110.5, 95)
    pdf.text('SIRET : ' + quotation.client.siret, 110.5, 100)
    pdf.text('TVA : ' + quotation.client.TVA, 110.5, 105)

    pdf.setFontSize(12)
    pdf.setFont('Helvetica', 'bold')
    pdf.setTextColor(9, 132, 227)
    pdf.setDrawColor(9, 132, 227)

    if (quotation.products[0].product === 'Placement') {
      pdf.roundedRect(26, 115, 160, 30, 1, 1)
      pdf.text('PLACEMENT', 95, 120)
      pdf.line(95, 122, 122, 122)

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)

      pdf.text(
        'Vous nous mandatez pour recruter au sein de votre entreprise, un poste en CDD ou CDI.',
        46,
        130,
      )
      pdf.text(
        "Le contrat, la DPAE et la fiche de paie sont établis par vos soins. Vous êtes l'employeur.",
        46,
        135,
      )
      pdf.text('Vous recevrez une seule facture pour le placement', 57, 140)
    }

    if (
      quotation.products[0].product === 'Délégation' ||
      quotation.products[0].product === 'Gestion'
    ) {
      pdf.roundedRect(15, 110, 92, 35, 1, 1)
      pdf.roundedRect(110, 110, 92, 35, 1, 1)
      pdf.text('DELEGATION', 47, 115)
      pdf.line(47, 117, 75, 117)
      pdf.text('GESTION', 145, 115)
      pdf.line(145, 117, 164, 117)

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)

      pdf.text('Vous nous mandatez pour', 17, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('recruter', 55, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('   et', 65, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('  gérer', 70, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text(" l'administratif", 80, 125)
      pdf.text('de ', 17, 130)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('notre', 21, 130)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('recrue. Le contrat, la DPAE et la fiche de paie sont', 30, 130)
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        17,
        135,
      )
      pdf.text(
        'la sécurité du salarié. Vous recevrez une facture mensuelle.',
        17,
        140,
      )

      pdf.text('Vous nous mandatez pour', 112, 125)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('gérer', 150, 125)
      pdf.setFont('Helvetica', 'normal')
      pdf.text("simplement l'administratif", 159, 125)
      pdf.text('de', 112, 130)
      pdf.setFont('Helvetica', 'bold')
      pdf.text('votre', 116, 130)
      pdf.setFont('Helvetica', 'normal')
      pdf.text('recrue. Le contrat, la DPAE et la fiche de paie sont', 125, 130)
      pdf.text(
        "établis par nous, qui sommes l'employeur. Vous avez à charge",
        112,
        135,
      )
      pdf.text(
        'la sécurité du salarié. Vous recevrez une facture mensuelle.',
        112,
        140,
      )
    }

    pdf.setTextColor(0, 0, 0)

    pdf.setFillColor(9, 132, 227)
    pdf.setDrawColor(9, 132, 227)
    pdf.rect(15, 155, 180, 10, 'F')

    pdf.setTextColor(255, 255, 255)
    pdf.setFontSize(10)
    pdf.setFont('Helvetica', 'bold')

    if (
      quotation.products[0].product === 'Délégation' ||
      quotation.products[0].product === 'Gestion'
    ) {
      let height = 170

      pdf.text('Designation', 25, 161)
      pdf.text('Taux horaire', 60, 161)
      pdf.text('Coefficient', 95, 161)
      pdf.text('Prix HT/heure', 130, 159)
      pdf.text('travaillée', 135, 163)
      pdf.text('Observation', 165, 161)

      pdf.setFontSize(10)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)
      pdf.setDrawColor(0, 0, 0)

      quotation.products.map((product, idx) => {
        if (product.type === 'Prime')
          pdf.text('Prime : ' + product.product, 20, height)
        else if (product.type === 'Indemnité')
          pdf.text('Indemnité : ' + product.product, 20, height)
        else if (product.type === 'Autre') pdf.text(product.product, 20, height)
        else {
          pdf.text(product.product + ' H/F', 20, height)
          const poste = splitStringWithoutCuttingWords(product.poste, 24)
          for (let i = 0; i < poste.length; i++) {
            pdf.text(poste[i], 20, height + 5)
            height += 5
          }
        }
        pdf.text(parseFloat(product.taux).toFixed(2) + '€', 65, height)
        pdf.text(product.coefficient, 105, height)
        pdf.text(
          (parseFloat(product.taux) * parseFloat(product.coefficient)).toFixed(
            2,
          ) + '€',
          140,
          height,
        )
        pdf.text(product.comment, 165, height)
        height += 5
        pdf.setTextColor(128, 128, 128)
        pdf.setDrawColor(128, 128, 128)
        if (product.minimumHours) {
          pdf.text(
            "Nombre d'heures minimales avant embauche sans facturation : " +
              product.minimumHours +
              'h',
            50,
            height,
          )
          height += 3
        }

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0)
          pdf.setLineWidth(0.5)
        }
        pdf.line(15, height, 195, height)
        pdf.setTextColor(0, 0, 0)
        pdf.setDrawColor(0, 0, 0)
        height += 7
        if (height > 260) {
          pdf.addPage()
          height = 10
        }
      })

      height += 10
      if (height > 250) {
        pdf.addPage()
        height = 10
      }

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')

      pdf.text('Nom et qualité du signataire :', 15, height)
      pdf.text('Date : ' + formatJsDate(new Date()), 15, height + 5)

      pdf.text(quotation.client.tvaRate, 120, height)
      pdf.text(
        'Conditions de paiement : ' + quotation.client.facturation,
        120,
        height + 5,
      )
      pdf.text('Moyen de règlement : Virement/Chèque/CB', 120, height + 10)
    }

    if (quotation.products[0].product === 'Placement') {
      let height = 170

      pdf.text('Designation', 25, 161)
      pdf.text('Quantité', 68, 161)
      pdf.text('PU (HT)', 110, 161)
      pdf.text('Montant (HT)', 160, 161)

      pdf.setFontSize(10)
      pdf.setFont('Helvetica', 'normal')
      pdf.setTextColor(0, 0, 0)
      pdf.setDrawColor(0, 0, 0)

      quotation.products.map((product, idx) => {
        pdf.text(product.product + ' H/F', 20, height)
        pdf.text(product.poste, 20, height + 5)
        height += 5
        pdf.text(parseFloat(product.taux).toFixed(2) + '€', 110, height)
        pdf.text(product.coefficient, 75, height)
        pdf.text(
          (parseFloat(product.taux) * parseFloat(product.coefficient)).toFixed(
            2,
          ) + '€',
          175,
          height,
        )
        height += 5
        pdf.setTextColor(128, 128, 128)
        pdf.setDrawColor(128, 128, 128)

        if (idx === quotation.products.length - 1) {
          pdf.setDrawColor(0, 0, 0)
          pdf.setLineWidth(0.5)
        }
        pdf.line(15, height, 195, height)
        pdf.setTextColor(0, 0, 0)
        pdf.setDrawColor(0, 0, 0)
        height += 7
        if (height > 260) {
          pdf.addPage()
          height = 10
        }
      })

      if (height > 250) {
        pdf.addPage()
        height = 10
      }

      pdf.setFillColor(135, 177, 255)
      pdf.setDrawColor(9, 132, 227)
      height += 6
      pdf.setTextColor(255, 255, 255)
      pdf.setFont('Helvetica', 'Bold')

      pdf.setTextColor(0, 0, 0)
      pdf.setFont('Helvetica', 'normal')

      pdf.text('Total HT', 110, height)
      pdf.text(
        quotation.products
          .reduce((total, item) => total + parseFloat(item.taux), 0)
          .toFixed(2) + ' €',
        174,
        height,
      )

      height += 10

      pdf.text(
        (
          quotation.products.reduce(
            (total, item) => total + parseFloat(item.taux),
            0,
          ) * 0.2
        ).toFixed(2) + ' €',
        174,
        height,
      )
      height += 10
      pdf.setFillColor(9, 132, 227)
      pdf.rect(105, height - 7, 90, 10, 'F')
      pdf.setTextColor(255, 255, 255)
      pdf.text('Total TTC', 110, height)
      pdf.text(
        (
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ) * 0.2,
          ) +
          parseFloat(
            quotation.products.reduce(
              (total, item) => total + parseFloat(item.taux),
              0,
            ),
          )
        ).toFixed(2) + ' €',
        174,
        height,
      )

      pdf.setTextColor(0, 0, 0)

      height += 10

      if (height >= 270) pdf.addPage()

      pdf.setFontSize(9)
      pdf.setFont('Helvetica', 'normal')

      /* let hauteur=35
      for(let x of comment2){
        pdf.text(x,18,height)
        height+=5
      }
      pdf.text(
        'Conditions de paiement : '+quotation.products[0].comment,
        18,
        height,
      )
      pdf.text('Moyens de règlement : Virement/Chèque/CB', 18, height+5)
      pdf.text(
        'Coordonnées bancaires Asnières : FR76 1005 7190 0300 0208 2410146 - CMCIFRPP',
        18,
        height+10,
      )
      pdf.text(
        'Coordonnées bancaires Brive / Toulouse : FR76 1005 7190 0300 0207 4100 156 - CMCIFRPP',
        18,
        height+15,
      )
      pdf.text('Nom et qualité du signataire :', 18, height+20)
      pdf.text('Date : ' + formatJsDate(new Date()), 18,  height+25) */

      if (height > 280) {
        pdf.addPage()
        height = 10
      }

      const comment2 = splitStringWithoutCuttingWords(
        quotation.products[0].comment2,
        90,
      )
      const paymentCondition = splitStringWithoutCuttingWords(
        'Conditions de paiement : ' + quotation.products[0].comment,
        70,
      )
      const replaceCondition = splitStringWithoutCuttingWords(
        'Conditions de remplacement : ' + quotation.products[0].comment2,
        70,
      )
      pdf.text('Nom et qualité du signataire :', 15, height)
      pdf.text('Date : ' + formatJsDate(new Date()), 15, height + 5)

      pdf.text('Moyen de règlement : Virement/Chèque/CB', 90, height)
      for (let x of paymentCondition) {
        pdf.text(x, 90, height + 5)
        height += 5
        if (height >= 280) {
          pdf.addPage()
          height = 10
        }
      }
      for (let x of replaceCondition) {
        pdf.text(x, 90, height + 10)
        height += 10
        if (height >= 280) {
          pdf.addPage()
          height = 10
        }
      }
    }

    pdf.text(
      `J'm intérim - SAS au capital de 80 000 € - 4 Avenue Laurent Cély, Hall A, 92600 Asnières sur Seine, France`,
      25,
      280,
    )
    pdf.text(
      'SIRET : 91912551800010 - Code APE : 7820z - N° de TVA : FR52919125518',
      50,
      285,
    )

    pdf.addPage()
    pdf.addImage(cgvClient, 'PNG', 10, 10, 198, 282)

    const pdfBlob = pdf.output('blob')
    const pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl, '_blank')
  }

  const atradiusValues = ['Accepté', 'Refusé', 'En arbitrage']

  const atradiusValueArray = atradiusValues.map((atradiusValue) => {
    return {
      value: atradiusValue,
      label: atradiusValue,
    }
  })

  const epiProps = {
    treeData: epiData,
    onChange: onChangeEpi,
    value: epis,
    treeCheckable: true,
    placeholder: 'EPI',
  }

  const setAtradius = (atradiusValue) => {
    if (atradiusValue === 'Accepté') {
      setAcceptedAtradius(true)
    } else {
      setAcceptedAtradius(false)
    }
  }

  function splitStringWithoutCuttingWords(inputString, chunkSize) {
    const words = inputString.split(' ')
    const resultChunks = []
    let currentChunk = ''

    for (const word of words) {
      if (currentChunk.length + word.length <= chunkSize) {
        currentChunk += (currentChunk ? ' ' : '') + word
      } else {
        resultChunks.push(currentChunk)
        currentChunk = word
      }
    }

    if (currentChunk) {
      resultChunks.push(currentChunk)
    }

    return resultChunks
  }

  const formatJsDate2 = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${month}${year}`
  }

  const deleteContact = (contacts, idx) => {
    console.log(contacts)
    console.log(idx)
    const newContacts = [...contacts]
    newContacts.splice(idx, 1)
    axios
      .patch(`client/${client._id}`, { contacts: newContacts })
      .then((res) => {
        setUpdateContactObject({})
        const clientIdx = originalClients.findIndex((c) => c._id === client._id)
        const newClients = [...originalClients]
        newClients[clientIdx] = res.data
        setClient(res.data)
        setClients(newClients)
        message.success('Contact supprimé ! ')
        setIsOpenCreateContact(false)
      })
      .catch((e) => {
        message.error("une erreur s'est produite ! ")
      })
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const filter = (array, word) => {
    const excludedFields = ['_id', 'user']
    word = word.toLowerCase()
    return array.filter((obj) => {
      const stringObj = JSON.stringify(obj).toLowerCase()
      return stringObj.includes(word.toLowerCase())
      /* for (const key in obj) {
        if (obj.hasOwnProperty(key) && !excludedFields.includes(key)) {
          const value = obj[key]

          if (typeof value === 'string' && value.toLowerCase().includes(word)) {
            return true
          }
          if (typeof value === 'object') {
            if (value.join('').toLowerCase().includes(word)) {
              return true
            }
          }
        }
      } */
    })
  }

  const updatePld = () => {
    axios
      .post(`client/${client._id}/update-pld`, { pld })
      .then((res) => {
        console.log(res.data)
        setClient(res.data)
        setIsEditPld(false)
      })
      .catch((e) => console.log(e))
  }

  const updateName = () => {
    axios
      .post(`client/${client._id}/update-name`, { companyName:name })
      .then((res) => {
        console.log(res.data)
        setClient(res.data)
        setIsEditName(false)
        setName(res.data.companyName)
      })
      .catch((e) => console.log(e))
  }

  const createPldContract = () => {
    if (canCreateContact) setIsCreatePldContract(true)
    else message.error("Le client n'existe pas sur tempo !")
  }

  const updateContacts = () => {
    axios
      .patch(`client/${client._id}`, {
        contacts: [...client.contacts, updateContactObject],
      })
      .then((res) => {
        setUpdateContactObject({})
        const clientIdx = originalClients.findIndex((c) => c._id === client._id)
        const newClients = [...originalClients]
        newClients[clientIdx] = res.data
        setClient(res.data)
        setClients(newClients)
        message.success('Contact créé ! ')
        setIsOpenCreateContact(false)
      })
      .catch((e) => {
        message.error("une erreur s'est produite ! ")
      })
  }

  const askAtradius = () => {
    setIsLoadingAtradius(true)
    if (!client.siret) {
      message.error('Le client doit avoir un numero de siret')
      return
    }
    if (amount.length === 0) {
      message.error('Veuillez entrer un montant entier !')
      return
    }
    const siren = client.siret.trim().substring(0, 9)
    axios
      .post('cover/new-cover', { amount, siren, clientId: client._id })
      .then((res) => {
        console.log(res)
        message.success('Cote demandée !')
        setIsAskAtradius(false)
      })
      .catch((e) => {
        console.log(e)
        message.error("Une erreur s'est produite !")
      })
      .finally(() => {
        setIsLoadingAtradius(false)
      })
  }

  const [isCreateQuotation, setIsCreateQuotation] = useState(false)

  function formatPhoneNumber(input) {
    const formatted = input.replace(/\B(?=(\d{2})+(?!\d))/g, ' ')
    return formatted
  }

  return (
    <div className="">
      {isAskAtradius && (
        <Modal
          open={isAskAtradius}
          footer={null}
          onCancel={() => setIsAskAtradius(false)}
        >
          <div className="flex flex-col mt-10 items-center justify-center space-y-4">
            <input
              onChange={(e) => setAmount(e.target.value)}
              className="border outline-none border-gray-300 w-96 rounded-md p-2"
              placeholder="Montant"
            />
            <button
              onClick={askAtradius}
              className="p-2 text-white rounded-md w-96 text-center bg-purple-500"
            >
              {!isLoadingAtradius && <span>Demander cote atradius</span>}
              {isLoadingAtradius && <span>...Patientez</span>}
            </button>
          </div>
        </Modal>
      )}

      {isCreateFacture && (
        <CreateFacture
          client={client}
          isCreateFacture={isCreateFacture}
          setIsCreateFacture={setIsCreateFacture}
        />
      )}

      {isOpenCreateContact && (
        <Modal
          footer={null}
          width={900}
          open={isOpenCreateContact}
          onCancel={() => setIsOpenCreateContact(false)}
          className="z-30"
        >
          <div className="flex items-center space-x-2 mb-6 mt-12">
            <Select
              className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              onChange={(e) =>
                setUpdateContactObject({ ...updateContactObject, title: e })
              }
              value={updateContactObject.title}
              optionFilterProp="children"
              placeholder="Civilité"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'M.',
                  value: 'M.',
                  key: 'M.',
                },
                {
                  label: 'Mme',
                  value: 'Mme',
                  key: 'Mme',
                },
              ]}
            />
            <Input
              placeholder="Prénom"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  firstname: e.target.value,
                })
              }
              value={updateContactObject.firstname}
              className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />
            <Input
              placeholder="Nom"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  lastname: e.target.value,
                })
              }
              value={updateContactObject.lastname}
              className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />
          </div>

          <Input
            placeholder="Profession"
            onChange={(e) =>
              setUpdateContactObject({
                ...updateContactObject,
                profession: e.target.value,
              })
            }
            value={updateContactObject.profession}
            className="px-2 mb-6 placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
          />

          <div className="mb-6 flex items-center space-x-2">
            <Input
              placeholder="Téléphone fixe"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  phone: e.target.value,
                })
              }
              value={updateContactObject.phone}
              className="px-2  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />
            <Input
              placeholder="Téléphone portable"
              onChange={(e) =>
                setUpdateContactObject({
                  ...updateContactObject,
                  mobile: e.target.value,
                })
              }
              value={updateContactObject.mobile}
              className="px-2 placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
            />
          </div>

          <Input
            placeholder="Email"
            onChange={(e) =>
              setUpdateContactObject({
                ...updateContactObject,
                email: e.target.value,
              })
            }
            value={updateContactObject.email}
            className="px-2 mb-6  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
          />

          {/*   <Select
          className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
          onChange={(e) => changeTitle(e)}
          value={updateContactObject.title}
          optionFilterProp="children"
          placeholder="Permissions"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              label: 'M.',
              value: 'M.',
              key: 'M.',
            },
            {
              label: 'Mme',
              value: 'Mme',
              key: 'Mme',
            },
          ]}
        /> */}

          <p className="text-right">
            <button
              onClick={updateContacts}
              className="bg-mountain-600 p-3 rounded-md text-white"
            >
              Enregistrer
            </button>
          </p>
        </Modal>
      )}

      {isCreatePldContract && (
        <CreateContract2
          client={client}
          qualifications={clientQualifications}
          chantiers={clientChantiers}
          isCreateContract={isCreatePldContract}
          setIsCreateContract={setIsCreatePldContract}
        />
      )}

      {isDuplicateContract && (
        <DuplicateContract
          client={client}
          selectedContract={selectedContract}
          qualifications={clientQualifications}
          chantiers={clientChantiers}
          isDuplicateContract={isDuplicateContract}
          setIsDuplicateContract={setIsDuplicateContract}
        />
      )}

      <Modal
        className="w-full h-screen"
        onCancel={() => {
          setClient(null)
          setSection('info')
        }}
        open={client}
        footer={null}
      >
        {isCreateQuotation && (
          <CreateQuotationModal
            client={client}
            isCreateQuotation={isCreateQuotation}
            setIsCreateQuotation={setIsCreateQuotation}
          />
        )}
        <div className="flex items-start space-x-14  overflow-hidden">
          <div>
            <div>
              <div className="text-2xl text-gray-600 font-semibold flex items-center space-x-2">
                {!isEditName &&<p>{client.companyName}</p>}
                {isEditName && (
                      <Input
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        placeholder="Nom du client"
                        className="w-40 rounded-md outline-none"
                      />
                    )}
                    {isEditName && (
                      <button
                        onClick={updateName}
                        className="bg-purple-400 text-sm p-2 rounded-md text-white "
                      >
                        Enregistrer
                      </button>
                    )}
                <EditOutlinedIcon className="hover:cursor-pointer" onClick={() => setIsEditName(!isEditName)}  />

              </div>
              {!client.pld && (
                <p className="text-blue-500 mx-auto w-fit text-xs py-1 px-2 mt-2 border-2 mb-4 border-blue-500 rounded-full">
                  Prospect
                </p>
              )}

              <div className="flex flex-col justify-between border-r border-r-blue-400 pr-12  mt-6 h-[500px]">
                <div>
                  <p className="capitalize  flex items-center space-x-1">
                    <PersonIcon className="text-base" />
                    <span>{client.commercial}</span>
                  </p>

                  <p className="capitalize flex items-center space-x-1">
                    <MultipleStopIcon className="text-base" />
                    <span>{client.clientAcquisition}</span>
                  </p>

                  <p className="lowercase flex mt-8 items-center space-x-1">
                    <EmailIcon className="text-base" />
                    <span>{client.contactEMail}</span>
                  </p>

                  <p className="lowercase flex items-center space-x-1">
                    <PhoneIcon className="text-base" />
                    <span>{formatPhoneNumber(client.contactPhone)}</span>
                  </p>

                  <p className="lowercase flex items-center space-x-1">
                    {!isEditPld && (
                      <span className="uppercase font-bold">PLD</span>
                    )}
                    {!isEditPld && <span>{client.pld}</span>}
                    {isEditPld && (
                      <Input
                        onChange={(e) => setPld(e.target.value)}
                        placeholder="PLD"
                        className="w-40 rounded-md outline-none"
                      />
                    )}
                    {isEditPld && (
                      <button
                        onClick={updatePld}
                        className="bg-mountain-500 p-2 rounded-md text-white "
                      >
                        Enregistrer
                      </button>
                    )}
                    <span>
                      <EditOutlinedIcon
                        onClick={() => setIsEditPld(!isEditPld)}
                        className="text-base"
                      />
                    </span>
                  </p>
                </div>

                <div className="flex flex-col space-y-2">
                  <p
                    onClick={() => setIsAskAtradius(true)}
                    className="text-white hover:cursor-pointer bg-blue-400 rounded-md font-semibold p-2 w-full  mx-auto  flex items-center space-x-1"
                  >
                    <img src={devis} className="w-6" />{' '}
                    <span>Demander une cote atradius</span>
                  </p>
                  <p
                    onClick={() => setIsCreateQuotation(true)}
                    className="text-white hover:cursor-pointer bg-blue-400 rounded-md font-semibold p-2 w-full  mx-auto  flex items-center space-x-1"
                  >
                    <img src={devis} className="w-6" />{' '}
                    <span>Faire un devis</span>
                  </p>

                  <p
                    onClick={() => setIsCreateFacture(true)}
                    className="text-white hover:cursor-pointer bg-green-400 rounded-md font-semibold w-full  p-2  mx-auto  flex items-center space-x-1"
                  >
                    <img src={contrat2} className="w-6" />{' '}
                    <span>Créer vacation / placement</span>
                  </p>

                  <p
                    onClick={() => createPldContract()}
                    className="text-white hover:cursor-pointer bg-yellow-400 rounded-md font-semibold w-full  p-2  mx-auto  flex items-center space-x-1"
                  >
                    <img src={contrat1} className="w-6" />{' '}
                    <span>Créer contrat PLD</span>
                  </p>

                  <p className="text-white bg-pink-400 rounded-md font-semibold w-full  p-2  mx-auto flex items-center space-x-1">
                    <img src={information} className="w-6" />{' '}
                    <span>Generer fiche de renseignement</span>
                  </p>
                </div>

                <div className="font-semibold  mt-6 flex flex-col space-y-2">
                  <p className="flex items-center">
                    <img src={money} className="w-8" />
                    <span className="ml-2">CA 2023 :</span>
                  </p>
                  <p className="flex items-center ml-8">
                    <span className="ml-2">CA 2022 :</span>
                  </p>
                  <p className="flex items-center ml-8">
                    <span className="ml-2">CA 2021 :</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="grow mt-6 h-full">
            <div className="flex  space-x-2 justify-between items-center">
              <p
                onClick={() => setSection('info')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'info'
                    ? 'bg-red-50 text-red-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={infoimage} className="w-6" />
                <span>Informations</span>
              </p>
              <p
                onClick={() => setSection('contact')}
                className={`w-1/6 text-center hover:cursor-pointer  uppercase flex items-center space-x-2 font-semibold p-2 ${
                  section === 'contact'
                    ? 'bg-blue-50 text-blue-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={contactimage} className="w-6" />
                <span>Contact</span>
              </p>
              <p
                onClick={() => setSection('event')}
                className={`w-1/6 text-center hover:cursor-pointer uppercase flex items-center space-x-2 font-semibold p-2 ${
                  section === 'event'
                    ? 'bg-green-50 text-green-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={eventimage} className="w-6" />
                <span>Evenement</span>
              </p>
              <p
                onClick={() => setSection('devis')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'devis'
                    ? 'bg-pink-50 text-pink-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={financialimage} className="w-6" />
                <span>Devis</span>
              </p>

              <p
                onClick={() => setSection('financial')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'financial'
                    ? 'bg-purple-50 text-purple-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={financialimage} className="w-6" />
                <span>ATRADIUS</span>
              </p>

              <p
                onClick={() => setSection('file')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'file'
                    ? 'bg-orange-50 text-orange-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={fileimage} className="w-6" />
                <span>Fichiers</span>
              </p>
              <p
                onClick={() => setSection('commentaire')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'commentaire'
                    ? 'bg-yellow-50 text-yellow-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={commentimage} className="w-6" />
                <span>Commentaires</span>
              </p>
              <p
                onClick={() => setSection('contrat')}
                className={`w-1/6 text-center hover:cursor-pointer flex items-center space-x-2 uppercase font-semibold p-2 ${
                  section === 'contrat'
                    ? 'bg-cyan-100  text-cyan-500 rounded-tr-xl rounded-tl-xl'
                    : ''
                }`}
              >
                <img src={infoimage} className="w-6" />
                <span>Contrat</span>
              </p>
            </div>

            {section === 'info' && (
              <div className="bg-red-50  py-10 px-4 h-full">
                <div className="flex items-start space-x-2">
                  <div className="flex flex-col space-y-2 grow">
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold"> Siège : </span>

                      <span className="lowercase">
                        {client.address}, {client.postCode}, {client.town}
                      </span>
                    </p>
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold"> Livraison : </span>

                      <span className="lowercase">
                        {client.addressLivraison}, {client.postCodeLivraison},{' '}
                        {client.townLivraison}
                      </span>
                    </p>

                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold">Facturation :</span>
                      <span className="lowercase">
                        {client.addressFacturation},{' '}
                        {client.postCodeFacturation}, {client.townFacturation}
                      </span>
                    </p>
                    {!cover && loadedCover && (
                      <p className="uppercase font-semibold text-red-500">
                        Aucune couverture atradius
                      </p>
                    )}
                  </div>

                  <div className="grow">
                    <p className=" flex items-center space-x-1">
                      <span className="font-semibold">SIRET :</span>

                      <span>{client.siret}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1">
                      <span className="font-semibold">APE :</span>
                      <span className="uppercase">{client.APE}</span>
                    </p>
                  </div>
                  <div className="grow">
                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">
                        CONDITIONS DE PAIEMENT :
                      </span>
                      <span className="uppercase">{client.facturation}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">TAUX DE TVA :</span>
                      <span className="uppercase">{client.tvaRate}</span>
                    </p>

                    <p className="uppercase flex items-center space-x-1 mb-8">
                      <span className="font-semibold">TVA INTRA. :</span>
                      <span className="uppercase">{client.TVA}</span>
                    </p>
                  </div>
                </div>

                <p className="uppercase flex items-center space-x-1 mt-28">
                  <span className="font-semibold">
                    EPI FOURNIS PAR L'E.U. :
                  </span>
                </p>
                <TreeSelect
                  className="w-96 rounded-full border-0"
                  size="large"
                  {...epiProps}
                />
              </div>
            )}

            {section === 'contact' && (
              <div className="bg-blue-50  py-10 px-1 h-full">
                <div className="flex items-center text-xs mx-10">
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    TITRE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    NOM
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    PRENOM
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    POSTE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    EMAIL
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    TELEPHONE
                  </p>
                  <p className="w-[12%] text-center  font-semibold text-blue-400">
                    HABILITATION
                  </p>
                  <div className="flex items-center w-[12%] space-x-4 justify-center">
                    <AddIcon
                      className="hover:cursor-pointer text-blue-400"
                      onClick={() => setIsOpenCreateContact(true)}
                    />
                  </div>
                </div>

                {client.contacts.map((contact, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center mt-6 text-xs mx-10"
                    >
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {contact.title}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {' '}
                        {contact.lastname}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {' '}
                        {contact.firstname}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {' '}
                        {contact.profession}
                      </p>
                      <p className="w-[12%] text-center  font-semibold">
                        {' '}
                        {contact.email}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {' '}
                        {contact.mobile}
                      </p>
                      <p className="w-[12%] text-center capitalize font-semibold">
                        {contact.habilitation || ''}{' '}
                      </p>
                      <p className="w-[12%] justify-center items-center  flex space-x-2 text-xs text-blue-600">
                        <EditOutlinedIcon />
                        <DeleteForeverIcon
                          onClick={() => deleteContact(client.contacts, idx)}
                        />
                      </p>
                    </div>
                  )
                })}
              </div>
            )}

            {section === 'financial' && (
              <div className="bg-purple-50  py-10 px-4 h-full">
                <div className=" mt-2 mx-auto h-32">
                  <div className="flex p-3 bg-gray-500 text-white items-center justify-between mb-2 py-3">
                    <p className="w-1/6 text-center">DATE DE LA DEMANDE</p>
                    <p className="w-1/6 text-center">TYPE D'ASSURANCE</p>
                    <p className="w-1/6 text-center">MONTANT DEMANDE</p>
                    <p className="w-1/6 text-center">MONTANT ACCORDE</p>
                    <p className="w-1/6 text-center">FACTURE EN COURS</p>
                    <p className="w-1/6 text-center">ETAT</p>
                  </div>
                  {cover && (
                    <div className="flex bg-white shadow-lg text-xs border border-gray-300 rounded-md p-3 items-center justify-between">
                      <p className="w-1/6  text-center">
                        {formatJsDate(cover._doc.decisionDate)}
                      </p>
                      <p className="w-1/6 text-center">
                        {cover._doc.coverType}
                      </p>
                      <p className="w-1/6  text-center">
                        {
                          cover._doc
                            .creditLimitApplicationAmountInPolicyCurrency
                        }
                        &euro;
                      </p>
                      <p className="w-1/6  text-center">
                        {cover._doc.totalDecision.decisionAmtInPolicyCurrency}
                        &euro;
                      </p>
                      <p className="w-1/6  text-center">
                        {cover.montantNonFacture.toFixed(2)}&euro;
                      </p>
                      {cover.montantNonFacture <=
                        cover._doc.totalDecision.decisionAmtInPolicyCurrency &&
                        cover._doc.totalDecision.decisionAmtInPolicyCurrency >
                          0 && (
                          <p className="w-1/6 text-green-600  text-center">
                            OK
                          </p>
                        )}
                      {cover._doc.totalDecision.decisionAmtInPolicyCurrency ===
                        '0' && (
                        <p className="w-1/6 text-red-600  text-center uppercase">
                          refusée
                        </p>
                      )}
                      {cover._doc.totalDecision.decisionAmtInPolicyCurrency !==
                        '0' &&
                        cover.montantNonFacture >
                          cover._doc.totalDecision
                            .decisionAmtInPolicyCurrency && (
                          <p className="w-1/6 text-red-600 font-semibold  text-center">
                            A REVOIR
                          </p>
                        )}
                      <p className="w-1/6  text-center"></p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {section === 'event' && (
              <div className="bg-green-50  py-10 px-4 h-full">
                <div className="mt-10 uppercase text-xs flex items-center justify-between space-x-1">
                  <p className="text-green-400 text-center">type</p>
                  <p className="text-green-400 text-center">date</p>
                  <p className="text-green-400 text-center">heure</p>
                  <p className="text-green-400 text-center">lieu</p>
                  <p className="text-green-400 text-center">commentaire</p>

                  <AddIcon
                    className="hover:cursor-pointer w-20 text-green-400"
                    onClick={() => setIsOpenCreateTask(true)}
                  />
                </div>

                <div className="mt-10 font-semibold flex flex-col space-y-2">
                  {tasks.map((task) => {
                    return (
                      <div className="uppercase text-xs flex items-center justify-between space-x-1">
                        <p className=" text-center">{task.type}</p>
                        <p className=" text-center">
                          {formatJsDate(task.date)}
                        </p>
                        <p className=" text-center">{task.hour}</p>
                        <p className=" text-center">{task.place}</p>
                        <p className=" text-center">{task.comment}</p>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            {section === 'file' && <ClientDocuments client={client} />}

            {section === 'commentaire' && (
              <ClientComment
                client={client}
                clients={clients}
                setClients={setClients}
              />
            )}

            {section === 'devis' && (
              <div className="mt-4">
                <div className="flex items-center justify-between  mb-6 mx-10 text-xs">
                  <p className="w-1/6 text-center">NUMERO DE DEVIS</p>
                  <p className="w-1/6 text-center">CLIENT</p>
                  <p className="w-1/6 text-center">POSTE</p>
                  <p className="w-1/6 text-center">COEFFICIENT</p>
                  <p className="w-1/6 text-center">TAUX HORAIRE</p>
                  <p className="w-1/6 text-center">STATUT</p>
                </div>
                <div className="flex flex-col space-y-2">
                  {quotations.map((quotation, idx) => {
                    return (
                      <div
                        key={idx}
                        className=" py-4 flex items-center drop-shadow-2xl text-sm justify-between mx-10 bg-white border border-gray-200 rounded-md p-4 hover:scale-105 hover:cursor-pointer transition-all duration-300"
                      >
                        <div className="w-1/6">
                          {quotation.isDraft && (
                            <p className="flex justify-center">
                              <span className="bg-gray-200 px-2 py-1 rounded-md text-center">
                                Brouillon
                              </span>
                            </p>
                          )}
                          {!quotation.isDraft && (
                            <p className="text-center">
                              {quotation.quotationNumber
                                .toString()
                                .padStart(5, '0')}
                            </p>
                          )}
                        </div>

                        <div className="w-1/6">
                          <p className="font-bold text-center">
                            {quotation.client.companyName}
                          </p>
                        </div>

                        <div className="w-1/6">
                          {quotation.products.map((product, idx) => {
                            return (
                              <>
                                <div key={idx}>
                                  {product.type === 'Prime' && (
                                    <p className="font-bold text-center">
                                      Prime : {product.product}{' '}
                                    </p>
                                  )}
                                </div>

                                <div>
                                  {product.type !== 'Prime' && (
                                    <p className="font-bold text-center">
                                      {product.poste}{' '}
                                    </p>
                                  )}
                                </div>
                              </>
                            )
                          })}
                        </div>

                        <div className="w-1/6">
                          {quotation.products.map((product) => {
                            return (
                              <p className="font-bold text-center">
                                {product.coefficient}
                              </p>
                            )
                          })}
                        </div>

                        <div className="w-1/6">
                          {quotation.products.map((product) => {
                            return (
                              <p className="font-bold text-center">
                                {product.taux}
                              </p>
                            )
                          })}
                        </div>

                        <div className="w-1/6 flex justify-center items-center ">
                          <p className="flex items-center space-x-2">
                            <SearchIcon
                              onClick={() => generatePDF(quotation)}
                              className="hover:text-green-500"
                            />
                            <DownloadingIcon className="hover:text-green-500" />
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}

            {section === 'contrat' && (
              <div className="bg-fuschia-50 h-full">
                <ClientModalContract
                  client={client}
                  setSelectedContract={setSelectedContract}
                  setIsDuplicateContract={setIsDuplicateContract}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ClientDetailModal
