import { Modal, Select, message } from 'antd'
import axios from '../../axios/axiosInstance'
import { useState } from 'react'

const ModifyStatus = ({
  acompteId,
  modifierAcompte,
  onClose,
  setModifierAcompte,
}) => {
  const [status, setStatus] = useState('')

  const modify = () => {
    axios
      .put(`acompte/${acompteId}`, {
        status,
      })
      .then((res) => {
        message.success('Modification enregistrées')
        onClose(res.data)
        setModifierAcompte(false)
      })
      .catch((e) => {
        message.error('Vous ne pouvez pas modifier !')
      })
  }

  return (
    <Modal
      open={modifierAcompte}
      footer={null}
      onCancel={() => setModifierAcompte(false)}
    >
      <div className="flex items-center space-y-6 flex-col mt-10">
        <Select
          className={`w-96  font-light rounded-md border`}
          optionFilterProp="children"
          placeholder="Statut"
          onChange={(e) => setStatus(e)}
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: 'En cours de traitement',
              label: 'En cours de traitement',
              key: '0',
            },
            {
              value: 'En attente des RH',
              label: 'En attente des RH',
              key: '1',
            },
            {
              value: 'En attente du RIB',
              label: 'En attente du RIB',
              key: '2',
            },
          ]}
        />
        <button
          className="p-2 text-white w-96 bg-green-600 rounded-md"
          onClick={modify}
        >
          Modifier le statut
        </button>
      </div>
    </Modal>
  )
}

export default ModifyStatus
