import { DesktopTimePicker } from '@mui/x-date-pickers'
import { DatePicker, Input, TimePicker, message } from 'antd'
import dayjs from 'dayjs'

const CreateContractDate = ({ contract, setContract }) => {
  console.log(contract)
  const selectStartDate = (date) => {
    if (date > contract.endDate) {
      message.error('La date de depart doit etre anterieur à la date de fin !')
    } else {
      let startDate = new Date(date);
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      setContract({ ...contract, startDate })
    }
    console.log(date)
  }

  const selectEndDate = (date) => {

    if (date < contract.startDate) {
      message.error('La date de fin doit etre posterieur à la date de depart !')
    } else {
      let endDate = new Date(date);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      setContract({ ...contract, endDate })
    }
    console.log(date)
  }

  const handleStartTimeChange = (newValue) => {
    if (newValue && newValue.$d) {
      const localDate = newValue.$d
      // Convert localDate to UTC
      const utcDate = new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          localDate.getHours(),
          localDate.getMinutes(),
          localDate.getSeconds(),
        ),
      )
      setContract({ ...contract, endHour: utcDate })
    }
  }

  return (
    <div>
      <p className="font-semibold text-center text-3xl">Date de la mission</p>

      <div className="flex items-center my-6 space-x-2 justify-center">
        <DatePicker
          //onChange={(e) => setContract({...contract,startDate:e.$d})}
          onChange={(e) => selectStartDate(e.$d)}
          value={contract.startDate ? dayjs(contract?.startDate) : null}
          className="w-1/2 h-10"
          format="DD/MM/YYYY"
          placeholder="Date de début de la mission"
        />
        <DatePicker
          //onChange={(e) => setContract({...contract,endDate:e.$d})}
          onChange={(e) => selectEndDate(e.$d)}
          value={contract.endDate ? dayjs(contract?.endDate) : null}
          className="w-1/2 h-10"
          format="DD/MM/YYYY"
          placeholder="Date de fin de la mission"
        />
      </div>

      <div className="flex items-center my-6 space-x-2">
        <DesktopTimePicker
          value={contract.startHour && dayjs(contract.startHour)}
          onChange={(newValue) => {
            if (newValue) {
              setContract({ ...contract, startHour: newValue.$d })
            }
          }}
          format="HH:mm"
          className="w-1/2 outline-none"
          placeholder="Heure de début"
        />
        <DesktopTimePicker
          format="HH:mm"
          value={contract.endHour && dayjs(contract.endHour)}
          className="w-1/2 outline-none focus:outline-none hover:outline-none"
          onChange={(newValue) => {
            if (newValue) {
              setContract({ ...contract, endHour: newValue.$d })
            }
          }}
          placeholder="Heure de fin"
        />
      </div>
      <div>
        <Input
          onChange={(e) =>
            setContract({ ...contract, nbHeureHebdo: e.target.value })
          }
          value={contract.nbHeureHebdo ? `${contract.nbHeureHebdo}` : null}
          className="rounded-md bcontract bcontract-gray-300 mb-2 mt-6"
          placeholder="Nombre heures hebdomadaire"
        />
      </div>
    </div>
  )
}

export default CreateContractDate
