import logo from '../../assets/img/logo.png'
import { AuthContext } from '../../context/authContext'
import { Menu, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import { DocumentScannerOutlined } from '@mui/icons-material'
import axios from '../../axios/axiosInstance'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'

const Menu3 = ({ toggle, toggleDashboard }) => {
  const { setIsLoggedIn } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isReloaded, setIsReloaded] = useState(['a'])
  const [agencyName, setAgencyName] = useState('')
  const [selected, setSelected] = useState('dashboard')
  const [openedGestion, setOpenedGestion] = useState(false)
  const [openedCandidat, setOpenedCandidat] = useState(false)
  const [openedInterimaire, setOpenedInterimaire] = useState(false)
  const [openedClient, setOpenedClient] = useState(false)
  const [openedParametre, setOpenedParametre] = useState(false)
  const [openedSwitch, setOpenedSwitch] = useState(false)
  const [openedCommercial, setOpenedCommercial] = useState(false)
  const [openedPay, setOpenedPay] = useState(false)
  const [openedRecrutement, setOpenedRecrutement] = useState(false)

  const agencies = [
    {
      id: '650adee7951dd697c7535e3e',
      name: 'Asnieres',
    },
    {
      id: '6533fe25099978decf532a59',
      name: 'Brive',
    },
    {
      id: '654a3d82874a059df3f98653',
      name: 'Toulouse',
    },
  ]

  const switchAgency = (agencyName, agencyId) => {
    axios
      .post('agency/switch', { agency: agencyId })
      .then((res) => {
        setIsReloaded([...isReloaded])
        setAgencyName(agencyName)
        toggle('dashboard')
        toggleDashboard()
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          message.error("Vous n'etes pas autorisé à changer d'agence !")
        }
      })
  }

  useEffect(() => {})

  const click = (item) => {
    toggle(item)
    setSelected(item)
  }

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userRole')
    navigate('/login')
  }

  /*   function getItem(label, key, icon, onClick, children) {
      return {
        key,
        icon,
        children,
        onClick,
        label,
      }
    }
    const items = [
      getItem('Commande', 'commande', <DocumentScannerOutlined />, click),
      getItem(
        'Signatures des contrats',
        'signature',
        <DocumentScannerOutlined />,
        click,
      ),
      getItem(
        'Prolongations des contracts',
        'prolongation',
        <DocumentScannerOutlined />,
        click,
      ),
      getItem('Saisie des heures', 'saisie', <DocumentScannerOutlined />, click),
      getItem(
        'Proposition de candidats',
        'proposition',
        <DocumentScannerOutlined />,
        click,
      ),
      getItem('Documents', 'documents', <DocumentScannerOutlined />, click),
      getItem('Deconnexion', 'deconnexion', null, logout),
  
      ,
    ]
   */
  return (
    <>
      {/* <Spin spinning={true} fullscreen indicator={<LoadingOutlined />} size='large' /> */}
      <div className="text-base bg-[#f9fafb] border-r border-r-gray-300  flex flex-col  scrollbar-thin scrollbar-thumb-slate-700 scrollbar-track-slate-300  overflow-y-auto text-black fixed top-0 left-0 h-full">
        <img className="w-24 mx-auto mt-8 mb-8" src={logo} />
        <p className="text-center -mt-6 mb-6 text-lg italic">{agencyName}</p>

        {/*  <Menu
            className="grow pb-10 text-lg"
            style={{
              width: 256,
              backgroundColor: '#F7F9FC',
            }}
            mode="inline"
            defaultOpenKeys={['sub3']}
          >
            <SubMenu>
              <p>ooo</p>
              <p>ooo</p>
              <p>ooo</p>
              <p>ooo</p>
            </SubMenu>
            </Menu>  */}
        <div className="w-[256px] flex flex-col items-center   font-medium justify-center space-y-6">
          <p
            onClick={() => {
              click('dashboard')
            }}
            className={`${
              selected === 'dashboard' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md w-4/5 flex hover:cursor-pointer items-center space-x-2`}
          >
            <span>Tableau de bord</span>
          </p>

          <div className={`flex w-full items-center flex-col justify-center `}>
            <p
              onClick={() => {
                setOpenedGestion(!openedGestion)
              }}
              className={`${
                openedGestion ? 'bg-gray-200' : ''
              } p-2 rounded-md w-4/5 hover:cursor-pointer font-medium flex items-center justify-between`}
            >
              <span>Gestion</span>
              {!openedGestion && (
                <span>
                  {' '}
                  <ChevronDownIcon className="w-6" />
                </span>
              )}
              {openedGestion && (
                <span>
                  {' '}
                  <ChevronUpIcon className="w-6" />
                </span>
              )}
            </p>
            <div
              className={`w-4/5 transition-all duration-1000 px-4 flex flex-col space-y-3 mt-3 ${
                openedGestion ? 'fadeIn' : 'hidden opacity-0'
              }`}
            >
              <p
                className={`${
                  selected === 'taches' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('taches')
                }}
              >
                Taches du jour
              </p>

              <div className={`flex w-full  flex-col justify-center `}>
                <p
                  onClick={() => {
                    setOpenedCommercial(!openedCommercial)
                  }}
                  className={`${
                    openedCommercial ? 'bg-gray-200' : ''
                  } p-2 rounded-md  hover:cursor-pointer  flex items-center justify-between`}
                >
                  <span>Commerciale</span>
                  {!openedCommercial && (
                    <span>
                      {' '}
                      <ChevronDownIcon className="w-6" />
                    </span>
                  )}
                  {openedCommercial && (
                    <span>
                      {' '}
                      <ChevronUpIcon className="w-6" />
                    </span>
                  )}
                </p>
                <div
                  className={` transition-all duration-1000  flex flex-col space-y-3 mt-3 ${
                    openedCommercial ? 'fadeIn' : 'hidden opacity-0'
                  }`}
                >
                  <p
                    className={`${
                      selected === 'atradius' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('atradius')
                    }}
                  >
                    Atradius
                  </p>

                  <p
                    className={`${
                      selected === 'controleReference' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('controleReference')
                    }}
                  >
                    Controle reference
                  </p>
                </div>
              </div>

              <div className={`flex w-full  flex-col justify-center `}>
                <p
                  onClick={() => {
                    setOpenedPay(!openedPay)
                  }}
                  className={`${
                    openedPay ? 'bg-gray-200' : ''
                  } p-2 rounded-md  hover:cursor-pointer  flex items-center justify-between`}
                >
                  <span>Paie</span>
                  {!openedPay && (
                    <span>
                      {' '}
                      <ChevronDownIcon className="w-6" />
                    </span>
                  )}
                  {openedPay && (
                    <span>
                      {' '}
                      <ChevronUpIcon className="w-6" />
                    </span>
                  )}
                </p>
                <div
                  className={` transition-all duration-1000  flex flex-col space-y-3 mt-3 ${
                    openedPay ? 'fadeIn' : 'hidden opacity-0'
                  }`}
                >
                  <p
                    className={`${
                      selected === 'maladie' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('maladie')
                    }}
                  >
                    Absences
                  </p>

                  <p
                    className={`${
                      selected === 'acompte' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('acompte')
                    }}
                  >
                    Acomptes
                  </p>

                  <p
                    className={`${
                      selected === 'facture-manuelle' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('facture-manuelle')
                    }}
                  >
                    Factures à faire
                  </p>

                  <p
                    className={`${
                      selected === 'renseignement' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('renseignement')
                    }}
                  >
                    Fiche renseignement
                  </p>

                  <p
                    className={`${
                      selected === 'rh' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('rh')
                    }}
                  >
                    Relevés d'heures
                  </p>

                  <p
                    className={`${
                      selected === 'saisie' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('saisie')
                    }}
                  >
                    Saisie arret
                  </p>

                  <p
                    className={`${
                      selected === 'visite' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('visite')
                    }}
                  >
                    Visite médicale
                  </p>
                </div>
              </div>

              <div className={`flex w-full  flex-col justify-center `}>
                <p
                  onClick={() => {
                    setOpenedRecrutement(!openedRecrutement)
                  }}
                  className={`${
                    openedRecrutement ? 'bg-gray-200' : ''
                  } p-2 rounded-md  hover:cursor-pointer  flex items-center justify-between`}
                >
                  <span>Recrutement</span>
                  {!openedRecrutement && (
                    <span>
                      {' '}
                      <ChevronDownIcon className="w-6" />
                    </span>
                  )}
                  {openedRecrutement && (
                    <span>
                      {' '}
                      <ChevronUpIcon className="w-6" />
                    </span>
                  )}
                </p>
                <div
                  className={` transition-all duration-1000  flex flex-col space-y-3 mt-3 ${
                    openedRecrutement ? 'fadeIn' : 'hidden opacity-0'
                  }`}
                >
                  <p
                    className={`${
                      selected === 'annonces' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('annonces')
                    }}
                  >
                    Annonces
                  </p>

                  <p
                    className={`${
                      selected === 'order' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('order')
                    }}
                  >
                    Commande
                  </p>

                  <p
                    className={`${
                      selected === 'prolongations' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('prolongations')
                    }}
                  >
                    Prolongations
                  </p>

                  <p
                    className={`${
                      selected === 'WaitingDocument' ? 'bg-[#EBE9FE]' : ''
                    } p-2 px-4 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      click('WaitingDocument')
                    }}
                  >
                    Validation de documents
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={` flex w-full items-center flex-col justify-center `}>
            <p
              onClick={() => {
                setOpenedCandidat(!openedCandidat)
              }}
              className={`${
                openedCandidat ? 'bg-gray-200' : ''
              } p-2 rounded-md w-4/5 hover:cursor-pointer font-medium flex items-center justify-between`}
            >
              <span>Candidats</span>
              {!openedCandidat && (
                <span>
                  {' '}
                  <ChevronDownIcon className="w-6" />
                </span>
              )}
              {openedCandidat && (
                <span>
                  {' '}
                  <ChevronUpIcon className="w-6" />
                </span>
              )}
            </p>
            <div
              className={`w-4/5 transition-all duration-1000 px-4 flex flex-col space-y-3 mt-3 ${
                openedCandidat ? 'fadeIn' : 'hidden opacity-0'
              }`}
            >
              <p
                className={`${
                  selected === 'searchCandidat' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md overflow-x-hidden hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('searchCandidat')
                }}
              >
                Rechercher candidat
              </p>
              <p
                className={`${
                  selected === 'availableCandidat' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('availableCandidat')
                }}
              >
                Candidats disponibles
              </p>
            </div>
          </div>

          <div className={` flex w-full items-center flex-col justify-center `}>
            <p
              onClick={() => {
                setOpenedInterimaire(!openedInterimaire)
              }}
              className={`${
                openedInterimaire ? 'bg-gray-200' : ''
              } p-2 rounded-md w-4/5 hover:cursor-pointer font-medium flex items-center justify-between`}
            >
              <span>Interimaires</span>
              {!openedInterimaire && (
                <span>
                  {' '}
                  <ChevronDownIcon className="w-6" />
                </span>
              )}
              {openedInterimaire && (
                <span>
                  {' '}
                  <ChevronUpIcon className="w-6" />
                </span>
              )}
            </p>
            <div
              className={`w-4/5 transition-all duration-1000 px-4 flex flex-col space-y-3 mt-3 ${
                openedInterimaire ? 'fadeIn' : 'hidden opacity-0'
              }`}
            >
              <p
                className={`${
                  selected === 'planning' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('planning')
                }}
              >
                Planning
              </p>
              <p
                className={`${
                  selected === 'candidatContract' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('candidatContract')
                }}
              >
                Contrat
              </p>
              <p
                className={`${
                  selected === 'pay' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('pay')
                }}
              >
                Fiches de paie
              </p>
              <p
                className={`${
                  selected === 'attestation' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('attestation')
                }}
              >
                Pole emploi
              </p>
              <p
                className={`${
                  selected === 'certificatTravail' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('certificatTravail')
                }}
              >
                Certificat de travail
              </p>
              <p
                className={`${
                  selected === 'fiscal' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('fiscal')
                }}
              >
                Attestation fiscale
              </p>
            </div>
          </div>

          <div
            className={`  flex w-full items-center flex-col justify-center `}
          >
            <p
              onClick={() => {
                setOpenedClient(!openedClient)
              }}
              className={`${
                openedClient ? 'bg-gray-200' : ''
              } p-2 rounded-md w-4/5 hover:cursor-pointer font-medium flex items-center justify-between`}
            >
              <span>Clients</span>
              {!openedClient && (
                <span>
                  {' '}
                  <ChevronDownIcon className="w-6" />
                </span>
              )}
              {openedClient && (
                <span>
                  {' '}
                  <ChevronUpIcon className="w-6" />
                </span>
              )}
            </p>
            <div
              className={`w-4/5 transition-all duration-1000 px-4 flex flex-col space-y-3 mt-3 ${
                openedClient ? 'fadeIn' : 'hidden opacity-0'
              }`}
            >
              <p
                className={`${
                  selected === 'searchClient' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('searchClient')
                }}
              >
                Rechercher client
              </p>
              <p
                className={`${
                  selected === 'devis' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('devis')
                }}
              >
                Devis
              </p>
              <p
                className={`${
                  selected === 'clientContract' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('clientContract')
                }}
              >
                Contrat
              </p>

              <p
                className={`${
                  selected === 'facture' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('facture')
                }}
              >
                Factures
              </p>

              <p
                className={`${
                  selected === 'registre' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md  hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('registre')
                }}
              >
                Registre du personnel
              </p>
            </div>
          </div>

          <p
            onClick={() => {
              click('documents')
            }}
            className={`${
              selected === 'documents' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md w-4/5 flex hover:cursor-pointer items-center space-x-2`}
          >
            <span>Documents</span>
          </p>

          <p
            onClick={() => {
              click('perso')
            }}
            className={`${
              selected === 'perso' ? 'bg-[#EBE9FE]' : ''
            } p-2 rounded-md w-4/5 flex hover:cursor-pointer items-center space-x-2`}
          >
            <span>Espace personnel</span>
          </p>

          <div className={` flex w-full items-center flex-col justify-center `}>
            <p
              onClick={() => {
                setOpenedParametre(!openedParametre)
              }}
              className={`${
                openedParametre ? 'bg-gray-200' : ''
              } p-2 rounded-md w-4/5 hover:cursor-pointer font-medium flex items-center justify-between`}
            >
              <span>Parametres</span>
              {!openedParametre && (
                <span>
                  {' '}
                  <ChevronDownIcon className="w-6" />
                </span>
              )}
              {openedParametre && (
                <span>
                  {' '}
                  <ChevronUpIcon className="w-6" />
                </span>
              )}
            </p>
            <div
              className={`w-4/5 transition-all duration-1000 px-4 flex flex-col space-y-3 mt-3 ${
                openedParametre ? 'fadeIn' : 'hidden opacity-0'
              }`}
            >
              <p
                className={`${
                  selected === 'utilisateurs' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md overflow-x-hidden hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  click('utilisateurs')
                }}
              >
                Utilisateurs
              </p>

              <div
                className={`flex w-full items-center flex-col justify-center `}
              >
                <p
                  onClick={() => {
                    setOpenedSwitch(!openedSwitch)
                  }}
                  className={`p-2 rounded-md  hover:cursor-pointer font-medium flex items-center justify-between`}
                >
                  <span>Switcher agence</span>
                  {!openedSwitch && (
                    <span>
                      {' '}
                      <ChevronDownIcon className="w-6" />
                    </span>
                  )}
                  {openedSwitch && (
                    <span>
                      {' '}
                      <ChevronUpIcon className="w-6" />
                    </span>
                  )}
                </p>
                <div
                  className={` transition-all duration-1000  flex flex-col space-y-3 mt-3 ${
                    openedSwitch ? 'fadeIn' : 'hidden opacity-0'
                  }`}
                >
                  <p
                    className={` p-2 rounded-md overflow-x-hidden hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      switchAgency('Asnieres', '650adee7951dd697c7535e3e')
                    }}
                  >
                    Asnieres
                  </p>

                  <p
                    className={`p-2 rounded-md overflow-x-hidden hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      switchAgency('Brive', '6533fe25099978decf532a59')
                    }}
                  >
                    Brive
                  </p>

                  <p
                    className={`p-2 rounded-md overflow-x-hidden hover:cursor-pointer flex items-center space-x-2`}
                    onClick={() => {
                      switchAgency('Toulouse', '654a3d82874a059df3f98653')
                    }}
                  >
                    Toulouse
                  </p>
                </div>
              </div>

              <p
                className={`${
                  selected === 'availableCandidat' ? 'bg-[#EBE9FE]' : ''
                } p-2 rounded-md hover:cursor-pointer flex items-center space-x-2`}
                onClick={() => {
                  logout()
                }}
              >
                Deconnexion
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Menu3
