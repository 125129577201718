import { Dialog } from '@headlessui/react'
import CameraEnhanceIcon from '@mui/icons-material/CameraEnhance'
import AxiosInstance from '../../../axios/axiosInstance'
import avatar from '../../../assets/img/avatar.jpg'
import Compressor from 'compressorjs'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckIcon from '@mui/icons-material/Check'
import { Icon } from '@iconify/react'
import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
const OrderCandidatModal = ({ candidate, setSelectedCandidate }) => {
  const inputFileRef = useRef(null)
  const [userImg, setUserImg] = useState('')
  const [editName, setEditName] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (candidate) {
      AxiosInstance.get(`tempworker-candidate/${candidate._id}/image`)
        .then((res) => {
          setUserImg(res.data.image)
        })
        .catch((e) => console.log(e))
    }
    setFirstname(candidate?.firstname)
    setLastname(candidate?.lastname)
  }, [candidate])

  const updateName = () => {
    console.log(firstname, lastname)
    AxiosInstance.post(`tempworker-candidate/${candidate._id}/update`, {
      firstname,
      lastname,
    })
      .then(() => {
        message.success('Modification enregistrées ! 😏')
        setEditName(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const handleFileChange = (event) => {
    event.preventDefault()
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = (e) => {
      setUserImg(e.target.result.split(',')[1])
    }
    reader.readAsDataURL(file)
    new Compressor(file, {
      quality: 0.6,
      width: 400,

      success(result) {
        const formData = new FormData()

        formData.append('file', result)

        AxiosInstance.put(
          `tempworker-candidate/${candidate._id}/image`,
          formData,
        )
          .then((res) => {
            message.success('Modifications enregistrées ! 😏')
          })
          .catch((error) => {
            console.error(error)
          })
      },
      error(err) {
        console.log(err.message)
      },
    })
  }

  return (
    <>
      <Dialog
        open={candidate !== null}
        onClose={() => setSelectedCandidate(null)}
        className="relative z-10"
      >
        <div className="fixed inset-0 z-10 overflow-y-scroll scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 flex items-center justify-center">
          <div className="flex w-full min-h-full bg-[rgba(63,67,66,0.39)] items-center justify-center p-4 sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform h-[95vh] w-full nav:w-4/5 mx-auto rounded-lg">
              <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse rounded-t-lg ">
                <Icon
                  icon={'ic:round-close'}
                  width={30}
                  onClick={() => setSelectedCandidate(null)}
                  className="hover:cursor-pointer"
                />
              </div>
              <div className="bg-gray-50 flex w-full h-[90vh] overflow-scroll lg:px-12 rounded-b-lg justify-between items-start">
                <div className="flex flex-col w-full nav:w-4/5 mx-auto">
                  <div className="flex flex-col items-center mb-16">
                    <div className="relative mb-4">
                      <img
                        src={
                          userImg ? `data:image/png;base64,${userImg}` : avatar
                        }
                        className="w-32 h-32 rounded-full"
                      />

                      <p className="absolute bottom-0 right-0">
                        <CameraEnhanceIcon
                          onClick={handleClick}
                          className="text-black text-3xl cursor-pointer"
                        />
                      </p>
                      <input
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                        className="hidden"
                        ref={inputFileRef}
                      />
                    </div>
                    <div className="w-full text-center ">
                      <div className="flex items-center justify-center">
                        {!editName && (
                          <div className="flex items-center justify-center space-x-2">
                            <p className="text-4xl font-light uppercase">
                              {lastname + ' ' + firstname}
                            </p>
                          </div>
                        )}

                        {editName && (
                          <div className="flex items-center justify-center space-x-4">
                            <p className="font-light uppercase flex items-center space-x-2">
                              <input
                                className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400 uppercase"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                              />
                              <input
                                className="grow max-h-12 text-base leading-none text-gray-600 font-light py-3 pl-5 focus:oultine-none border-2 rounded-md border-gray-200 placeholder-gray-400"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                              />
                              <button onClick={updateName}>
                                <CheckIcon className="rounded-md text-white text-4xl bg-mountain-500 border border-gray-400" />
                              </button>
                            </p>
                          </div>
                        )}
                        <EditOutlinedIcon
                          onClick={() => setEditName(!editName)}
                          className="text-3xl"
                        />
                      </div>

                      <p className="mt-1 text-sm">
                        Créé le{' '}
                        {new Date(candidate?.createdAt).toLocaleDateString(
                          'fr-FR',
                        )}{' '}
                        par{' '}
                        {`${candidate?.user?.firstname} ${candidate?.user?.lastname}`}
                      </p>
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-green-600 font-semibold">
                        Disponible
                      </p>
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-orange-400 font-semibold">
                        Indisponible
                      </p>
                      <p className="text-white rounded-md px-2 py-1 w-fit mx-auto mt-4 bg-black font-semibold">
                        Bloqué(e)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export default OrderCandidatModal
