import { useEffect, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import {
  Card,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Select,
  TimePicker,
  TreeSelect,
  message,
} from 'antd'
import facture from '../../assets/img/facture.png'
import placeholder from '../../assets/img/placeholder.png'
import phone from '../../assets/img/phone.png'
import email from '../../assets/img/email.png'
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { DoneAllOutlined } from '@mui/icons-material'
import xlsIcon from '../../assets/img/xls.png'
import CreateClientModal from './CreateClientModal'

import locale from 'antd/es/date-picker/locale/fr_FR'

import dayjs from 'dayjs'

import ClientDetailModal from './ClientDetailModal/ClientDetailModal'
import FilterClient from './FilterClient'

const ListClient = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [clients, setClients] = useState([])
  const [originalClients, setOriginalClients] = useState([])
  const [selectedClient, setSelectedClient] = useState(null)
  const [isOpenCreateContact, setIsOpenCreateContact] = useState(false)
  const [isOpenCreateTask, setIsOpenCreateTask] = useState(false)
  const [updateContactObject, setUpdateContactObject] = useState({})
  const [taskType, setTaskType] = useState('Taches')
  const [currentPage, setCurrentPage] = useState(1)
  const [quotations, setQuotations] = useState([])
  const [originalQuotations, setOriginalQuotations] = useState([])
  const [openFilterModal, setOpenFilterModal] = useState(false)
  const [date, setDate] = useState(null)

  const [time, setTime] = useState(null)
  const [comment, setComment] = useState('')
  const [place, setPlace] = useState('')
  const [tasks, setTasks] = useState([])
  const [isCreateFacture, setIsCreateFacture] = useState(false)
  const [pld, setPld] = useState('')
  const [documentCount, setDocumentCount] = useState(0)
  const [searchWord, setSearchWord] = useState('')

  const onChangePage = (page, pageSize) => {
    axiosInstance
      .get(`client/paginate/${page - 1}`)
      .then((res) => {
        setClients(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  /*   const loadBills = async () => {
    axiosInstance.get('tempworker-candidate/bills')
      .then((data) => {
        return data.data;
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  } */

  const resetTaskFields = () => {
    setComment('')
    setTaskType('Taches')
    setDate(null)
    setTime(null)
    setPlace('')
  }

  const createTask = () => {
    const data = {
      client: selectedClient._id,
      type: taskType,
      date,
      comment,
      place,
    }
    if (time) {
      data.hour = time.getHours() + ':' + time.getMinutes()
    }

    axiosInstance
      .post('task', data)
      .then((res) => {
        const newTasks = [res.data, ...tasks]
        setTasks(newTasks)
        message.success('Tache créée ! ')
        resetTaskFields()
        setIsOpenCreateTask(false)
      })
      .catch((e) => message.error("Une erreur s'est produite ! "))
  }

  const selectClient = (client) => {
    setSelectedClient(client)
  }

  const selectSearchWord = (word) => {
    if (word.length === 0) {
      setClients(originalClients)
    }
    setSearchWord(word)
  }

  const search = () => {
    axiosInstance
      .post('client/find-by-text', { query: searchWord })
      .then((res) => {
        setClients(res.data)
      })
      .catch((e) => {})
  }

  const changeTitle = (e) => {
    const newContact = { ...updateContactObject }
    newContact.title = e
    setUpdateContactObject(newContact)
  }

  const changeFirstname = (e) => {
    const newContact = { ...updateContactObject }
    newContact.firstname = e
    setUpdateContactObject(newContact)
  }

  const changeLastname = (e) => {
    const newContact = { ...updateContactObject }
    newContact.lastname = e
    setUpdateContactObject(newContact)
  }

  const changeEmail = (e) => {
    const newContact = { ...updateContactObject }
    newContact.email = e
    setUpdateContactObject(newContact)
  }

  const changePhone = (e) => {
    const newContact = { ...updateContactObject }
    newContact.phone = e
    setUpdateContactObject(newContact)
  }

  const changeMobilePhone = (e) => {
    const newContact = { ...updateContactObject }
    newContact.mobile = e
    setUpdateContactObject(newContact)
  }

  const changeProfession = (e) => {
    const newContact = { ...updateContactObject }
    newContact.profession = e
    setUpdateContactObject(newContact)
  }

  useEffect(() => {
    axiosInstance.get('client/count').then((res) => {
      setDocumentCount(res.data)
    })

    axiosInstance
      .get('client')
      .then(async (res) => {
        setClients(res.data)
        setOriginalClients(res.data)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <div className="">
      {isOpen && (
        <CreateClientModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          clients={clients}
          setClients={setClients}
        />
      )}

      {openFilterModal && <FilterClient setClients={setClients} originalClients={originalClients} openFilterModal={openFilterModal} setOpenFilterModal={setOpenFilterModal} />}

      {isOpenCreateTask && (
        <Modal
          footer={null}
          width={900}
          open={isOpenCreateTask}
          onCancel={() => {
            setIsOpenCreateTask(false)
            resetTaskFields()
          }}
        >
          <div className="flex flex-col justify-center space-y-2 mt-10">
            <Select
              className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
              optionFilterProp="children"
              placeholder="Taches"
              onChange={(e) => {
                setTaskType(e)
                setComment('')
                setDate(null)
                setTime(null)
                setPlace('')
              }}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  label: 'Taches',
                  value: 'Taches',
                  key: 'Taches',
                },
                {
                  label: 'Appel',
                  value: 'Appel',
                  key: 'Appel',
                },
                {
                  label: 'Deplacement',
                  value: 'Deplacement',
                  key: 'Deplacement',
                },
                {
                  label: 'Autre',
                  value: 'Autre',
                  key: 'Autre',
                },
              ]}
            />
            {taskType === 'Taches' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  onChange={(date, dateString) => setDate(date.$d)}
                  value={date ? dayjs(date) : null}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de la tache"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <Input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full h-40  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="commentaire"
                />
              </div>
            )}

            {taskType === 'Appel' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date de l'appel"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure de l'appel"
                />
                <Input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full h-40  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="commentaire"
                />
              </div>
            )}

            {taskType === 'Deplacement' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date du deplacement"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure du deplacement"
                />
                <Input
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  className="px-2 w-full   placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Lieu du deplacement"
                />
                <Input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full h-40  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="commentaire"
                />
              </div>
            )}

            {taskType === 'Autre' && (
              <div className="flex flex-col space-y-1">
                <DatePicker
                  value={date ? dayjs(date) : null}
                  onChange={(date, dateString) => setDate(date.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Date"
                  locale={locale}
                  format={'DD/MM/YYYY'}
                />
                <TimePicker
                  format={'HH:mm'}
                  value={time ? dayjs(time) : null}
                  onChange={(time, timeString) => setTime(time.$d)}
                  className="px-2 w-full  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Heure"
                />

                <Input
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="px-2 w-full h-40  placeholder-gray-300 py-1 outline-none text-black border rounded-md border-gray-200 "
                  placeholder="Commentaire"
                />
              </div>
            )}

            <button
              onClick={createTask}
              className="rounded-md p-2 bg-mountain-500 w-2/4 mx-auto text-white mt-10 uppercase font-semibold"
            >
              Créér la tache
            </button>
          </div>
        </Modal>
      )}

      <div className="flex flex-col  mb-4">
        <div className="text-left flex space-x-1 mb-6 items-center w-full justify-end pr-10 mt-6">
          <div className="flex items-center justify-center space-x-2">
            <Input
              className={`w-80 rounded-md border border-gray-200 focus:outline-none`}
              placeholder="Recherche"
              style={{ outline: 'none' }}
              onChange={(e) => selectSearchWord(e.target.value)}
            />
            <button
              onClick={() => search()}
              className="bg-green-500 text-white p-2 rounded-md"
            >
              Rechercher
            </button>
          </div>
                  <FilterListOutlinedIcon 
                  onClick={()=>setOpenFilterModal(true)}
                   className="w-10 h-10 text-gray-400" />
          <img src={xlsIcon} className="w-10 h-10 mx-2" />
          <DoneAllOutlined className="w-10 h-10 text-gray-400" />
          <AddCircleOutlineIcon
            onClick={() => setIsOpen(true)}
            className="w-10 h-10 text-gray-400"
          />
        </div>

        {clients.length > 0 && (
          <div>
            {/*  <div className="flex items-center justify-between  mb-6 mx-20 text-base">
                <p className="w-1/4">NOM</p>
                <p className="w-1/4">ADRESSE</p>
                <p className="w-1/4">TELEPHONE</p>
                <p className="w-1/4">EMAIL</p>
              </div> */}
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2">
              {clients.map((client, idx) => (
                <Card
                  key={idx}
                  onClick={() => {
                    selectClient(client)
                  }}
                  className="border p-0 text-gray-800 bg-gray-50 border-gray-400 hover:scale-101 hover:cursor-pointer transition-all duration-200"
                >
                  <div>
                    <p className="font-bold text-lg text-">
                      {client.companyName}{' '}
                    </p>
                  </div>

                  <div className="flex items-start space-x-2">
                    <div className="flex flex-col items-start my-2">
                      <p className=" text-sm flex items-center space-x-3">
                        <img className="w-6" src={placeholder} />{' '}
                        <span>{client.address}</span>{' '}
                      </p>
                      <p className="ml-[36px]">
                        {' '}
                        {client.postCode} {client.town}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center space-x-2 mb-2">
                    <p className=" text-sm flex items-center ">
                      <img className="w-4" src={phone} />{' '}
                      <span className="ml-[22px]">{client.contactPhone}</span>{' '}
                    </p>
                  </div>

                  <div className=" flex items-center space-x-2">
                    <p className=" text-sm flex items-center ">
                      <img className="w-4" src={email} />{' '}
                      <span className="ml-[22px]">{client.contactEMail}</span>{' '}
                    </p>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        )}
      </div>

      {selectedClient && (
        <ClientDetailModal
          client={selectedClient}
          originalClients={originalClients}
          setClient={setSelectedClient}
          clients={clients}
          setClients={setClients}
        />
      )}

      {/* {selectedClient && (
        <ClientModal
          setSelectedClient={setSelectedClient}
          selectedClient={selectedClient}
        />
      )} */}

      <div className="text-right">
        <Pagination
          className="mt-6 w-full"
          total={documentCount}
          current={currentPage}
          onChange={onChangePage}
          pageSize={20}
        />
      </div>
    </div>
  )
}

export default ListClient
