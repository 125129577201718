import { Drawer, Select, Input } from 'antd'
import axios from '../../axios/axiosInstance'
import { useState } from 'react'
const FilterFacture = ({
  open,
  onClose,
  setIsReset,
  setBills,
  originalBills,
}) => {
  const [status, setStatus] = useState('')
  const [selectedCandidate, setSelectedCandidate] = useState(null)
  const [selectedClient, setSelectedClient] = useState(null)
  const [candidates, setCandidates] = useState([])
  const [client, setClient] = useState(null)
  const [clients, setClients] = useState([])
  const [timer, setTimer] = useState(null)

  const reset = () => {
    setBills(originalBills)
    onClose()
    setIsReset(true)
  }

  const filterBills = () => {
    const payload = {}

    // Check if selectedCandidate is defined, and add it to the payload if true
    if (selectedCandidate) {
      payload.candidate = selectedCandidate._id
    }

    // Check if selectedClient is defined, and add it to the payload if true
    if (selectedClient) {
      payload.client = selectedClient._id
    }

    // Check if selectedStatus is defined, and add it to the payload if true
    if (status.length) {
      payload.status = status
    }

    axios
      .post('bill/filter', payload)
      .then((res) => {
        setBills(res.data)
        onClose()
        setIsReset(false)
      })
      .catch((e) => console.log(e))
  }

  const searchCandidate = (word) => {
    if (word.length === 0) {
      setCandidates([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axios
          .post('tempworker-candidate/find-by-text', { query: word })
          .then((res) => {
            setCandidates(res.data)
            console.log(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  const searchClient = (word) => {
    if (word.length === 0) {
      setClients([])
    }
    if (word.length < 3) {
      if (timer) clearTimeout(timer)
    }
    if (word.length >= 3) {
      if (timer) clearTimeout(timer)

      const timer2 = setTimeout(() => {
        axios
          .post('client/find-by-text', { query: word })
          .then((res) => {
            setClients(res.data)
            console.log(res.data)
          })
          .catch((e) => console.log(e))
      }, 700)
      setTimer(timer2)
    }
  }

  return (
    <Drawer open={open} onClose={onClose}>
      <div className="flex flex-col space-y-2">
        <Input
          className="w-60 rounded-md border border-gray-300"
          placeholder="Candidat"
          onChange={(e) => searchCandidate(e.target.value)}
        />
        {candidates.length > 0 && (
          <div className="h-40 w-60 overflow-scroll flex flex-col space-y-1">
            {candidates.map((candidate) => {
              return (
                <div
                  className={`${
                    selectedCandidate?._id === candidate._id
                      ? 'bg-green-500'
                      : ''
                  }`}
                  onClick={() => setSelectedCandidate(candidate)}
                >
                  <p>
                    {candidate.firstname} {candidate.lastname}
                  </p>
                </div>
              )
            })}
          </div>
        )}

        <Input
          className="w-60 rounded-md border border-gray-300"
          placeholder="Client"
          onChange={(e) => searchClient(e.target.value)}
        />
        {clients.length > 0 && (
          <div className="h-40 w-60 overflow-scroll flex flex-col space-y-1">
            {clients.map((client) => {
              return (
                <div
                  className={`${
                    selectedClient?._id === client._id ? 'bg-green-500' : ''
                  }`}
                  onClick={() => setSelectedClient(client)}
                >
                  <p>{client.companyName}</p>
                </div>
              )
            })}
          </div>
        )}

        <Select
          className="w-60"
          showSearch
          onChange={(e) => setStatus(e)}
          optionFilterProp="children"
          placeholder="Statut"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              label: 'A facturer',
              value: 'A facturer',
              key: 'A facturer',
            },
            {
              label: 'En attente',
              value: 'En attente',
              key: 'En attente',
            },
            { label: 'Facturée', value: 'Facturée', key: 'Facturée' },
            { label: 'Annulée', value: 'Annulée', key: 'Annulée' },
          ]}
        ></Select>

        <button
          onClick={filterBills}
          className="bg-black text-white p-2 w-60 rounded-md"
        >
          Filtrer
        </button>
        <button
          onClick={reset}
          className="bg-black text-white p-2 w-60 rounded-md"
        >
          Reinitialiser
        </button>
      </div>
    </Drawer>
  )
}

export default FilterFacture
