import { Button, Input } from 'antd'
import Header from '../components/Header/Header'
import { useContext, useState } from 'react'
import AxiosInstance from '../axios/axiosInstance'
import { message } from 'antd'
import { AuthContext } from '../context/authContext'
import { Link, useNavigate } from 'react-router-dom'
import { Icon } from '@iconify/react'
import logo from '../assets/img/logo.png'

const ProfilPage = () => {
  const [username, setUsername] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext)

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userRole')
    navigate('/login')
  }

  const resetFields = () => {
    setUsername('')
    setFirstname('')
    setLastname('')
    setEmail('')
    setPhone('')
    setPassword('')
  }
  const createUser = () => {
    AxiosInstance.post('auth/admin', {
      username,
      firstname,
      lastname,
      email,
      password,
      phone,
    })
      .then((res) => {
        message.success('Utilisateur crée')
        resetFields()
      })
      .catch((e) => {
        let errorMessage = ''
        try {
          errorMessage = e.response.data.message.join(' - ')
        } catch (e) {
          errorMessage = 'erreur'
        }
        message.error(errorMessage)
      })
  }

  return (
    <div>
      <div
        style={{ zIndex: 10 }}
        className="mx-auto fixed z-20 top-0 bg-transparent  w-full px-4 "
      >
        <div className="flex   justify-between">
          <div className="flex flex-1   p-2 justify-between">
            <Link to="/">
              <img className="w-12 h-12" src={logo} />
            </Link>
            <div className="flex flex-shrink-0 items-end">
              <span
                //   onClick={logoButton}
                className="cursor-pointer text-blue-700 self-center text-3xl font-semibold whitespace-nowrap "
              ></span>
            </div>
            {/* list nav */}

            <div className=" flex space-x-4 items-end">
              {/* logout button */}
              <div
                onClick={logout}
                className="flex flex-col  items-center text-black hover:text-black cursor-pointer"
              >
                <button>
                  <Icon icon={'material-symbols:power-rounded'} width={20} />
                </button>
                <p className="hidden md:block uppercase font-normal text-sm">
                  deconnexion
                </p>
              </div>

              {/* user picture */}
              {/*    <div className="w-14 h-14 flex bg-mountain-500 shadow-none rounded-full">
                  <p className="uppercase w-full text-2xl text-titan-white-50 text-center my-auto" />
                </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col mt-32  items-center justify-center">
        <p className="text-3xl font-semibold mb-8">Créer un utilisateur</p>
        <div className="w-2/5">
          <Input
            value={username}
            type="text"
            onChange={(e) => setUsername(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Username"
          />
          <Input
            value={firstname}
            type="text"
            onChange={(e) => setFirstname(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Nom"
          />
          <Input
            value={lastname}
            type="text"
            onChange={(e) => setLastname(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Prénom"
          />
          <Input
            value={email}
            type="text"
            onChange={(e) => setEmail(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Email"
          />
          <Input
            value={phone}
            type="text"
            onChange={(e) => setPhone(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Téléphone"
          />
          <Input
            value={password}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            className="grow mb-4 max-h-12 text-base leading-none text-gray-600 font-light py-2 pl-3 focus:oultine-none border rounded-md border-[#00000040] placeholder-[#00000040]"
            placeholder="Mot de passe"
          />
          <button
            onClick={createUser}
            className="bg-mountain-500 text-white  font-semibold p-3 rounded-md"
          >
            Creer utilisateur
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProfilPage
