import { Button, Input, Modal, Popover, Select, message } from 'antd'
import { useRef, useState, useEffect } from 'react'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import poubelle from '../../assets/img/poubelle.gif'
import download from '../../assets/img/download.gif'
import visualize from '../../assets/img/visualize.gif'
import traiter from '../../assets/img/traiter.gif'
import {
  AddCircleOutlineOutlined,
  CheckCircleOutline,
  TroubleshootSharp,
} from '@mui/icons-material'
import { Document, Page } from 'react-pdf'
import { useNavigate } from 'react-router-dom'
import axios from '../../axios/axiosInstance'
import { Checkbox, CircularProgress } from '@mui/material'
import { FilePdfOutlined } from '@ant-design/icons'

const SaisieArret = () => {
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [showDocument, setShowDocument] = useState(false)
  const [documentType, setDocumentType] = useState('')
  const [documentData, setDocumentData] = useState('')
  const [selectedFile, setSelectedFile] = useState(null)
  const [numPages, setNumPages] = useState([])
  const [timer, setTimer] = useState(null)
  const [saisies, setSaisies] = useState([])
  const [originalSaisies, setOriginalSaisies] = useState([])
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [searchWord, setSearchWord] = useState('')
  const [selectedSaisie, setSelectedSaisie] = useState(null)
  const [isTraiterSaisie, setIsTraiterSaisie] = useState(false)
  const [isNotInMission, setIsNotInMission] = useState(false)
  const inputFileRef = useRef(null)

  const handleClick = () => {
    inputFileRef.current.click()
  }

  useEffect(() => {
    loadSaisies()
  }, [])

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }

  const updateStatus = () => {
    let formData = new FormData()
    let url = `saisie/${selectedSaisie._id}/status`
    if (isNotInMission) {
      formData.append('file', selectedFile)
      formData.append('status', 'Traitée')
      formData.append('aRetenir', 'Cloturée')
      url = `saisie/${selectedSaisie._id}/status-file`
    } else {
      formData = { 'status': 'Traitée','aRetenir':"En cours" }
    }
    axios
      .post(url, formData)
      .then((res) => {
        const newSaisies = [...saisies]
        const idx = newSaisies.findIndex(
          (saisie) => saisie._id === res.data._id,
        )
        newSaisies[idx] = res.data
        setSaisies(newSaisies)
        console.log(res.data)
        console.log(formData)
        message.success(' statut mis a jour !')
        setSelectedSaisie(null)
        setIsTraiterSaisie(false)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
        console.log(e)
      })
  }

  const traiterSaisie = (saisie) => {
    setSelectedSaisie(saisie)
    setIsTraiterSaisie(true)
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0])
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const base64ToBlob = (base64, type = 'application/pdf') => {
    const binStr = window.atob(base64)
    const len = binStr.length
    const arr = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    console.log(type)
    const blob = new Blob([arr], { type: type })
    return URL.createObjectURL(blob)
  }

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const search = () => {
    if (searchWord.length === 0) {
      setSaisies(originalSaisies)
    }
    if (searchWord.length < 3) {
      return
    }
    if (searchWord.length >= 3) {
      axios
        .post('saisie/find-by-text', { query: searchWord })
        .then((res) => {
          setSaisies(res.data)
        })
        .catch((e) => {})
    }
  }

  const deleteSaisie = (saisieId) => {
    console.log(saisieId)
    axios
      .delete(`saisie/${saisieId}`)
      .then((res) => {
        console.log(res)
        const saisieIdx = saisies.findIndex(
          (saisie) => res.data._id === saisie._id,
        )
        const newsaisies = [...saisies]
        newsaisies.splice(saisieIdx, 1)
        console.log(newsaisies)
        setSaisies(newsaisies)
        message.success('Saisie supprimée !')
      })
      .catch((e) => {
        message.error("Vous n'etes pas autorisé a supprimer !")
      })
  }

  const loadSaisies = () => {
    axios
      .get('saisie')
      .then((res) => {
        setSaisies(res.data)
        setOriginalSaisies(res.data)
      })
      .catch((e) => console.log(e))
  }

  const getDocument = (documentId) => {
    setIsLoading(true)
    axios
      .get(`/saisie/${documentId}`)
      .then((res) => {
        //setDocumentType(res.data.type)
        //setDocumentData(res.data.data)
        console.log(res.data.type)
        let pdfUrl
        if (res.data.type === 'pdf') pdfUrl = base64ToBlob(res.data.data)
        else pdfUrl = base64ToBlob(res.data.data, 'image/png')

        window.open(pdfUrl, '_blank')
        //setShowDocument(true)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const voirPreuve = (documentId) => {
    setIsLoading(true)
    axios
      .get(`/saisie/${documentId}`)
      .then((res) => {
        //setDocumentType(res.data.type)
        //setDocumentData(res.data.data)
        let pdfUrl
        if (res.data.proofType === 'pdf')
          pdfUrl = base64ToBlob(res.data.proofData)
        else pdfUrl = base64ToBlob(res.data.proofData, 'image/png')

        window.open(pdfUrl, '_blank')
        //setShowDocument(true)
      })
      .catch((e) => {
        message.error("Une erreur s'est produite")
        console.log(e)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <div>
      {isTraiterSaisie && (
        <Modal
          open={isTraiterSaisie}
          footer={null}
          onCancel={() => {
            setIsTraiterSaisie(false)
            setSelectedFile(null)
            setIsNotInMission(false)
          }}
        >
          <div className="flex flex-col items-center justify-center space-y-4">
            <div className="mt-6">
              <p className="font-semibold text-lg">
                L'intérimaire est-il en mission ?
              </p>

              <div className="flex items-center my-6 space-x-10 justify-center">
                <p className="flex items-center space-x-1">
                  <Checkbox
                    checked={!isNotInMission}
                    onChange={() => setIsNotInMission(false)}
                  />
                  <span>OUI</span>
                </p>

                <p className="flex items-center space-x-1">
                  <Checkbox
                    checked={isNotInMission}
                    onChange={() => setIsNotInMission(true)}
                  />
                  <span>NON</span>
                </p>
              </div>
            </div>
            {isNotInMission && (
              <div
                onClick={handleClick}
                className="border w-96 border-[#00000040]  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
              >
                <CloudUploadIcon />
                <p className="mt-2">Ajouter email envoyé à l'organisme</p>
                <p className="text-black">{selectedFile?.name}</p>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="hidden"
                  ref={inputFileRef}
                />
              </div>
            )}

            <button
              className="rounded-md bg-purple-500 text-white p-2 "
              onClick={updateStatus}
            >
              Traiter
            </button>
          </div>
        </Modal>
      )}
      {isLoading && (
        <CircularProgress
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        />
      )}
      <Modal
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        footer={[
          <Button key="back" onClick={() => setShowDocument(false)}>
            fermer
          </Button>,
        ]}
        width={1200}
        title="Document"
        open={showDocument}
        onCancel={() => setShowDocument(false)}
      >
        <div>
          {documentType === 'pdf' && (
            <Document
              file={`data:application/pdf;base64,${documentData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={2 / 5} key={idx} pageNumber={idx + 1} />
              })}
            </Document>
          )}

          {documentType !== 'pdf' && (
            <img
              className="w-[600px]"
              src={`data:image/png;base64,${documentData}`}
            />
          )}
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setIsOpenModal(false)}
        open={isOpenModal}
      >
        <Select
          className=" mt-10 h-8 px-2 py-1  text-gray-600 font-light rounded-md placeholder-gray-400 w-96 "
          showSearch
          placeholder=""
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={[
            {
              value: 'Arret maladie',
              label: 'Arret maladie',
              key: 'Arret maladie',
            },
            {
              value: 'Accident de travail',
              label: 'Accident de travail',
              key: 'Accident de travail',
            },
            {
              value: 'Maladie professionnelle',
              label: 'Maladie professionnelle',
              key: 'Maladie professionnelle',
            },
            {
              value: 'Congé maternité',
              label: 'Congé maternité',
              key: 'Congé maternité',
            },
            {
              value: 'Congé paternité',
              label: 'Congé paternité',
              key: 'Congé paternité',
            },
            {
              value: 'Autres absences',
              label: 'Autres absences (à preciser)',
              key: 'Autres absences',
            },
          ]}
        />
      </Modal>
      <div className="flex justify-start flex-col ml-10 mb-10 mt-10">
        <div className="flex items-center space-x-2 h-10">
          <p className="text-xl text-gray-400">Accueil / Gestion / Saisie</p>
        </div>

        <div className="flex items-center space-x-2">
          <Input
            ref={searchInputRef}
            onChange={(e) => setSearchWord(e.target.value)}
            className={`w-80 rounded-md duration-500 border border-gray-200 focus:outline-none`}
            placeholder="Recherche"
            style={{ outline: 'none' }}
          />
          <button
            onClick={() => search()}
            className="bg-green-500 text-white p-2 rounded-md"
          >
            Rechercher
          </button>
        </div>
      </div>

      <div className="flex items-center font-semibold w-fit my-5  border rounded-lg border-gray-300 bg-[#F7F9FC]  space-x-2">
        <p
          onClick={() => setSaisies(originalSaisies)}
          className="border-r p-2 border-r-gray-300"
        >
          Toutes
        </p>
        <p
          onClick={() =>
            setSaisies(
              originalSaisies.filter((saisie) => saisie.status === 'Traitée'),
            )
          }
          className="border-r p-2 border-r-gray-300"
        >
          Traitées
        </p>
        <p
          onClick={() =>
            setSaisies(
              originalSaisies.filter((saisie) => saisie.status === 'A traiter'),
            )
          }
          className="p-2"
        >
          Non Traitées
        </p>
      </div>

      <div className="border border-gray-300 rounded-md">
        <div className="flex items-center justify-between  text-md py-3">
          <p className="w-1/6 text-center">DATE ENREGISTREMENT</p>
          <p className="w-1/6 text-center">INTERIMAIRE</p>
          <p className="w-1/6 text-center">MONTANT</p>
          <p className="w-1/6 text-center">STATUT</p>
          <p className="w-1/6 text-center">A RETENIR</p>
          <p className="w-1/6 text-center">ACTION MENEE</p>
        </div>
      </div>
      <div className="flex flex-col mt-2 bg-white space-y-2">
        {saisies.map((saisie, idx) => {
          return (
            <div
              className="flex shadow-xl  items-center drop-shadow-2xl text-xs  justify-between  bg-white border  border-gray-200 rounded-md p-4 hover:cursor-pointer"
              key={idx}
            >
              <p className="w-1/6 text-center text-lg capitalize">
                {formatJsDate(saisie.createdAt)}
              </p>

              <p className="w-1/6 text-center text-lg capitalize">
                {saisie.candidateName.toLowerCase()}
              </p>

              <p className="w-1/6 text-center text-lg capitalize">
                {saisie.amount}
              </p>

              <p className="w-1/6 text-center text-lg capitalize">
                {saisie.status}
              </p>

              <p className="w-1/6 text-center text-lg capitalize">
                {saisie.aRetenir}
              </p>

              <div className="w-1/6 flex items-center justify-center space-x-2">
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-base space-y-3">
                      <p
                        onClick={() => getDocument(saisie._id)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <img src={visualize} className="w-8" />
                        <span>Visualiser</span>
                      </p>

                      {saisie.status !== 'Traitée' && (
                        <p
                          onClick={() => traiterSaisie(saisie)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img src={traiter} className="w-8" />
                          <span>Traiter</span>
                        </p>
                      )}

                      {saisie.proofType && (
                        <p
                          onClick={() => voirPreuve(saisie._id)}
                          className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                        >
                          <img src={visualize} className="w-8" />
                          <span>Voir preuve</span>
                        </p>
                      )}

                      <p
                        onClick={() => deleteSaisie(saisie._id)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <img src={poubelle} className="w-8" />
                        <span>Supprimer</span>
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <Button>...</Button>
                </Popover>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SaisieArret
