import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const InactivityLogout = () => {
  const navigate = useNavigate()

  useEffect(() => {
    let inactivityTimeout
    const expirationKey = 'expirationTime'

    const resetTimeout = () => {
      clearTimeout(inactivityTimeout)
      let expirationTime = 0
      let expiration = localStorage.getItem(expirationKey)
      if (expiration) {
        expirationTime = parseInt(expiration)
        const remainingTime = expirationTime - Date.now()
        if (remainingTime > 0) {
          localStorage.setItem(
            expirationKey,
            (Date.now() + 60 * 60 * 1000).toString(),
          )
        } else {
          logout()
        }
      } else {
        expirationTime = (Date.now() + 60 * 60 * 1000).toString()
        localStorage.setItem(expirationKey, expirationTime)
        inactivityTimeout = setTimeout(logout, expirationTime)
      }
    }

    const logout = () => {
      localStorage.clear()
      console.log('Logged out due to inactivity')
      navigate('login')
    }

    resetTimeout()

    window.addEventListener('mousemove', resetTimeout)
    window.addEventListener('keydown', resetTimeout)
    window.addEventListener('click', resetTimeout)

    return () => {
      clearTimeout(inactivityTimeout)
      window.removeEventListener('mousemove', resetTimeout)
      window.removeEventListener('keydown', resetTimeout)
      window.removeEventListener('click', resetTimeout)
    }
  }, [navigate])

  return null // No JSX content needed for this component
}

export default InactivityLogout
