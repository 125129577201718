import { Dialog, Transition } from '@headlessui/react'
import { useState } from 'react'
import { Button, Drawer, Modal, TreeSelect } from 'antd'
import { Input } from 'antd'
import { DatePicker, Space } from 'antd'
import locale from 'antd/es/date-picker/locale/fr_FR'
import AxiosInstance from '../../axios/axiosInstance'

const { RangePicker } = DatePicker

const FilterClient = ({
  openFilterModal,
  setOpenFilterModal,
  setClients,
  originalClients
}) => {


 


  const [commercial,setCommercial] = useState('');

  const getFilteredClients = () => {
    AxiosInstance(`client/commercial/${commercial}`)
    .then((res)=> {
        console.log(res.data);
        setClients(res.data);
        setOpenFilterModal(false);
    })
    .catch(e=> console.log(e));
  }
  

  const reset = () => {
    setCommercial('');
    setClients(originalClients);
    setOpenFilterModal(false);
  }

  return (
    <Drawer
      title="Filtrer Candidat"
      open={openFilterModal}
      onClose={() => setOpenFilterModal(false)}
    >
      <div className=" flex h-full flex-col">
        <div className="grow flex flex-col justify-between">
          <div className="flex items-center flex-col hover:cursor-pointer px-10">
            <div className="w-full flex flex-col items-start space-y-2">
            <input type='text' onChange={(e)=> setCommercial(e.target.value)} />
            </div>
            <div>
              <p className="flex items-center space-x-2 justify-center mt-6">
                <button
                  className="text-white rounded-md p-2 m-2 text-xs  bg-green-500"
                  onClick={getFilteredClients}
                >
                  VALIDER
                </button>
                <button
                  className="text-white rounded-md p-2 m-2 text-xs bg-black"
                  onClick={reset}
                >
                  REINITIALISER
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default FilterClient
