import {
  AppstoreOutlined,
  UserOutlined,
  SettingOutlined,
  ShopOutlined,
} from '@ant-design/icons'
import logo from '../../assets/img/logo.png'
import { AuthContext } from '../../context/authContext'
import { Menu, Spin, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useContext, useEffect, useState } from 'react'
import dashboard from '../../assets/img/icon-dashboard.png'
import star from '../../assets/img/star.png'
import AxiosInstance from '../../axios/axiosInstance'
import { DocumentScannerOutlined } from '@mui/icons-material'
import { LoadingOutlined } from '@ant-design/icons'

const Menu2 = ({ toggle, toggleDashboard }) => {
  const { setIsLoggedIn } = useContext(AuthContext)
  const navigate = useNavigate()
  const [isReloaded, setIsReloaded] = useState(['a'])
  const [agencyName, setAgencyName] = useState('')

  const agencies = [
    {
      id: '650adee7951dd697c7535e3e',
      name: 'Asnieres',
    },
    {
      id: '6533fe25099978decf532a59',
      name: 'Brive',
    },
    {
      id: '654a3d82874a059df3f98653',
      name: 'Toulouse',
    },
  ]

  const switchAgency = (agencyName, agencyId) => {
    AxiosInstance.post('agency/switch', { agency: agencyId })
      .then((res) => {
        setIsReloaded([...isReloaded])
        setAgencyName(agencyName)
        toggle('dashboard')
        toggleDashboard()
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          message.error("Vous n'etes pas autorisé à changer d'agence !")
        }
      })
  }

  useEffect(() => {
    getAgency()
  }, isReloaded)

  const getAgency = () => {
    const agencyId = localStorage.getItem('agency')
    const agency = agencies.find((ag) => ag.id === agencyId)
    setAgencyName(agency.name)
  }

  const click = (item) => {
    toggle(item.key)
  }

  const logout = () => {
    setIsLoggedIn(false)
    localStorage.removeItem('accessToken')
    localStorage.removeItem('isLogged')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userEmail')
    localStorage.removeItem('userRole')
    navigate('/login')
  }

  function getItem(label, key, icon, onClick, children) {
    return {
      key,
      icon,
      children,
      onClick,
      label,
    }
  }
  const items = [
    getItem(
      'Dashboard',
      'dashboard',
      <img className="w-6" src={dashboard} />,
      click,
    ),
    getItem('Gestion', 'gestion', <SettingOutlined />, null, [
      getItem(
        'Tâches du jour',
        'taches',
        <img className="w-5 rounded-full" src={star} />,
        click,
      ),
      getItem('Absences', 'maladie', null, click),
      getItem('Annonces', 'annonces', null, click),
      getItem('Atradius', 'atradius', null, click),
      getItem('Acomptes', 'acompte', null, click),
      getItem('Commande', 'order', null, click),
      getItem('Contrat freelance', 'DemandeContrat', null, click),
      getItem('Controle de reference', 'controleReference', null, click),
      getItem('Factures à faire', 'facture-manuelle', null, click),
      getItem('Fiche renseignement', 'renseignement', null, click),
      getItem('Prolongations', 'prolongations', null, click),
      getItem("Relevés d'heures", 'rh', null, click),
      getItem('Saisie arret', 'saisie', null, click),
      getItem('Validation de documents', 'WaitingDocument', null, click),
      getItem('Visite médicale', 'visite', null, click),
    ]),
    getItem('Candidats', 'candidats', <UserOutlined />, null, [
      getItem('Rechercher un candidat', 'searchCandidat', null, click),
      getItem('Candidats disponibles', 'availableCandidat', null, click),
    ]),
    getItem('Interimaires', 'interimaires', <UserOutlined />, null, [
      getItem('Planning', 'planning', null, click),
      getItem('Contrat', 'candidatContract', null, click),
      getItem('Fiche de paie', 'pay', null, click),
      getItem('Attestation pole emploi', 'attestation', null, click),
      getItem('Certificat de travail', 'certificatTravail', null, click),
      getItem('Attestation fiscale', 'fiscal', null, click),
    ]),
    getItem('Clients', 'sub4', <ShopOutlined />, null, [
      getItem('Rechercher un client', 'searchClient', null, click),
      getItem('Devis', 'devis', null, click),
      getItem('Contrat', 'clientContract', null, click),
      getItem('Factures', 'facture', null, click),
      getItem('Registre du personnel', 'registre', null, click),
    ]),
    getItem('Bibliothèque', 'documents', <DocumentScannerOutlined />, click),
    getItem(
      'Mon espace personnel',
      'perso',
      <DocumentScannerOutlined />,
      click,
    ),
    getItem('Paramètres', 'parametres', <SettingOutlined />, null, [
      getItem('Utilisateurs', 'utilisateurs', null, click),
      getItem('Annuaire', 'annuaire', null, click),
      getItem('Switcher agence', 'switch', null, null, [
        getItem('Agence Asnieres', 'Asnieres', null, () =>
          switchAgency('Asnieres', '650adee7951dd697c7535e3e'),
        ),
        getItem('Agence Brive', 'Brive', null, () =>
          switchAgency('Brive', '6533fe25099978decf532a59'),
        ),
        getItem('Agence Toulouse', 'Toulouse', null, () =>
          switchAgency('Toulouse', '654a3d82874a059df3f98653'),
        ),
      ]),

      getItem('Deconnexion', 'deconnexion', null, logout),
    ]),

    ,
  ]

  return (
    <>
      {/* <Spin spinning={true} fullscreen indicator={<LoadingOutlined />} size='large' /> */}
      <div className="text-base font-Inter bg-[#4875ce] bg-opacity-100  flex flex-col  overflow-y-auto text-black fixed top-0 left-0 h-full">
        <img className="w-24 mx-auto mt-4 mb-8" src={logo} />
        <p className="text-center text-white -mt-6 mb-6 text-lg italic">
          {agencyName}
        </p>

        <Menu
          className="grow flex flex-col space-y-6 pb-10 font-Inter text-lg bg-white text-black"
          style={{
            width: 256,
            backgroundColor: '#4875ce',
          }}
          mode="inline"
          items={items}
          defaultOpenKeys={['sub3']}
        />
      </div>
    </>
  )
}
export default Menu2
