import {
  Button,
  Drawer,
  Input,
  Modal,
  Popover,
  Select,
  Spin,
  Table,
  message,
} from 'antd'
import Column from 'antd/es/table/Column'
import { PlagiarismOutlined } from '@mui/icons-material'
import { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../axios/axiosInstance'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { DeleteFilled, EditFilled } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import userEvent from '@testing-library/user-event'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export default function ClientDocuments({ client }) {
  const [openAddModal, setOpenAddModal] = useState(false)
  const [clientDocuments, setClientDocuments] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const inputFileRef = useRef(null)
  const [documentData, setDocumentData] = useState('')
  const [documentType, setDocumentType] = useState('')
  const [showDocument, setShowDocument] = useState(false)
  const [numPages, setNumPages] = useState([])
  const [loadingDocument, setLoadingDocument] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState(null)
  const [isDrawer, setIsDrawer] = useState(false)
  const [documentToUploadType, setDocumentToUploadType] = useState('')
  const [otherDocumentType, setOtherDocumentType] = useState('')

  const navigate = useNavigate()
  useEffect(() => {
    loadDocuments()
  }, [])

  const loadDocuments = () => {
    axiosInstance
      .get(`/client-document/client/${client._id}`)
      .then((res) => {
        setClientDocuments(res.data)
        console.log(res.data)
      })
      .catch((e) => message.error("Une erreur s'est produite!"))
  }
  const handleClick = () => {
    inputFileRef.current.click()
  }

  const handleFileChange = (event) => {
    console.log(event.target.files[0])
    setSelectedFile(event.target.files[0])
  }

  const deleteDocument = (documentId) => {
    setLoadingDocument(true)
    axiosInstance
      .delete(`/client-document/${documentId}`)
      .then((res) => {
        const documents = clientDocuments.filter(
          (doc) => doc._id !== documentId,
        )
        setClientDocuments(documents)
        message.success('Document supprimé!')
        setLoadingDocument(false)
      })
      .catch((e) => message.error("Une erreur s'est produite"))
  }

  const getDocument = (documentId) => {
    axiosInstance
      .get(`/client-document/${documentId}`)
      .then((res) => {
        let pdfUrl
        if (res.data.name.split('.')[1] === 'pdf')
          pdfUrl = base64ToBlob(res.data.data)
        else pdfUrl = base64ToBlob(res.data.data, 'image/png')

        window.open(pdfUrl, '_blank')
      })
      .catch((e) => message.error("Une erreur s'est produite"))
  }

  const base64ToBlob = (base64, type = 'application/pdf') => {
    const binStr = window.atob(base64)
    const len = binStr.length
    const arr = new Uint8Array(len)
    for (let i = 0; i < len; i++) {
      arr[i] = binStr.charCodeAt(i)
    }
    console.log(type)
    const blob = new Blob([arr], { type: type })
    return URL.createObjectURL(blob)
  }

  /* 
  const getDocument = (documentId) => {
    setLoadingDocument(true)
    axiosInstance
      .get(`/client-document/${documentId}`)
      .then((res) => {
        setDocumentType(res.data.name.split('.')[1])
        setDocumentData(res.data.data)
        setShowDocument(true)
        setLoadingDocument(false)
      })
      .catch((e) => message.error("Une erreur s'est produite"))
  } */

  const formatJsDate = (date) => {
    const newDate = new Date(date)
    const day = String(newDate.getDate()).padStart(2, '0')
    const month = String(newDate.getMonth() + 1).padStart(2, '0') // Months are 0-based, so we add 1
    const year = newDate.getFullYear()

    return `${day}/${month}/${year}`
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const selectDocument = (document) => {
    setSelectedDocument(document)
  }

  const handleDownloadPdf = (documentId) => {
    const link = document.createElement('a')
    axiosInstance
      .get(`/client-document/${documentId}`)
      .then((res) => {
        let documentType = res.data.name.split('.')[1]
        if (documentType === 'pdf') {
          link.href = `data:application/pdf;base64,${res.data.data}`
          link.download = `${res.data.name}`
        } else {
          link.href = `data:image/png;base64,${res.data.data}`
          link.download = `${res.data.name}`
        }

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })
      .catch((e) => message.error("Une erreur s'est produite"))

    // Simulate a click event to trigger the download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const addDocument = () => {
    if (
      (documentToUploadType.length === 0 && otherDocumentType.length === 0) ||
      !selectedFile
    ) {
      message.error('Veuillez remplir tout les champs !')
      return
    }
    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('clientId', client._id)
    formData.append('type', documentToUploadType)
    formData.append('otherDocumentType', otherDocumentType)

    axiosInstance
      .post('/client-document', formData)
      .then((res) => {
        setClientDocuments([...clientDocuments, res.data])
        setOpenAddModal(false)
        setDocumentToUploadType('')
        setOtherDocumentType('')
        setSelectedFile(null)
      })
      .catch((e) => {
        console.log(e)
        setSelectedFile(null)
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  return (
    <div className="text-right  bg-orange-50 h-full">
      <Modal
        bodyStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        footer={[
          <Button key="back" onClick={() => setShowDocument(false)}>
            fermer
          </Button>,
        ]}
        width={1000}
        title="Document"
        open={showDocument}
        onCancel={() => setShowDocument(false)}
      >
        <div>
          {documentType === 'pdf' && (
            <Document
              file={`data:application/pdf;base64,${documentData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={5 / 3} pageNumber={idx + 1} />
              })}
            </Document>
          )}

          {documentType !== 'pdf' && (
            <img src={`data:image/png;base64,${documentData}`} />
          )}
        </div>
      </Modal>

      {openAddModal && (
        <Modal
          bodyStyle={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          footer={[
            <Button key="add" onClick={addDocument}>
              Ajouter
            </Button>,
            <Button
              key="back"
              onClick={() => {
                setOpenAddModal(false)
                setDocumentToUploadType('')
                setOtherDocumentType('')
                setSelectedFile(null)
              }}
            >
              fermer
            </Button>,
          ]}
          width={800}
          title="Ajout de documents"
          open={openAddModal}
          onCancel={() => {
            setOpenAddModal(false)
            setDocumentToUploadType('')
            setOtherDocumentType('')
            setSelectedFile(null)
          }}
        >
          <div className="flex flex-col space-y-8 w-full items-center justify-center">
            <Select
              className=" mt-10 h-8 py-2  text-gray-600 font-light rounded-md placeholder-gray-400 w-4/5 "
              showSearch
              onChange={(e) => setDocumentToUploadType(e)}
              value={documentToUploadType}
              placeholder="Type document"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'Atradius accepté',
                  label: 'Atradius accepté',
                  key: 'Atradius accepté',
                },
                {
                  value: 'Atradius refusé',
                  label: 'Atradius refusé',
                  key: 'Atradius refusé',
                },
                {
                  value: 'Devis signé',
                  label: 'Devis signé',
                  key: 'Devis signé',
                },

                {
                  value: 'Fiche de poste',
                  label: 'Fiche de poste',
                  key: 'Fiche de poste',
                },
                {
                  value: 'Fiche de renseignement paie',
                  label: 'Fiche de renseignement paie',
                  key: 'Fiche de renseignement paie',
                },
                {
                  value: 'K-BIS',
                  label: 'K-BIS',
                  key: 'K-BIS',
                },
                {
                  value: 'Proposition commerciale',
                  label: 'Proposition commerciale',
                  key: 'Proposition commerciale',
                },
                {
                  value: 'Autre',
                  label: 'Autre',
                  key: 'Autre',
                },
              ]}
            />
            {documentToUploadType === 'Autre' && (
              <Input
                placeholder="Type du document"
                onChange={(e) => setOtherDocumentType(e.target.value)}
                className=" mt-10 h-8 px-2 py-2  text-gray-600 font-light rounded-md placeholder-gray-400 w-4/5"
              />
            )}
            <div
              onClick={handleClick}
              className="border border-[#00000040] w-4/5  text-[#00000040] rounded-md flex flex-col items-center justify-center mt-6 py-6"
            >
              <CloudUploadIcon />
              <p className="mt-2">Ajouter document</p>
              <p className="text-black">{selectedFile?.name}</p>
              <input
                type="file"
                onChange={(e) => handleFileChange(e)}
                className="hidden"
                ref={inputFileRef}
              />
            </div>
          </div>
        </Modal>
      )}
      <button
        onClick={setOpenAddModal}
        className="bg-orange-500 p-2 mt-10 rounded-md mr-6 mb-4 text-white"
      >
        Inserer un document
      </button>
      {clientDocuments.length > 0 && (
        <div className="uppercase">
          <Table pagination={{ pageSize: 50 }} dataSource={clientDocuments}>
            <Column
              className="text-center bg-orange-50"
              title="Depot"
              dataIndex="createdAt"
              key="createdAt"
              render={(name, record) => <p>{name && formatJsDate(name)}</p>}
            />
            <Column
              className="text-center bg-orange-50"
              title="Type"
              dataIndex="type"
              key="type"
              render={(type, record) => <p>{type}</p>}
            />

            <Column
              className="text-center bg-orange-50"
              title="Par"
              dataIndex="user"
              key="user"
              render={(name, record) => (
                <p>
                  {name && (name.firstname || '') + ' ' + (name.lastname || '')}
                </p>
              )}
            />

            <Column
              className="text-center bg-orange-50"
              title="Action"
              dataIndex="_id"
              key="_id"
              render={(data, record) => (
                <Popover
                  placement="bottom"
                  content={
                    <div className="flex flex-col capitalize text-base space-y-3">
                      <p
                        onClick={() => getDocument(data)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Visualiser</span>
                      </p>

                      <p
                        onClick={() => handleDownloadPdf(data)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Télécharger</span>
                      </p>
                      <p
                        onClick={() => {
                          selectDocument(record)
                          setIsDrawer(true)
                        }}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Editer</span>
                      </p>
                      <p
                        onClick={() => deleteDocument(data)}
                        className="hover:bg-gray-200 flex space-x-1 items-center p-1 hover:cursor-pointer"
                      >
                        <span>Supprimer</span>
                      </p>
                    </div>
                  }
                  trigger="click"
                >
                  <Button>...</Button>
                </Popover>
              )}
            />
          </Table>
          <Drawer
            title="Editer le document"
            placement="right"
            onClose={() => setIsDrawer(false)}
            open={isDrawer}
          >
            <p>{selectedDocument?.name}</p>
            <p>{selectedDocument?.type}</p>
          </Drawer>
        </div>
      )}

      {clientDocuments.length === 0 && (
        <div className="flex flex-col items-center justify-center h-60">
          <p>Aucun document</p>
        </div>
      )}
    </div>
  )
}
