import AxiosInstance from '../../axios/axiosInstance'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import { useEffect, useRef, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ImageSearchIcon from '@mui/icons-material/ImageSearch'
import DownloadingIcon from '@mui/icons-material/Downloading'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { AddCircleOutlineOutlined, CalendarMonth } from '@mui/icons-material'
import { SearchBar } from '../utils/SearchBar'
import axiosInstance from '../../axios/axiosInstance'
import { DatePicker, Modal, Pagination, Alert } from 'antd'
import { useNavigate } from 'react-router-dom'
import { Input } from 'antd'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { Document, Page, pdfjs } from 'react-pdf'
import { Icon } from '@mui/material'
import { Puff } from 'react-loader-spinner'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

export default function CandidatPay() {
  const [pays, setPays] = useState([])
  const [selectedFile, setSelectedFile] = useState(null)
  const [date, setDate] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const navigate = useNavigate()
  const inputFileRef = useRef()
  const [isModalShowPay, setIsModalShowPay] = useState(false)
  const [numPages, setNumPages] = useState([])
  const [paySlipData, setPaySlipData] = useState(false)
  const [originalPays, setOriginalPays] = useState([])
  const [documentCount, setDocumentCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [showSearch, setShowSearch] = useState(false)
  const searchInputRef = useRef(null)
  const [isLoadingParsing, setIsloadingParsing] = useState(false)
  const [message, setMessage] = useState('')
  const [isMessage, setIsMessage] = useState(false)

  const months = [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Aout',
    'Septembre',
    'Octobre',
    'Novembre',
    'Decembre',
  ]

  useEffect(() => {
    loadPay()
  }, [])

  const showSearchInput = (search) => {
    setShowSearch(!search)
    if (!search)
      setTimeout(() => {
        searchInputRef.current.focus()
      }, 100)
  }

  const handleClick = () => {
    inputFileRef.current.click()
  }

  const handleDownloadPdf = (pay) => {
    axiosInstance
      .get(`payslip/${pay._id}`)
      .then((res) => {
        const binaryData = Uint8Array.from(window.atob(res.data.data), (char) =>
          char.charCodeAt(0),
        )
        const pdfBlob = new Blob([binaryData], { type: 'application/pdf' })
        const pdfUrl = URL.createObjectURL(pdfBlob)
        window.open(pdfUrl, '_blank')
      })
      .catch((e) => console.log(e))
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0]
    if (file.type === 'application/pdf') {
      setSelectedFile(event.target.files[0])
    } else {
      message.error('Veuillez ajouter un fichier pdf !')
    }
  }

  const selectDate = (dateString) => {
    let date = dateString.split('/')
    let formattedDate = months[parseInt(date[0]) - 1] + ' ' + date[1]
    setDate(formattedDate)
  }

  const parseFile = () => {
    setIsloadingParsing(true)
    if (!date.length || !selectedFile) {
      message.error('Veuillez remplir tous les champs!')
      return
    }

    const formData = new FormData()
    formData.append('file', selectedFile)
    formData.append('date', date)

    AxiosInstance.post('payslip/parsing', formData)
      .then((res) => {
        console.log(res.data.length)
        const data = res.data + ''
        if (data.length) {
          setMessage('Fiches de paie de ' + res.data + ' non traités !')
        } else {
          setMessage(
            'Fichier correctement splité, toutes les fiches de paie ont été traités !',
          )
        }
        setIsMessage(true)
        setOpenModal(false)
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
          return
        }
      })
      .finally(() => {
        setIsloadingParsing(false)
      })
  }

  const handleDocumentLoadSuccess = ({ numPages }) => {
    const newArray = Array.from({ length: numPages }, (_, index) => index + 1)
    setNumPages(newArray)
  }

  const search = (word) => {
    if (word.length === 0) {
      setPays(originalPays)
      setCurrentPage(1)
    }
    if (word.length >= 3) {
      axiosInstance
        .post('payslip/find-by-text', { query: word })
        .then((res) => setPays(res.data))
    }
  }

  const onChangePage = (page, pageSize) => {
    axiosInstance
      .get(`payslip/paginate/${page - 1}`)
      .then((res) => {
        setPays(res.data)
        setCurrentPage(page)
      })
      .catch((e) => {
        console.log(e)
      })
  }

  const loadPay = () => {
    axiosInstance.get('payslip/count').then((res) => {
      setDocumentCount(res.data)
      console.log(res.data)
    })

    axiosInstance
      .get('payslip')
      .then((res) => {
        setPays(res.data)
        setOriginalPays(res.data)
      })
      .catch((e) => message.error("Une erreur s'est produite !"))
  }
  return (
    <>
      {isLoadingParsing && (
        <div className="w-full h-full absolute top-0 z-30 right-0 bg-gray-400 bg-opacity-40 flex items-center justify-center">
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#4fa94d"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {isMessage && (
        <Alert
          message={message}
          className="w-3/5 mx-auto"
          closable
          type="success"
          onClose={() => setIsMessage(false)}
        />
      )}
      {isModalShowPay && (
        <div className="flex items-stretch absolute top-0 min-h-screen  left-0 right-0 z-30 ">
          <div className="min-h-screen  bg-black w-1/5">
            <p>okok</p>
          </div>
          <div className="grow flex flex-col  items-center justify-center py-6 bg-black bg-opacity-70 min-h-screen">
            <div className="flex items-center my-3 justify-center space-x-4">
              <p
                className="cursor-pointer bg-white px-2 py-1 text-black rounded-md border border-black"
                onClick={() => setIsModalShowPay(false)}
              >
                Fermer
              </p>
            </div>
            <Document
              file={`data:application/pdf;base64,${paySlipData}`}
              onLoadSuccess={handleDocumentLoadSuccess}
            >
              {numPages.map((x, idx) => {
                return <Page scale={5 / 3} pageNumber={idx + 1} />
              })}
            </Document>
          </div>
        </div>
      )}

      {!isModalShowPay && (
        <div>
          <Modal
            onCancel={() => {
              setSelectedFile(null)
              setOpenModal(false)
            }}
            footer={null}
            open={openModal}
          >
            <div className="flex w-3/5 flex-col justify-center text-center  mt-10 h-full mx-auto">
              <DatePicker
                picker="month"
                format="MM/YYYY"
                className="my-6"
                onChange={(date, dateString) => selectDate(dateString)}
                placeholder="Mois de paie"
              />
              <div
                onClick={handleClick}
                className="border border-[#00000040] py-3 text-[#00000040] rounded-md"
              >
                <CloudUploadIcon />
                <p className="mt-2">Ajouter fichier fiche de paie</p>
                <p className="text-black">{selectedFile?.name}</p>
                <input
                  type="file"
                  onChange={(e) => handleFileChange(e)}
                  className="hidden"
                  ref={inputFileRef}
                />
              </div>

              <div className="flex mb-2 items-center justify-center w-full">
                <button
                  onClick={parseFile}
                  className="rounded-full bg-mountain-500 hover:bg-mountain-600 py-4 px-10 mt-9 text-base uppercase font-semibold leading-none text-white   focus:ring-6 focus:ring-offset-2 focus:outline-none"
                >
                  <span>Spliter fiche de paie</span>
                </button>
              </div>
            </div>
          </Modal>
          <div className="flex justify-end ml-20 mb-10 mt-10">
            <div className="flex items-center space-x-2 h-10">
              {showSearch && (
                <Input
                  ref={searchInputRef}
                  className={`w-80 transition-all ${
                    showSearch ? 'opacity-100' : 'opacity-0'
                  } duration-500 ease-in-out   rounded-md border border-gray-200 focus:outline-none`}
                  placeholder="Recherche"
                  style={{ outline: 'none' }}
                  onChange={(e) => search(e.target.value)}
                />
              )}
              <SearchIcon
                onClick={() => showSearchInput(showSearch)}
                className="text-gray-500 text-3xl"
              />
              <DoneAllIcon className="text-gray-500 text-3xl" />
              <AddCircleOutlineOutlined
                onClick={() => setOpenModal(true)}
                className="text-gray-500 text-3xl"
              />
            </div>
          </div>
          <div className="flex items-center justify-between  mb-6 mx-20 text-md">
            <p className="w-1/3">AGENCE</p>
            <p className="w-1/3">INTERIMAIRE</p>
            <p className="w-1/3">MOIS DE PAIE</p>
          </div>
          <div className="flex flex-col space-y-4">
            {pays.map((pay, idx) => {
              return (
                <div
                  key={idx}
                  onClick={(e) => handleDownloadPdf(pay)}
                  className=" flex py-6  items-center drop-shadow-2xl text-xs  justify-between mx-20 bg-white border  border-gray-200 rounded-md p-4 hover:scale-101 hover:rotate-y-90 hover:cursor-pointer transition-all duration-300"
                >
                  <div className="w-1/3">
                    <p className="font-bold">J'M INTERIM</p>
                    <p className="text-gray-500">Asnieres sur seine</p>
                  </div>

                  <div className="w-1/3 flex items-center space-x-2">
                    <div>
                      <p>
                        <AccountBoxIcon className="text-3xl text-gray-300" />
                      </p>
                    </div>
                    <div className="text-xs">
                      <p className="font-bold">{pay.candidateName}</p>
                      <p className="text-gray-500">{pay.candidatePld}</p>
                    </div>
                  </div>

                  <div className="w-1/3 flex items-center space-x-2">
                    <div>
                      <p>
                        <CalendarMonth className="text-3xl text-gray-300" />
                      </p>
                    </div>
                    <div className="text-xs">
                      <p className="font-bold text-center">{pay.date}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="text-right">
            <Pagination
              className="mt-6 w-full"
              total={documentCount}
              current={currentPage}
              onChange={onChangePage}
              pageSize={20}
            />
          </div>
        </div>
      )}
    </>
  )
}
