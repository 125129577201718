import { message, Modal, Select } from 'antd'
import TextArea from 'antd/es/input/TextArea'
import { useEffect, useState } from 'react'
import axios from '../../axios/axiosInstance'
import { useNavigate } from 'react-router-dom'

const RelanceModal = ({ prolongations, isRelance, setIsRelance }) => {
  const [contactEmail, setContactEmail] = useState('')
  const [client, setClient] = useState(null)
  const navigate = useNavigate();


  const sendRelance = () => {
    const interimaires = prolongations.map((prol)=> prol.NomPrenom);

    if (contactEmail.length === 0) {
      message.error('Selectionnez un contact')
      return
    }
    axios.post(`pld-contract/relance`, {
      interimaires,
      contactEmail,
    })
      .then((res) => {
        message.success('Relance envoyée !')
        setIsRelance(false);
      })
      .catch((e) => {
        if (e.response.data.statusCode === 403) {
          localStorage.clear()
          navigate('/login')
        }
      })
  }

  useEffect(() => {
    axios.get(`client/${prolongations[0].IdClient}/pld`).then((res) => {
      setClient(res.data)
    })
  }, [])
  console.log(prolongations)
  return (
    <Modal open={isRelance} width={1000} footer={null} onCancel={() => setIsRelance(false)}>
      <div className="flex flex-col my-10 space-y-6 items-center justify-center ">

        {prolongations.length === 1 && (
          <div className='flex flex-col space-y-2'>
            <p className='font-semibold'>Objet : Prolongation intérimaire</p>

            <p>Cher client,</p>

            <p>
              Le contrat de <span className='capitalize font-semibold'>{prolongations[0].NomPrenom.toLowerCase()} </span>,en mission chez vous, arrive
              prochainement à son terme.
            </p>

            <p>
              Seriez-vous intéressé(e) par une prolongation de son service ?{' '}
            </p>
            <p className='ml-10'>
              - Si <span className='text-green-600 font-semibold'>oui</span>, pourriez-vous préciser jusqu'à quelle date ? Toujours le
              même motif ?
            </p>
            <p className='ml-10'>
              - Si <span className='text-red-600 font-semibold'>non</span>, pourriez-vous préciser la date de son dernier jour
              travaillé ?
            </p>
            <p>
              Nous vous remercions par avance pour votre retour et vous
              souhaitons une bonne journée !
            </p>
          </div>
        )}

        {prolongations.length > 1 && (
          <div className='flex flex-col space-y-2'>
            <p className='font-semibold'>Objet : Prolongations intérimaires</p>

            <p>Cher client,</p>

            <p>
              Les contrats de nos intérimaires cités ci-dessous, en mission chez
              vous, arrivent prochainement à termes.
            </p>

            {prolongations.map((prolongation) => {
              return <p className='capitalize font-semibold'> - {prolongation.NomPrenom.toLowerCase()}</p>
            })}

            <p>
              Seriez-vous intéressé(e) par une prolongation de leurs services ?{' '}
            </p>
            <p className='ml-10'>
              - Si <span className='text-green-600 font-semibold'>oui</span>, pourriez-vous préciser jusqu'à quelle date ? Toujours le
              même motif ?
            </p>
            <p className='ml-10'>
              - Si <span className='text-red-600 font-semibold'>non</span>, pourriez-vous préciser la date de leur dernier jour
              travaillé ?
            </p>
            <p>
              Nous vous remercions par avance pour votre retour et vous
              souhaitons une bonne journée !
            </p>
          </div>
        )}
        <Select
          className="w-96"
          showSearch
          onChange={(e) => setContactEmail(e)}
          value={contactEmail.length > 0 ? contactEmail : null}
          optionFilterProp="children"
          placeholder="Selectionner interlocuteur"
          filterOption={(input, option) =>
            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
          }
          options={client?.contacts?.map((contact, idx) => {
            return {
              value: contact.email,
              label: contact.email,
              key: idx,
            }
          })}
        />

        <button
          onClick={sendRelance}
          className="bg-mountain-500 w-96 font-semibold p-2 rounded-md text-white"
        >
          Envoyer la relance
        </button>
      </div>
      <div></div>
    </Modal>
  )
}

export default RelanceModal
