import { Checkbox } from 'antd'

const CreateContractPenibilite = ({ contract, setContract }) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        <p className="font-semibold text-center text-3xl">
          Penibilités communiquées ?
        </p>

        <div className="flex items-center my-6 space-x-10 justify-center">
          <p className="flex items-center space-x-1">
            <Checkbox
              checked={contract.PenibiliteNC === 'N'}
              onChange={() => setContract({ ...contract, PenibiliteNC: 'N' })}
            />
            <span>OUI</span>
          </p>
          <p className="flex items-center space-x-1">
            <Checkbox
              checked={contract.PenibiliteNC === 'O'}
              onChange={() => setContract({ ...contract, PenibiliteNC: 'O' })}
            />
            <span>NON</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default CreateContractPenibilite
