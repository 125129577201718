import React, { ReactNode, SyntheticEvent } from 'react'
import ApiCalendar from 'react-google-calendar-api'

const config = {
  clientId:
    '963972261535-lcsmne849lelcoto8e4ibngg845hqi86.apps.googleusercontent.com',
  apiKey: 'AIzaSyBL6fAZrbxQnncuoCuWeZItrh-Xf45Mymc',
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
}

const apiCalendar = new ApiCalendar(config)

const GoogleCalendar = () => {
  const handleItemClick = (event, name) => {
    if (name === 'sign-in') {
      apiCalendar
        .handleAuthClick()
        .then((res) => {
          apiCalendar.listUpcomingEvents(10).then((result) => {
            console.log(result.result.items)
          })
        })
        .catch((e) => {})
    } else if (name === 'sign-out') {
      apiCalendar.handleSignoutClick()
    }
  }

  return (
    <div>
      <button onClick={(e) => handleItemClick(e, 'sign-in')}>sign-in</button>
      <button onClick={(e) => handleItemClick(e, 'sign-out')}>sign-out</button>
    </div>
  )
}

export default GoogleCalendar
