import { Input, Modal, message } from 'antd'
import { useState } from 'react'
import axios from '../../axios/axiosInstance'

const RefuseAcompte = ({
  acompteId,
  refuserAcompte,
  onClose,
  setRefuserAcompte,
}) => {
  const [reason, setReason] = useState('')

  const refuse = () => {
    axios
      .put(`acompte/${acompteId}`, {
        status: 'Refusée',
        motif: reason,
      })
      .then((res) => {
        message.success('Modification enregistrées')
        onClose(res.data)
        setRefuserAcompte(false)
      })
      .catch((e) => {
        message.error('Vous ne pouvez pas modifier !')
      })
  }

  return (
    <div>
      <Modal
        open={refuserAcompte}
        footer={null}
        onCancel={() => setRefuserAcompte(false)}
      >
        <div className="flex flex-col space-y-6 mt-10">
          <Input
            className="rounded-md border border-gray-300"
            placeholder="Raison du refus"
            onChange={(e) => setReason(e.target.value)}
            value={reason}
          />

          <button
            className="p-2 text-white bg-green-600 rounded-md"
            onClick={refuse}
          >
            Refuser l'acompte
          </button>
        </div>
      </Modal>
    </div>
  )
}

export default RefuseAcompte
